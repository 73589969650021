import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DataGrids from "../../../common/table/DataGrid";
import ExcelUpload from '../../../common/excelUpload/Main'
import Multiselect from 'multiselect-react-dropdown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Link, useParams } from "react-router-dom";

import axios, { CancelToken, isCancel } from 'axios';
import {
  Modal,
  ModalBody,
} from 'reactstrap';
import Getcookie from '../../../modules/login/Getcookie';
import { useHistory } from 'react-router-dom';

import SideBar from '../../../common/SideBar/SideBar';
import { useNavigate, useLocation } from 'react-router-dom';
// import pdfImg from '../../../assets/pdf.png'
import { VscError } from 'react-icons/vsc';
import {
  GET_DOCUMENTBYID, UPLOAD_COST_JOB_EXCEL,
  BRANDSHEET_EXCEL, GET_Employee, TENDER_STATE, GET_COSTSHEET_BY_ID, GET_BRANDSHEET_BY_ID,
  COSTSHEET_EXCEL, UPDATE_PROJECT,
  CREATE_EVENTVENDOR, SUBMIT_ANS,
  GET_ALLVENDOR, CREATE_TEAM,
  GET_EVENTBYID, GET_ALLDAILYUPDATE,
  GET_EVENTNAMEBYID, GET_DAILYUPDATE, DAILY_UPDATE_BY_PROJECT_ID,
  GET_PROJECTBYID, DAILY_UPDATE_BY_EMPLOYEE_ID, GET_DOCUMENT, CREATE_DOCUMENT,
  GET_VENDOR, GET_VENDOR_BY_PROJECT_BY_ID, DELETE_INDENT_PR, ALLOWED_USERTYPE,
  GET_TEAMS_BY_PROJECT_ID, GET_EMP_DATAILS_BY_MCODE, GET_ELEMENT_TYPE, GET_ALL_ELEMENTS, CREATE_INDENT_PR, GET_INDENT_PR
} from "../../../utiles/AllApis";
// import Uploadexcel from '../../components/Uploadexcel';
import Costsheetdefault from "../../../assets/Internal_costing_Format.xlsx";
import workOrderSheet from "../../../assets/Work_Order_sheet.xlsx";
import BrandingSheet from "../../../assets/Branding_Sheet.xlsx";
import { Button } from "@mui/material";
import { ArrowBackIos } from "@material-ui/icons";
import ElementCard from "../../manageMaster/ElementCard";
import ErrorAlertBox from "../../../common/AlertBox/ErrorAlertBox";
import SuccessAlertBox from "../../../common/AlertBox/SuccessAlertBox";
import FloatingBtn from "../../../common/Buttons/FloatingBtn";
// import AllVendor from "../itemMaster/AllVendor";

function RunningProject(props) {
  // const history = useHistory();

  const { projectName } = useParams();
  const [ViewDoc, setviewDoc] = useState([]);
  const [costData, setCostData] = useState([]);
  const [workOrderData, setWorkOrderData] = useState([]);
  const [BrandData, setBrandData] = useState([]);
  const [selectedExcelType, setSelectedExcelType] = useState("COST");
  const [ProductRiderData, setProductRiderData] = useState([]);
  const [costsheet, setcostsheet] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [viewUploadMoreDoc, setViewUploadMoreDoc] = useState(false);
  const [cost, setcost] = useState(true);
  const [production, setproduction] = useState(false);
  const [query, setQuery] = useState("");
  const [collapsShow, setCollapsShow] = useState(false);
  const [brand, setbrand] = useState(false);

  const [Order, setOrder] = useState(false)
  const [isElementSelected, setIsElementSelected] = useState(false);
  let vendorNameList = [];
  const cancelFileUpload = useRef(null);
  const [dltRowpos, setDltRowpos] = useState("");
  const [searchDropdown, setSearchDropDown] = useState("");
  const [TyprDocVal, setTyprDocVal] = useState(false);
  const [totalcost, settotalcost] = useState("0");
  const [totalbrand, settotaalbrand] = useState("0");
  const [empname, setempname] = useState("");
  const [dropdown, setdropdown] = useState([]);
  const defaultDate = `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`
  const [allocateDate, setallocateDate] = useState(defaultDate);
  const [dataTableForDailyUpdatesForProject, setdDataTableForDailyUpdatesForProject] = useState([]);
  const [countTotalcost, setcountTotalcost] = useState("");
  const [countTotalbrand, setcountTotalbrand] = useState("");
  const [DailyUpdatesForEmp, setDailyUpdatesForEmp] = useState([]);
  const [uploadedPrecentage, setUploadedPrecentage] = useState(0);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [costfile, setcostfile] = useState(null);
  const [brandfile, setbrandfile] = useState(null);
  const [cmpntDocs, setCmpntDocs] = useState();
  const [internalDocs, setInternalDocs] = useState();
  const [GetDocName, setGetDocName] = useState("");
  const [ElementsArr, setElementsArr] = useState([])
  const [Category, setCategory] = useState("")
  const [selectedElement, setselectedElement] = useState("")
  const [SelectedCategory, setSelectedCategory] = useState("")
  const [ElemntCategory, setElemntCategory] = useState([]);
  const [isSubmitFile, setIsSubmitFile] = useState(false);
  const [TotalRider, setTotalRider] = useState("")
  const [cardclass, setcardclass] = useState(null)
  const [cardTypeclass, setcardTypeclass] = useState(null);
  const navigate = useNavigate();
  const [isdisable, setisdisable] = useState(true)
  const [showallocate, setshowallocate] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({
    Particular_Name: '',
    Amt: '',
    Qty: '',

  });
  const location = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var arr = [];
    arr.push(formData)

    const url = window.location.href;
    const projectId = url.split('/').pop();
    setProjectId(projectId);
    var config = {
      method: "post",
      url: UPLOAD_COST_JOB_EXCEL,
      data:
      {
        "cost_List": arr,
        "projectId": projectId,
        "type": "WORK_ORDER"
      }
    };
    axios(config)
      .then(function (response) {

        if (!response.data.isSuccess) {
          setIsOpen(true);
          setHeading("opps...");
          setErrMsg("Somthing went wrong.");
        }
        else {
          setFormData({ Particular_Name: '', Qty: '', Amt: '' });
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your data inserted successfully..");




          getCostByProId();
          getBrandSheetByProId();
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const [costhover, setcosthover] = useState(
    "foocus tracking-in-expand-fwd-bottom"
  );
  const [brandhover, setbrandhover] = useState(
    "focus tracking-in-expand-fwd-bottom boxes"
  );
  const [Orderhover, setOrderhover] = useState(
    "focus tracking-in-expand-fwd-bottom boxes"
  );
  const [Productionhover, setproductionhover] = useState(
    "focus tracking-in-expand-fwd-bottom boxes"
  );
  const [isOpenDeletePopUp, setIsOpenDeletePopUp] = useState(false);
  const handalCloseDelete = () => {
    setIsOpenDeletePopUp(false);
  };
  const handalOpenDelete = (rowpos) => {
    setIsOpenDeletePopUp(true);
    setDltRowpos(rowpos);
  };
  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("user has cacel the file upload");
    }
  }
  const columns = [

    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.hiringDate.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },

  ]

  const optionsProduct = [
  ];
  const [selectOptionsProduct, setSelectOptionsProduct] = useState([]);
  const [checked, setChecked] = useState([]);
  const [vList, setVList] = useState("");
  const [thisuser, setthisuser] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [brandpopupcell, setbrandpopupcell] = useState(false);
  const [eventname, seteventname] = useState([])
  const [viewDailyUpdatesByProject, setViewDailyUpdatesByProject] = useState(false);
  const [popupdoc, setpopupDoc] = useState(false)
  const [vendordrop, setvendordrop] = useState([])
  const [eventVendor, seteventVendor] = useState([]);
  const [percent, setpercent] = useState("")
  const [showEmpDailyUpdatesPops, setShowEmpDailyUpdatesPopup] = useState(false);
  const [showMulticategory, setshowMulticategory] = useState(false);
  const [projectDetails, setProjetcDetails] = useState({
    vendor: "",
    vdr: ""
  });
  const [viewTeams, setViewTeams] = useState(false);
  const [viewVendorsTeam, setViewVendorsTeams] = useState(false);
  const [dataTable, setDataTable] = useState();
  const [dataTableForVendor, setDataTableForVendor] = useState([]);
  const [viewCompyDoc, setViewCompyDoc] = useState(false);
  const [viewInternalDoc, setViewInternalDoc] = useState(false);
  const [viewUploadPrstnDoc, setViewUploadPrstnDoc] = useState(false);
  const [uploadfile, setuploadfile] = useState("");
  const [empDoc, setEmpDoc] = useState();
  const [docList, setDocList] = useState([]);
  const [oselectedOtionsProduct, setoselectedOtionsProduct] = useState([]);

  const [allDailyUpdates, setAllDailyUpdates] = useState([]);
  const [userType, setUSerType] = useState("");
  const [vendorListName, setVendoeNameList] = useState("");
  const [viewDocList, setViewDocList] = useState(false);
  const handleOpenDocList = () => { setViewDocList(true) }
  const handleCloseDocList = () => { setViewDocList(false) }

  // -------------------
  const [Length, setLength] = useState(1)
  const [Width, setWidth] = useState(1)
  const [Height, setHeight] = useState(1)
  const [Amount, setAmount] = useState("")
  const [Quantity, setQuantity] = useState(1)
  const [Area, setArea] = useState("")
  const [Remark, setRemark] = useState("")
  const [Delivered, setDelivered] = useState("")
  const [recAccept, setRecAccept] = useState("U");
  const [recWork, setRecWork] = useState("U");
  const [securityDpst, setSecurityDpst] = useState("U");
  const [EMDReleased, setEMDReleased] = useState("U");
  const [openDataPopup, setOpenDataPopup] = useState(false);
  const [openDataPopup1, setOpenDataPopup1] = useState(false);
  const [openDataPopup2, setOpenDataPopup2] = useState(false);
  const [openDataPopup3, setOpenDataPopup3] = useState(false);
  const [dates, setDates] = useState({
    "recAcceptDate": "1990-01-01",
    "recWorkDate": "1990-01-01",
    "securityDpstDate": "1990-01-01",
    "EMDReleasedDate": "1990-01-01",
  });
  const handalOpen1 = () => { setIsOpen1(true); }
  const handalOpen = () => { setIsOpen(true); }
  const handalOpen2 = () => { setIsOpen2(true) }
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false)
  const [heading, setHeading] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // ------------------- Update Q&A  -----------------
  const updateRecAccept = (val) => {
    setOpenDataPopup(val);
    if (val) {
      setRecAccept("Y");
    }
    else {
      setRecAccept("N");
    }

  }
  const updateWorkOrder = (val) => {
    setOpenDataPopup1(val);
    if (val) {
      setRecWork("Y");
    }
    else {
      setRecWork("N");
    }
  }
  const updateSecDpst = (val) => {
    setOpenDataPopup2(val);
    if (val) {
      setSecurityDpst("Y");
    }
    else {
      setSecurityDpst("N");
    }
  }
  const updateEMDReleased = (val) => {
    setOpenDataPopup3(val);
    if (val) {
      setEMDReleased("Y");
    }
    else {
      setEMDReleased("N");
    }
  }
  const onDateChange = (e) => {
    setDates({ ...dates, [e.target.name]: e.target.value });
  }
  const taledata = [];
  var taledataVendor = [];
  const columnsForTeams = [

    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    {
      field: "hiringDate",
      headerName: "Hiring Date",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.hiringDate.split("T")[0]}</p>
        );
      }
    },
    { field: 'mail', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Mobile No.', width: 150 },
    {
      field: "View Daily Updates",
      headerName: "View Daily Updates",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (<button className="btn btn-success" onClick={e => { viewDailyUpdatesOfEmp(params.row.rowpos) }}>View</button>)

      }
    },
  ]
  const columnForDUpdatesOFEmp = [
    {
      field: "edate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'employeeName', headerName: 'Employee Name', width: 190, },
    { field: 'projectName', headerName: 'Event Name', width: 230, },
    { field: 'subject', headerName: 'Subject', width: 250, },
    { field: 'remarks', headerName: 'Remarks', width: 250, },
    {
      field: "path",
      headerName: "View Documents",
      sortable: false,
      width: 230,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button variant="contained" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }} color="primary" onClick={e => { viewDailyDocFoeEmp(params.row.dailyUpdate_docs) }}>view</Button>
        );
      }
    }
  ];
  const columnsForCmpnyDoc = [

    { field: 'name', headerName: 'Document Name', width: 500 },
    { field: 'path', headerName: 'Documets', width: 500 },


  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };

  const handleExcelUpload = (type) => {
    setSelectedExcelType(type);

  };


  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];

    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);




  };





  var ary = [];
  const getCheckedElmnt = (item) => {

    setIsElementSelected(true);
    setselectedElement(item)
    var b = item
    axios.post(GET_ELEMENT_TYPE,
      { "BCD": item }
    ).then((response) => {

      if (response.data.isSuccess === true) {
        setisdisable(false)

        setElemntCategory(response.data.data)
      }
      else {
        setElemntCategory(response.data.data)
      }


    })

  }

  useEffect(() => {
    setArea(Length * Width * Height)
    setAmount(Area * Quantity)

  }, [Length, Width, Height, Quantity, Area])

  const InsertIndent = () => {
    console.log(selectedElement, SelectedCategory, projectName, Length, Width, Height, Area, Quantity, Amount, Remark, Delivered)
    var data = JSON.stringify({
      "Length": Length,
      "Width": Width,
      "Height": Height,
      "Area": Area,
      "Qty": Quantity,
      "Amt": Amount,
      "Remark": Remark,
      "Delivered": Delivered,
      "Project_ID": projectName,
      "Catergory": SelectedCategory,
      "Element": selectedElement
    });

    var config = {
      method: 'post',
      url: CREATE_INDENT_PR,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess === true) {
          alert(response.data.message);
          window.location.reload();

        }
        else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  var arr = [];
  const handleCheckedCat = (item, itemType) => {

    setSelectedCategory(item)
    console.log(itemType);
    // if(itemType==="GENERATE")
    // {

    // }
  }
  const onHandleVendor = (val) => {

    setVendoeNameList(val);
    //   const a=[];
    // val.map((c,i)=>
    // {
    //   a.push(c.mcode);
    // })
    //   setVendoeNameList(a.join(','));
  }
  const handlefile = (e) => {
    var name = e?.target?.files[0]?.name?.split("");

    var validate = name?.reverse().join("").split(".")[0];
    if (validate === "xslx" || validate === "piz" || validate === "kpa") {
      alert("please select image file !");
      e.target.value = null;
    }
    else {
      setuploadfile(e.target.files[0])
    }
  }
  const hideallocate = () => {
    axios.get(GET_Employee).then((response) => {
      setdropdown(response.data.data);

    });
    setshowallocate(true);
    setpopupcell(false);
    handleClosePresentationDoc1(false);
    setbrandpopupcell(false);
    if (TyprDocVal) {
      setTyprDoc();
    }

  }
  const showALLocate = () => {
    setshowallocate(false)
  }
  function uploadfiles() {

    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", "UPDATE");
    data.append("name", "document");
    data.append("Type", selectedDocType);

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };

    axios(config)
      .then(function (response) {

        if (response.data.isSuccess === true) {
          window.location.reload();
        }
        else {
          alert("File Upload Fails");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function uploadfiles1() {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", uploadfile);
    data.append("ID", projectName);
    data.append("SubType", GetDocName);
    data.append("name", GetDocName);
    data.append("Type", selectedDocType);
    setIsSubmitFile(true);
    axios.post(CREATE_DOCUMENT, data, {
      onUploadProgress: (data) => {
        const { loaded, total } = data;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadedPrecentage(percent);
        }
      }, cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
    })
      .then(function (response) {

        if (response.data.isSuccess === true) {
          setUploadedPrecentage(100);
          setTimeout(() => {
            setIsSubmitFile(false);
            window.location.reload();
          }, 1000)

        }
        else {
          alert("File Upload Fails");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handlecrossDocPopo = () => {
    setpopupDoc(false);
  }
  const viewDailyUpdatesOfEmp = (empID) => {
    axios.post(GET_DAILYUPDATE,
      {
        "Project_ID": projectName,
        "EMP_MCODE": empID
      }
    ).then((response) => {

      if (response.data.isSuccess === true) {
        setDailyUpdatesForEmp(response.data.data);
      }

    })

    setShowEmpDailyUpdatesPopup(true);
  }
  const viewDailyDocFoeEmp = (dailyDocID) => {
    const docVal = dailyDocID.map((data, i) => {
      return (<img src={data.path} key={i}></img>)
    })
    setviewDoc(docVal);
    setpopupDoc(true);

  }

  const handlecostALLvendor = (params) => {
    // window.location.href = "#"
    setthisuser(params);
    setpopupcell(true)
    setbrandpopupcell(false);
  }
  const handleAllbrand = (params) => {
    // window.location.href = "#"
    setthisuser(params);
    setbrandpopupcell(true)
    setpopupcell(false)
  }
  const handleViewClick = (path) => {
    window.open(path, '_blank');
  };

  const checkedItems = checked?.length
    ? checked.reduce((total, item) => {
      return total + ", " + item

    })
    : "";

  const checkedCategory = checked?.length
    ? checked.reduce((total, item) => {
      return total + ", " + item

    })
    : "";
  useEffect(() => {
  }, [checkedItems])

  useEffect(() => {
    getProjectDetailsById();
    getCostByProId();
    getBrandSheetByProId();
    axios.get(GET_ALL_ELEMENTS).then((response) => {
      if (response.data.isSuccess === true) {
        setElementsArr(response.data.data);

        for (var i = 0; i < response.data.data.length; i++) {
          optionsProduct.push({ 'name': response.data.data[i].name, 'value': response.data.data[i].name });
        }
        // setSearchDropDown(  <Select
        //   value={selectOptionsProduct}
        //   onChange={handleChangeSearchBox}
        //   options={optionsProduct}
        // />)
        // setSearchDropDown(  <Select
        //   value={selectOptionsProduct}
        //   onChange={handleChangeSearchBox}
        //   options={optionsProduct}
        // />)
      }
    })
    // ---------------------------
    const handleChangeSearchBox = (e) => {

      setSelectOptionsProduct({ ...selectOptionsProduct, e })
    }


    // --------------------------------------
    setUSerType(Getcookie("UserType"));
    axios.post(GET_ALLDAILYUPDATE, { "ROWPOS": projectName }).then((response) => {

      if (response.data.isSuccess === true) {
        const resDate = response.data.data.filter((data, i) => {
          if (data.project_ID.includes(projectName)) {
            return data;
          }
        })
        // taledataVendor=response?.data?.data;
        taledataVendor = resDate;
      }

    })
    // getTeamMembers();
    let totalCost = 0;
    let b = 0;

    var data = JSON.stringify({
      Project_ID: projectName,
    });

    var config = {
      method: "post",
      url: GET_EVENTBYID,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //setBrandData(response?.data?.data?._brandSheet);
        setDocList([...response.data.data.lst_documents, ...response.data.data.lst_InternalDocuments, ...response.data.data.lst_CompanyDocuments]);
        settotaalbrand(response?.data?.data?._brandSheet?.length);
        settotalcost(response?.data?.data?.costSheet?.length);
        setcostsheet(response?.data?.data?.costSheet);
        var sums = response?.data?.data?.costSheet;

        sums?.map((sum, index) => {

          var sa = Number(JSON.parse(JSON.stringify(sum.amt)));
          totalCost = sa + totalCost;
        });
        setcountTotalcost(totalCost);
        var Bsum = response?.data?.data?._brandSheet;
        Bsum?.map((brand, index) => {
          var BD = Number(JSON.parse(JSON.stringify(brand.amt)));
          b = BD + b;
        });
        setcountTotalbrand(b);


      })
      .catch(function (error) {
        console.log(error);
      });

    axios.get(GET_VENDOR).then((response) => {

      if (response.data.isSuccess === true) {
        seteventVendor(response.data.data);
        response.data.data.map((c, i) => {
          vendorNameList.push({ "name": c.vendorName, "mcode": c.mcode });

        })

        setVList(<Multiselect
          id="VendorName"
          options={vendorNameList}
          displayValue="name"
          isObject={true}
          showCheckbox={true}
          onSelect={(event) => {
            onHandleVendor(event);
          }}
          onRemove={(event) => {
            onHandleVendor(event);
          }}

        />)
      }

    })


    axios.post(GET_VENDOR_BY_PROJECT_BY_ID, { "ROWPOS": projectName }).then((response) => {

      if (response.data.isSuccess === true) {

        taledataVendor = response?.data?.data;

        setDataTableForVendor(response.data.data);
      }

    })

    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {

      var array1 = [];
      if (response.data.isSuccess === true) {

        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("COMPANY_DOCUMENT")) {
            return data;
          }

        }
        )
        resData.map((data, i) => {
          array1.push(data.name);
        })
        setCmpntDocs(<Multiselect
          id="cmpDoc"
          options={array1}
          displayValue="name"
          isObject={false}
          showCheckbox={true}

        />);
      }

    });
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {
      var array1 = [];
      if (response.data.isSuccess === true) {

        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("WORKORDER_DOCUMENT")) {
            return data;
          }

        }
        )
        resData.map((data, i) => {
          array1.push(data.name);
        })
        setInternalDocs(<Multiselect
          options={array1}
          displayValue="key"

          isObject={false}
          showCheckbox={true}
        />);
      }

    });
  }, []);

  //-----------GET COST SHEET BY PROJECT ID--------
  const getCostByProId = () => {
    var config = {
      method: 'post',
      url: GET_COSTSHEET_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      data:
      {
        "Project_ID": projectName
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {
          var costDa = [];
          var orderDa = [];
          for (var i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].type === 'CST_SHT') {
              costDa.push(response.data.data[i])

            }
            else if (response.data.data[i].type === 'WORK_ORDER') {
              orderDa.push(response.data.data[i])

            }
          }
          setCostData(costDa);
          setWorkOrderData(orderDa)
        }
        else {
          setCostData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //----------get brandsheet by id---------
  const getBrandSheetByProId = () => {
    var config = {
      method: 'post',
      url: GET_DOCUMENTBYID,
      headers: {
        'Content-Type': 'application/json'
      },
      data:
      {
        "Project_ID": projectName
      }
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);

        if (response.data.isSuccess) {
          const filteredData = response.data.data.filter(data => data.path.includes('BRAND_SHEET'));


          setBrandData(filteredData);

        } else {
          setBrandData([]);
        }




      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const reloadFun = (type) => {
    getProjectDetailsById();

  }
  const getProjectDetailsById = () => {
    axios.post(GET_EVENTBYID, { "Project_ID": projectName }).then((response) => {
      if (response.data.isSuccess === true) {
        var oleEDate = `${new Date(response.data.data.eventDate).getFullYear()}/${new Date(response.data.data.eventDate).getMonth() + 1}/${new Date(response.data.data.eventDate).getDate()}`;
        if (oleEDate === "1990/1/1") {
          response.data.data.eventDate = "--NA--";
        }
        else {
          response.data.data.eventDate = `${new Date(response.data.data.eventDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data.eventDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data.eventDate).toLocaleString([], { month: 'short' })}`;
        }
        var oleeventToDate = `${new Date(response.data.data.eventToDate).getFullYear()}/${new Date(response.data.data.eventToDate).getMonth() + 1}/${new Date(response.data.data.eventToDate).getDate()}`;
        if (oleeventToDate === "1990/1/1") {
          response.data.data.eventToDate = "--NA--";
        }
        else {
          var a = `${new Date(response.data.data.eventToDate).toLocaleString([], { weekday: 'short' })}`;
          response.data.data.eventToDate = `${new Date(response.data.data.eventToDate).toLocaleString([], { weekday: 'short' })} ${new Date(response.data.data.eventToDate).toLocaleString([], { day: '2-digit' })} ${new Date(response.data.data.eventToDate).toLocaleString([], { month: 'short' })}`;
        }
        var olelDateOfPreBidQueries = `${new Date(response.data.data.lDateOfPreBidQueries).getFullYear()}/${new Date(response.data.data.lDateOfPreBidQueries).getMonth() + 1}/${new Date(response.data.data.lDateOfPreBidQueries).getDate()}`;
        if (olelDateOfPreBidQueries === "1990/1/1") {
          response.data.data.lDateOfPreBidQueries = "--NA--";
        }
        // var oleLastFillingDate=`${new Date(response.data.data[0].lastFillingDate).getFullYear()}/${new Date(response.data.data[0].lastFillingDate).getMonth()+1}/${new Date(response.data.data[0].lastFillingDate).getDate()}`;
        // if(oleLastFillingDate==="1990/1/1")
        // {
        //   response.data.data[0].lastFillingDate="--NA--";
        // }
        // var oleldateOfSubmissionBid=`${new Date(response.data.data.openFinancialBids).getFullYear()}/${new Date(response.data.data.openFinancialBids).getMonth()+1}/${new Date(response.data.data[0].ldateOfSubmissionBid).getDate()}`;
        // if(oleldateOfSubmissionBid==="1990/1/1")
        // {
        //   response.data.data.openFinancialBids="--NA--";
        // }
        var oleopenFinancialBids = `${new Date(response.data.data.openFinancialBids).getFullYear()}/${new Date(response.data.data.openFinancialBids).getMonth() + 1}/${new Date(response.data.data.openFinancialBids).getDate()}`;
        if (oleopenFinancialBids === "1990/1/1") {
          response.data.data.openFinancialBids = "--NA--";
        }
        var olepreBidMeetingDate = `${new Date(response.data.data.preBidMeetingDate).getFullYear()}/${new Date(response.data.data.preBidMeetingDate).getMonth() + 1}/${new Date(response.data.data.preBidMeetingDate).getDate()}`;
        if (olepreBidMeetingDate === "1990/1/1") {
          response.data.data.preBidMeetingDate = "--NA--";
        }
        var oletechnicalBidOpenDate = `${new Date(response.data.data.technicalBidOpenDate).getFullYear()}/${new Date(response.data.data.technicalBidOpenDate).getMonth() + 1}/${new Date(response.data.data.technicalBidOpenDate).getDate()}`;
        if (oletechnicalBidOpenDate === "1990/1/1") {
          response.data.data.technicalBidOpenDate = "--NA--";
        }
        var olepresentationDate = `${new Date(response.data.data.presentationDate).getFullYear()}/${new Date(response.data.data.presentationDate).getMonth() + 1}/${new Date(response.data.data.presentationDate).getDate()}`;
        if (olepresentationDate === "1990/1/1") {
          response.data.data.presentationDate = "--NA--";
        }

        seteventname(response.data.data);
        // setBrandData(response?.data?.data?._brandSheet);
      }
    })
  }
  const handlebrand = () => {
    setcost(false);
    setproduction(false);
    setbrand(true);
    setbrandhover("foocus tracking-in-expand-fwd-bottom");
    setOrderhover("focus tracking-in-expand-fwd-top boxes");
    setcosthover("focus tracking-in-expand-fwd-top boxes");
    setproductionhover("focus tracking-in-expand-fwd-top boxes");
    setSelectedExcelType("JOB_SHEET");

  };

  const handleOrder = () => {
    setcost(false);
    setproduction(false);
    setbrand(false);
    setOrder(true)
    setbrandhover("focus tracking-in-expand-fwd-top boxes");
    setOrderhover("foocus tracking-in-expand-fwd-bottom");
    setcosthover("focus tracking-in-expand-fwd-top boxes");
    setproductionhover("focus tracking-in-expand-fwd-top boxes");
    setSelectedExcelType("ORDER_SHEET");

  };

  const handleEmpAllocate = () => {
    var data = JSON.stringify({
      "Project_ID": projectName,
      "EMP_MCODE": empname,
      "Date": allocateDate
    });

    var config = {
      method: 'post',
      url: CREATE_TEAM,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        if (response.data.isSuccess === true) {
          alert(response.data.message);

        }
        else {
          document.getElementById("empAllocateDate").value = "";
          document.getElementById("empName").value = "";
        }
        showALLocate();

        //  setallocateDate("")
        //     setempname("")

      })
      .catch(function (error) {
        console.log(error);
      });


  };
  const getTeamMembers = () => {
    axios.post(GET_TEAMS_BY_PROJECT_ID, { "Project_ID": projectName }).then((response) => {

      if (response.data.isSuccess === true) {
        response?.data?.data?.map((data, i) => {

          axios.post(GET_EMP_DATAILS_BY_MCODE, { "EmployeeID": data.emP_MCODE }).then((response) => {

            if (response.data.isSuccess === true) {
              taledata.push(response?.data?.data[0]);

            }
          });
        }
        )

      }

    });
  }
  const handlecost = () => {
    setcost(true);
    setproduction(false);
    setbrand(false);
    setOrderhover("focus tracking-in-expand-fwd-top boxes");
    setcosthover("foocus tracking-in-expand-fwd-bottom");
    setbrandhover("focus tracking-in-expand-fwd-top boxes");
    setproductionhover("focus tracking-in-expand-fwd-top boxes");
    setSelectedExcelType("COST");
  };
  const handleproduction = () => {
    window.location.href = `/projectList/${projectName}/ProductionRider`
  };

  const selectedcost = (event) => {
    var name = event.target.files[0].name.split("");

    var validate = name.reverse().join("").split(".")[0];

    if (validate !== "xslx") {
      alert("please select xlsx file !");
      event.target.value = null;
    }
    setcostfile(event.target.files[0]);
  };

  const selectedbrand = (event) => {
    var name = event.target.files[0].name.split("");

    var validate = name.reverse().join("").split(".")[0];

    if (validate !== "xslx") {
      alert("please select xlsx file !");
      event.target.value = null;
    }
    setbrandfile(event.target.files[0]);
  };

  const btnuploadcost = () => {
    if (costfile === null) {
      alert("please select  excel file ");
      return;
    }
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", costfile);
    data.append("projectID", projectName);

    var config = {
      method: "post",
      url: COSTSHEET_EXCEL,
      data: data,
    };

    axios(config)
      .then(function (response) {

        if (response.data.isSuccess === true) {
          alert("cost factor sheet uploaded Successfully ! ");
          window.location.reload();
        } else {
          alert(response.data.message);
          document.getElementById("fileCost").value = "";

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const btnuploadbrand = () => {
    if (brandfile === null) {
      alert("please select  excel file ");
      return;
    }
    var FormData = require("form-data");

    var data = new FormData();
    data.append("File", brandfile);
    data.append("projectID", projectName);

    var config = {
      method: "post",
      url: BRANDSHEET_EXCEL,
      data: data,
    };

    axios(config)
      .then(function (response) {
        alert(response.data.message);
        brandfile(null)

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlecategory = () => {
    if (showMulticategory === true) {
      setshowMulticategory(false);
      // setcategories(projectDetails.Category)
    } else {
      setshowMulticategory(true);
    }
  };

  const onHandleChange2 = (e) => {
    if (e.target.checked) {
      setProjetcDetails({
        ...projectDetails,
        [e.target.name]: `${e.target.value}`,
      });
      setProjetcDetails({
        ...projectDetails,
        [e.target.id]: `${e?.target?.options[e?.target?.selectedIndex]?.text.trim()}`,
      });
      //e?.target?.options[e?.target?.selectedIndex]?.text.trim()
    } else {
      setProjetcDetails({ ...projectDetails, [e.target.name]: e.target.value });
      setProjetcDetails({
        ...projectDetails,
        [e.target.id]: `${e?.target?.options[e?.target?.selectedIndex]?.text.trim()}`,
      });
    }
  };
  const handleAllocatEmp = () => {
    if (projectDetails.vendor === "") {
      alert("fields cannot be empty")

    }
    else if (percent > 100) {
      alert("Percentage should not be greater than 100 ")
    }
    else {
      var data = JSON.stringify({
        "Project_ID": thisuser.row.project_ID,
        "FactorID": thisuser.row.rowpos,
        "Type": "COSTSHEET",
        "VENDOR_MCODE": projectDetails.vendor,
        "Allocation": percent,
        "Eversion": "1.0.0.8"
      });

      var config = {
        method: 'post',
        url: CREATE_EVENTVENDOR,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          if (response.data.isSuccess === true) {
            // alert(response.data.message);
            setpercent("");

            window.location.reload();
          }
          else {
            alert("something went wrong !");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  const handleOpenTeams = () => {
    getTeamMembers();
    setViewTeams(true);
    setViewUploadMoreDoc(false);
    setbrandpopupcell(false);
    setViewVendorsTeams(false);
    setViewDailyUpdatesByProject(false);
    handleCloseCmpyDoc();
    handleCloseInternalDoc();
    handleClosePresentationDoc();
  }
  const handleCloseTeams = () => {
    setViewTeams(false);
  }
  const allocateVendor = () => {

    if (vendorListName === "") {
      alert("fields cannot be empty")

    }
    else if (percent > 100) {
      alert("Percentage should not be greater than 100 ")
    }

    else {
      var data = JSON.stringify({
        "Project_ID": thisuser.row.project_ID,
        "FactorID": thisuser.row.rowpos,
        "Type": "BRANDSHEET",
        "VENDOR_MCODE": vendorListName,
        "Allocation": percent,

      });

      var config = {
        method: 'post',
        url: CREATE_EVENTVENDOR,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          if (response.data.isSuccess === true) {
            alert(response.data.message)
            setpercent("");
            setbrandpopupcell(false);
          }
          else {
            alert("something went wrong !");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }


  }

  const handlecrossEmpDailyUpdates = () => {
    setShowEmpDailyUpdatesPopup(false);
  }

  const handleOpenVendors = () => {
    setViewVendorsTeams(true);
    setViewTeams(false);
    setViewDailyUpdatesByProject(false);
    setViewUploadMoreDoc(false);
    handleCloseCmpyDoc();
    handleCloseInternalDoc();
    handleClosePresentationDoc();
  }
  const handleCloseVendors = () => {
    setViewVendorsTeams(false);
  }
  const handleOpendailyUpdates = () => {

    setViewVendorsTeams(false);
    setViewTeams(false);
    setViewUploadMoreDoc(false)
    setViewDailyUpdatesByProject(true);
    handleCloseCmpyDoc();
    handleCloseInternalDoc();
    handleClosePresentationDoc();
    axios.post(GET_ALLDAILYUPDATE,).then((response) => {

      if (response.data.isSuccess === true) {

        const resData = response.data.data.filter((data, i) => {
          if (data.project_ID.includes(projectName)) {
            return data;
          }

        }
        )
        console.log(resData);

        setAllDailyUpdates(
          <DataGrid
            rows={resData}
            columns={columnForDailyUpdatesForProject}
            getRowId={(row) => row.rowpos}
            pageSize={10}
            onSelectionModelChange={(ids) => {
            }}
          />
        );
        ;
      }

    });
  }
  const handleClosedailyUpdates = () => {
    setViewDailyUpdatesByProject(false);
  }
  const handleOpenCmpyDoc = () => {
    setViewCompyDoc(true);
    handleCloseInternalDoc();
    handleClosePresentationDoc();
    handleCloseVendors();
    handleCloseTeams();
    handleClosedailyUpdates();
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {

      var array1 = [];
      if (response.data.isSuccess === true) {

        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("COMPANY_DOCUMENT")) {
            return data;
          }

        }
        )
        resData.map((data, i) => {
          array1.push(data.name);
        })
        setCmpntDocs(<Multiselect
          id="cmpDoc"
          options={array1}
          displayValue="name"
          isObject={false}
          showCheckbox={true}

        />);
      }

    });

  }
  const handleCloseCmpyDoc = () => {
    setViewCompyDoc(false);
  }
  const handleOpenInternalDoc = () => {
    setViewInternalDoc(true);
    handleCloseCmpyDoc();
    handleClosePresentationDoc();
    handleCloseVendors();
    handleCloseTeams();
    handleClosedailyUpdates();
    axios.post(GET_DOCUMENT, { "Project_ID": projectName }).then((response) => {

      if (response.data.isSuccess === true) {

        const resData = response.data.data.filter((data, i) => {
          if (data.type.includes("WORKORDER_DOCUMENT")) {
            return data;
          }

        }
        )
        setInternalDocs(resData);
      }

    });

  }
  const handleCloseInternalDoc = () => {
    setViewInternalDoc(false);
  }
  const handleOpenPresentationDoc = () => {
    setViewUploadPrstnDoc(true);
    handleClosePresentationDoc1();
  }
  const handleClosePresentationDoc = () => {
    setViewUploadPrstnDoc(false);
  }
  const handleOpenPresentationDoc1 = (docType) => {
    setSelectedDocType(docType);
    setViewUploadMoreDoc(true);
    handleClosePresentationDoc();
    setpopupcell(false);
    setbrandpopupcell(false);
    showALLocate();
  }
  const handleClosePresentationDoc1 = () => {
    setViewUploadMoreDoc(false);
  }
  ///--------------------submit ans--------------
  const submitAns = () => {

    if (recAccept == "U" || recWork == "U" || securityDpst == "U" || EMDReleased == "U") {
      setHeading("Error");
      setErrMsg(`Please Annswer all given Questions`);
      handalOpen();
      return;
    }
    // else if(recAccept=="Y"||recWork=="Y"||securityDpst=="Y"||EMDReleased=="Y")
    // {
    //  if()
    // }
    axios.post(SUBMIT_ANS,
      {
        "ROWPOS": projectName,
        "IS_RECEIVED_ACCEP": recAccept,
        "IS_RECEIVED_WORKORDER": recWork,
        "IS_SECURITY_DEPOSIT": securityDpst,
        "IS_EMD_RELEASED": EMDReleased,
        "RECEIVED_ACCEP_DATE": dates.recAcceptDate,
        "RECEIVED_WORKORDER_DATE": dates.recWorkDate,
        "SECURITY_DEPOSIT_DATE": dates.securityDpstDate,
        "EMD_RELEASED_DATE": dates.EMDReleasedDate,
      }
    ).then((response) => {

      if (response.data.isSuccess === true) {

        handalOpen1();
        setHeading1("Success");
        setsuccessMsg(response.data.message);
        getProjectDetailsById();
      }
      else {
        setHeading("Creation Failed!");
        setErrMsg(`Opps..${response.data.message}`);
        handalOpen();
      }
    })

  }


  const WorkOrder = [


    {
      field: "eDate",
      headerName: "CREATED AT",
      width: 220,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
        );
      }
    },
    {
      field: "createdTime",
      headerName: "CREATED TIME",
      width: 220,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const time = params?.row?.eDate?.split("T")[1];
        const formattedTime = time ? formatTime(time) : "--:--";
        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },

    { field: "name", headerName: "PARTICULAR NAME", width: 220 },
    { field: "qty", headerName: "QUANTITY", width: 220 },
    { field: "amt", headerName: "AMOUNT", width: 220 },
  ]
  const costcolumns = [
    {
      field: "eDate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.eDate?.split("T")[0]}</p>
        );
      }
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const time = params?.row?.eDate?.split("T")[1];
        const formattedTime = time ? formatTime(time) : "--:--";
        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },
    {
      field: "head",
      headerName: "Particular Type",
      width: 180,
      renderCell: (params) => {
        if (params.row.head === '' || params.row.head === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.head}</span>);
        }
      }
    },
    { field: "name", headerName: "Particular Name", width: 180 },
    { field: "length", headerName: "Length", width: 70 },
    { field: "width", headerName: "Width", width: 70 },
    { field: "height", headerName: "Height", width: 70 },
    { field: "area", headerName: "Area", width: 70 },
    { field: "qty", headerName: "Qty", width: 70 },
    { field: "unit", headerName: "Unit", width: 70 },
    { field: "days", headerName: "Days", width: 70 },
    { field: "rate", headerName: "Rate", width: 70 },
    { field: "amt", headerName: "Amt", width: 70 },
    {
      field: "remark",
      headerName: "Remarks",
      width: 230,
      renderCell: (params) => {
        if (params.row.remark === '' || params.row.remark === null) {
          return (<span className='text-white'>--NA--</span>);
        }
        else {
          return (<span className='text-white'>{params.row.remark}</span>);
        }
      }
    },
  ];

  //delete production rider
  const deleteProductionRider = () => {
    var data = JSON.stringify({
      "ROWPOS": dltRowpos,
    });

    var config = {
      method: 'post',
      url: DELETE_INDENT_PR,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess === true) {
          // alert(response.data.message);
          window.location.reload();

        }
        else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const PRcolumn = [
    { field: "area", headerName: "area" },
    { field: "description", headerName: " DESCRIPTON", width: 120 },
    { field: "grouping", headerName: "grouping", width: 110 },
    { field: "height", headerName: "height", width: 110 },
    { field: "width", headerName: "width", width: 110 },
    { field: "location", headerName: "location", width: 110 },
    { field: "quantity", headerName: "quantity", width: 110 },
  ]
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert '0' to '12'
    return `${formattedHours}:${minutes} ${ampm}`;
  }

  const brandcolumns = [
    {
      field: "eDate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const date = params?.row?.eDate?.split("T")[0];
        return (
          <p className='text-white mt-2 f-14'>{date}</p>
        );
      }
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const time = params?.row?.eDate?.split("T")[1];
        const formattedTime = time ? formatTime(time) : "--:--";
        return (
          <p className='text-white mt-2 f-14'>{formattedTime}</p>
        );
      }
    },
    {
      field: "head",
      headerName: "Particular Type",
      width: 180,
      renderCell: (params) => {
        if (params.row.head === '' || params.row.head === null) {
          return (<span className='text-white'>--NA--</span>);
        } else {
          return (<span className='text-white'>{params.row.head}</span>);
        }
      }
    },
    { field: "name", headerName: "Particular Name", width: 180 },
    { field: "length", headerName: "Size", width: 90 },
    { field: "qty", headerName: "Qty", width: 90 },
    { field: "rate", headerName: "Rate", width: 90 },
    { field: "amt", headerName: "Amt", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    { field: "areA_USED", headerName: "Area Used", width: 100 },
    {
      field: "remark",
      headerName: "Remarks",
      width: 500,
      renderCell: (params) => {
        if (params.row.remark === '' || params.row.remark === null) {
          return (<span className='text-white'>--NA--</span>);
        } else {
          return (<span className='text-white'>{params.row.remark}</span>);
        }
      }
    },
  ];



  // const brandcolumns = [
  //   { field: "area", headerName: "Area" },
  //   { field: "description", headerName: "Description", width: 120 },
  //   { field: "grouping", headerName: "Grouping", width: 110 },
  //   { field: "height", headerName: "Height", width: 110 },
  //   { field: "width", headerName: "Width", width: 110 },
  //   { field: "location", headerName: "Location", width: 110 },
  //   { field: "quantity", headerName: "Quantity", width: 110 },
  //   { field: "rateAmount", headerName: "Rate Amount", width: 110 },
  //   { field: "rateType", headerName: "Rate Type", width: 110 },
  //   { field: "remarks", headerName: "Remarks", width: 110 },
  //   { field: "status", headerName: "Status", width: 110 },
  //   { field: "totalAmount", headerName: "Total Amount", width: 110 },
  //   { field: "unit", headerName: "Unit", width: 110 },
  //   {
  //     field: "ALLOCATE VENDOR",
  //     headerName: "Allocate",
  //     sortable: false,
  //     width: 150,
  //     disableClickEventBubbling: true,
  //     renderCell: () => {
  //       return (
  //         <button className="btn btn-success" onClick={handleAllbrand}>
  //           Allocate Vendor
  //         </button>
  //       );
  //     },
  //   },
  //   // {
  //   //   field: "edit",
  //   //   headerName: "Edit",
  //   //   sortable: false,
  //   //   width: 130,
  //   //   disableClickEventBubbling: true,
  //   //   renderCell: () => {
  //   //     return (
  //   //       <Button variant="contained" color="primary">
  //   //         EDIT
  //   //       </Button>
  //   //     );
  //   //   },
  //   // },
  // ];

  const column = [
    {
      field: "edate",
      headerName: "Created At",
      width: 360,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params.row.edate.split("T")[0]}</p>
        );
      }
    },
    { field: "vendorName", headerName: "Vender Name", width: 360 },
    { field: "allocation", headerName: "Allocation", width: 360 },
    // { field: "vendorID", headerName: "vendorID", width: 200 },

    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button variant="contained" color="primary">
    //         delete
    //       </Button>
    //     );
    //   },
    // },
  ]
  const columnForDailyUpdatesForProject = [
    {
      field: "edate",
      headerName: "Created At",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'employeeName', headerName: 'Employee Name', width: 190, },
    { field: 'projectName', headerName: 'Event Name', width: 230, },
    { field: 'subject', headerName: 'Subject', width: 250, },
    { field: 'remarks', headerName: 'Remarks', width: 250, },
    {
      field: "path",
      headerName: "View Documents",
      sortable: false,
      width: 230,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button variant="contained" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }} color="primary" onClick={e => { viewDailyDocFoeEmp(params.row.dailyUpdate_docs) }}>view</Button>
        );
      }
    }
  ];
  const addCmpnyDoc = () => {
    alert("Add Successfully");
    window.location.reload();
  }
  const updateProject = (proState) => {
    var won;
    if (proState === TENDER_STATE.RUNNING) {
      won = false;
    }
    else if (proState === TENDER_STATE.COMPLETED) {
      won = true;
    }
    var data = JSON.stringify({
      "ROWPOS": projectName,
      "Interested": true,
      "Remarks": "",
      "WON": won
    });

    var config = {
      method: 'post',
      url: UPDATE_PROJECT,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.isSuccess === true) {
          window.location.reload();
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleCollapsShow = () => {
    if (collapsShow) {
      setCollapsShow(false);
    }
    else {
      setCollapsShow(true);
    }
  }
  const setTyprDoc = () => {
    if (TyprDocVal) {
      setTyprDocVal(false);
    }
    else {
      setTyprDocVal(true);
    }
  }
  const componentRef = useRef();
  const handleGoToPrv = (xe) => {
    navigate(-1)
  }
  return (
    <div className='row'>
      {
        (props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ||
          props.tokectDetails?.logintype === ALLOWED_USERTYPE.CREATIVE ||
          props.tokectDetails?.logintype === ALLOWED_USERTYPE["CREATIVE-LEAD"]) ? (
          <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
            <SideBar ref={componentRef} />
          </div>
        ) : null
      }

      {/* {
        props?.tokectDetails?.logintype === ALLOWED_USERTYPE["CREATIVE-LEAD"] ?
          <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
            <SideBar />
          </div> : null
      } */}
      <div className={`${props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN
        ? 'col-md-12 col-12 col-sm-12 '
        : 'col-md-10 col-10 col-sm-10'} myview`}>
        <div className='p-4'>
          <span className="cursor-pointer"><a className="mt-4" href="#" onClick={e => { handleGoToPrv(e) }}>Go Back</a></span>
          <span className="float-end cursor-pointer"><a href="#" onClick={e => { updateProject(eventname?.state) }}>Send Into Previous Stage</a></span>
          <h3 className='tracking-in-expand-fwd-bottom text-center my-3 SecoHead proNameSec' >{eventname?.projectName}{eventname?.lost}
            <span className='text-white h3'>{eventname?.lost === true ? " (Lost)" : null}</span>
          </h3>
          <h4 className='tracking-in-expand-fwd-bottom text-secondary my-3 text-white fw-600' >Project Details</h4>
          <div className="row">
            <div className="col-12 col-sm-12 col-sm-12">
              <div className="table-responsive-sm">
                <table className=" table table-sm h-auto accordion">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col h4">CLIENT NAME</th>
                      <th scope="col h4">PROJECT CATEGORY</th>
                      <th scope="col h4">PROJECT LEAD</th>
                      <th scope="col h4">PROJECT TYPE </th>
                      <th scope="col h4">LAST DATE OF PRE-BID SUBMISSION</th>
                      <th scope="col h4">EVALUATION</th>
                      <th scope="col h4">MORE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td>{eventname?.clientName === "" ? "--NA--" : eventname?.clientName}</td>
                      <td>{eventname?.category === "" ? "--NA--" : eventname?.category}</td>
                      <td>{eventname?.ownerName === "" ? "--NA--" : eventname?.ownerName}</td>
                      <td>{eventname?.projectType === "" ? "--NA--" : eventname?.projectType}</td>
                      <td>{eventname?.ldateOfSubmissionBid === "" ? "--NA--" : new Date(eventname?.ldateOfSubmissionBid).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}</td>
                      <td>{eventname?.evaluation === "" ? "--NA--" : eventname?.evaluation}</td>
                      <td>
                        <a data-bs-toggle="collapse" data-bs-target="#r1" onClick={e => { handleCollapsShow(e) }}>
                          <KeyboardDoubleArrowDownIcon />
                        </a>
                      </td>
                    </tr>
                    {collapsShow ?
                      <tr className=" accordion-collapse" id="r1" data-bs-parent=".table">
                        <td colSpan="7">
                          <div className="row">
                            <div className="col-6 col-sm-6 my-2 ">
                              <span className="fw-bold"> ESTIMATED PROJECT COST :</span>{eventname?.estimatedProjectCost === "" ? "--NA--" : eventname?.estimatedProjectCost}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> EARNEST MONEY DEPOSIT(EMD) :</span>{eventname?.emd === "" ? "--NA--" : eventname?.emd}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> TENDER DOCUMENT FEE :</span>{eventname?.tenderDocumentFee === "" ? "--NA--" : eventname?.tenderDocumentFee}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold">  PROCESS OF SUBMISSION OF BID :</span>{eventname?.processOfSubmissionBid === "" ? "--NA--" : eventname?.processOfSubmissionBid}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold">  LAST DATE OF PRE-BID QUERIES :</span>{eventname.lDateOfPreBidQueries === "--NA--" ? " TBC" : new Date(eventname.lDateOfPreBidQueries).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> DATE OF OPENING TECHNICAL BIDS :</span>{eventname?.technicalBidOpenDate === "--NA--" ? " TBC" : new Date(eventname?.technicalBidOpenDate).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> PRESENTATION DATE :</span>{eventname?.presentationDate === " --NA--" ? " TBC" : new Date(eventname?.presentationDate).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> DATE OF PRE-BID MEETING :</span>{eventname?.preBidMeetingDate === " --NA--" ? " --NA--" : new Date(eventname?.preBidMeetingDate).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold">ELIGIBILITY ISSUE :</span>{eventname?.evaluationIssue === "" ? " --NA--" : eventname?.evaluationIssue}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> SCORING ISSUE :</span>{eventname?.scoringIssue === "" ? " --NA--" : eventname?.scoringIssue}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> EVENT DATE :</span>{eventname?.eventDate === " --NA--" ? " TBC" : `${eventname.eventDate} to ${eventname.eventToDate}`}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> DATE OF OPEN FINANCIAL BIDS :</span>{eventname?.openFinancialBids === " --NA--" ? " TBC" : new Date(eventname?.openFinancialBids).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> CONSORTION ALLOWED : </span>{eventname?.consortionAllowed === true ? " Yes" : " No"}
                            </div>
                            <div className="col-6 col-sm-6 my-2">
                              <span className="fw-bold"> REMARKS : </span>{eventname?.proRemarks}
                            </div>
                          </div>

                        </td>
                        {
                          userType === ALLOWED_USERTYPE.ADMIN ? <td className="mt-3">  <Link to={`/projectList/updateform?id=${projectName}`}>
                            <div style={{ color: "#fff", float: "right", background: "rgb(9, 99, 88)", padding: "5px 10px", fontSize: "14px", borderRadius: "6px" }}><ion-icon name="create"></ion-icon>EDIT</div>
                          </Link></td> : null
                        }

                      </tr> : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row my-4">
            <h4 className='tracking-in-expand-fwd-bottom text-secondary my-3 fw-600 text-white'>BID Document</h4>
            {docList?.filter((data, i) => {
              if (data.type === "MERGED_FILE") {
                return data;
              }
            }).map((data, i) =>
              <div key={i} className="col-md-3 col-sm-3 col-3 mx-auto text-center">

                <a href={data.path} target="blank" className='cursor-pointer'>
                  <iframe className='cursor-pointer' src={data.path} width="100%" height="300px" >
                  </iframe>

                </a>
                <div className="my-4 text-white">{new Date(data.edate).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')}</div>
              </div>
            )
            }
            <div className='px-3 text-center'>
              <div className='row' style={{ marginTop: "2%" }}>
                <div className="col-md-3 col-sm-3 col-6">
                  <button style={{
                    borderRadius: 5,
                    padding: "6px 20px",
                    fontSize: "14px",
                    border: "transparent",
                    color: "#fff",
                  }} className=' btn btnSuccess tracking-in-expand-fwd-top' onClick={e => { handleOpenDocList(e) }}>View Filing Document</button>
                </div>
                <div className="col-md-3 col-sm-3 col-6" >
                  <div className="dropdown">
                    <button type="button" style={{
                      borderRadius: 5,
                      padding: "6px 20px",
                      fontSize: "14px",
                      border: "transparent",
                      color: "#fff",
                    }} className="btn btnSuccess tracking-in-expand-fwd-topdropdown-toggle" data-bs-toggle="dropdown" onClick={setTyprDoc}>
                      Upload More
                    </button>
                    {
                      TyprDocVal ? <ul className="dropdown" style={{ listStyleType: "none", background: "#f3f3f3", paddingLeft: "0px", marginTop: "8%" }}>
                        <li className="my-2"><h5 className="dropdown-header">--SELECT UPLODED DOCUMENT TYPE--</h5></li>
                        <li className="my-2"><a className="dropdown-item" onClick={e => { handleOpenPresentationDoc1("WORKORDER_DOCUMENT") }}>Work Order</a></li>
                        <li className="my-2"><a className="dropdown-item" onClick={e => { handleOpenPresentationDoc1("COMPLITION_CERTIFICATE") }}>Completions  Certificate </a></li>
                        <li className="my-2"><a className="dropdown-item" onClick={e => { handleOpenPresentationDoc1("OTHER") }}>Other</a></li>
                      </ul>
                        : null
                    }

                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6 text-center">
                  <div className="">
                    <button onClick={hideallocate}
                      className="btn btnSuccess"
                      style={{
                        borderRadius: 5,
                        padding: "6px 20px",
                        fontSize: "14px",
                        border: "transparent",
                        color: "#fff",
                      }}
                    >Allocate Employee &nbsp;<ion-icon name="arrow-forward-outline"></ion-icon>
                    </button>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6" >

                  <div className="">
                    <button onClick={handleproduction}
                      className="btn btnSuccess"
                      style={{
                        borderRadius: 5,
                        padding: "6px 20px",
                        fontSize: "14px",
                        border: "transparent",
                        color: "#fff",
                      }}
                    >Add New Production Rider
                    </button>
                  </div>

                </div>
              </div>
            </div>
            {/* view filling documents List */}
            {
              viewDocList ?
                <div className="row"> <div className="col-12 col-sm-12 col-sm-12">
                  <div className="popupbox" style={{ top: "-1%", left: "0%", zIndex: "111" }}>
                    <div className='popup' style={{ width: "50%" }}>


                      <div className=''>
                        <p className='text-secondary h4'>Filing Documents <span style={{ float: "right", cursor: "pointer" }} onClick={e => { handleCloseDocList(e) }}>❌</span></p>
                        <div className="table-responsive py-4 overflow-scroll">
                          <table className="table h-auto accordion overflow-scroll">
                            <thead >
                              <tr>
                                <th className='text-dark' scope="row">#</th>
                                <th className='text-dark' scope="col">Document Type</th>
                                <th className='text-dark' scope="col">View</th>
                                <th className='text-dark' scope="col">Document Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                docList.length !== 0 ?
                                  docList?.filter((data, i) => {
                                    if (data.type !== "MERGED_FILE" && data.type !== 'ELIGIBILITY_DOCUMENT' && data.type !== 'PROJECT_DOCUMENT' && data.type !== 'CORRIGENDUM_DOCUMENT') {
                                      return data;
                                    }
                                  }).map((data, i) =>
                                    <>
                                      <tr key={i}>
                                        <td className='text-dark'>{i + 1}</td>
                                        <td className='text-dark'>{data.subType}</td>
                                        <td className='text-dark'>{data.type}</td>
                                        <td className='text-dark'> <a href={data.path} target="blank">{/*data-bs-toggle="collapse" data-bs-target={`#Doc_${data.document_ID}`}*/}
                                          {/* <KeyboardDoubleArrowDownIcon/> */}view
                                        </a></td>

                                      </tr>
                                      <tr className="collapse accordion-collapse" id={`Doc_${data.document_ID}`} data-bs-parent=".table">
                                        <td colSpan="2">
                                          {data?.path?.split(".")[data?.path?.split(".").length - 1] === "pdf" ?
                                            ""
                                            // <a href={data.path} target="blank"> <img src={pdfImg}alt="DocList"/></a>
                                            :
                                            <a href={data.path} target="blank"> <img className="w-100" src={data.path} alt="DocList" /></a>}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                  : <p className="text-center">No Data Found!!</p>}
                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>
                  </div>

                </div></div> : null
            }
            {/*data popo up */}

            {
              openDataPopup ?
                <div className="row"> <div className="col-12 col-sm-12 col-sm-12">
                  <div className="popupbox" style={{ top: "-1%", zIndex: "111" }}>
                    <div className='popup' style={{ width: "30%" }}>
                      <div className=''>
                        <p className='text-secondary h5'>Please Enter the date</p>
                        <div className="mb-2 text-center">
                          <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                          <input type="datetime-local" className="form-control mt-3" name="recAcceptDate" id="recAcceptDate" onChange={(e) => onDateChange(e)} max="9999-12-31T11:59:59" />{/*value={dates.recAcceptDate}*/}
                        </div>
                        <div className="text-center"><button className="btn btn-success cursor-pointer" onClick={e => { setOpenDataPopup(false) }} >send</button></div>
                      </div>
                    </div>
                  </div>
                </div></div> : null
            }
            {
              openDataPopup1 ?
                <div className="row"> <div className="col-12 col-sm-12 col-sm-12">
                  <div className="popupbox" style={{ top: "-1%", zIndex: "111" }}>
                    <div className='popup' style={{ width: "30%" }}>
                      <div className=''>
                        <p className='text-secondary h5'>Please Enter the date</p>
                        <div className="mb-2 text-center">
                          <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                          <input type="datetime-local" className="form-control mt-3" name="recWorkDate" id="recWorkDate" onChange={(e) => onDateChange(e)} max="9999-12-31T11:59:59" />{/*value={dates.recWorkDate}*/}
                        </div>
                        <div className="text-center"><button className="btn btn-success cursor-pointer" onClick={e => { setOpenDataPopup1(false) }} >send</button></div>
                      </div>
                    </div>
                  </div>
                </div></div> : null
            }
            {
              openDataPopup2 ?
                <div className="row"> <div className="col-12 col-sm-12 col-sm-12">
                  <div className="popupbox" style={{ top: "-1%", zIndex: "111" }}>
                    <div className='popup' style={{ width: "30%" }}>
                      <div className=''>
                        <p className='text-secondary h5'>Please Enter the date</p>
                        <div className="mb-2 text-center">
                          <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                          <input type="datetime-local" className="form-control mt-3" name="securityDpstDate" id="securityDpstDate" onChange={(e) => onDateChange(e)} max="9999-12-31T11:59:59" />{/*value={dates.securityDpstDate}*/}
                        </div>
                        <div className="text-center"><button className="btn btn-success cursor-pointer" onClick={e => { setOpenDataPopup2(false) }} >send</button></div>
                      </div>
                    </div>
                  </div>
                </div></div> : null
            }
            {
              openDataPopup3 ?
                <div className="row"> <div className="col-12 col-sm-12 col-sm-12">
                  <div className="popupbox" style={{ top: "-1%", zIndex: "111" }}>
                    <div className='popup' style={{ width: "30%" }}>
                      <div className=''>
                        <p className='text-secondary h5'>Please Enter the date</p>
                        <div className="mb-2 text-center">
                          <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                          <input type="datetime-local" className="form-control mt-3" name="EMDReleasedDate" id="EMDReleasedDate" onChange={(e) => onDateChange(e)} max="9999-12-31T11:59:59" />{/*value={dates.EMDReleasedDate}*/}
                        </div>
                        <div className="text-center"><button className="btn btn-success cursor-pointer" onClick={e => { setOpenDataPopup3(false) }} >send</button></div>
                      </div>
                    </div>
                  </div>
                </div></div> : null
            }
          </div>

          {/* <Link to={`/projectList/updateform?id=${projectName}`}>
                  <div  style={{color:"#fff",float:"right",background:"rgb(9, 99, 88)",padding:"5px 10px",fontSize:"14px",borderRadius:"6px"}}><ion-icon name="create"></ion-icon>EDIT</div>
                  </Link> */}
          {
            viewUploadMoreDoc ?
              <div className="row py-2">
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "#0f533d",
                      textTransform: "uppercase",
                      fontWeight: "900",
                      textAlign: "center",

                      // marginTop: "-30px",
                    }}
                    className="mb-2"
                  >
                    {" "}
                    <span className="text-white h4 text-capitalize mrglft-5"> Upload More Document
                      <span className="mb-0 mrglft-5 h5 cursor-pointer " onClick={e => { handleClosePresentationDoc1() }} >❌</span></span>
                  </h3>
                </div>
                <div className="col-md-4 col-sm-4 col-4"></div>
                <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                  <div className="my-4 text-center">
                    <label className="form-label text-capitalize">Document Name</label> <br />
                    <input type="text" placeholder='Enter Document Name..' onChange={e => { setGetDocName(e.target.value) }} className="w-100 mt-2" />
                  </div>
                  <div className="my-4 text-center">
                    <label className="form-label text-capitalize">Upload Document</label> <br />
                    <input type="file"
                      onChange={(e) => handlefile(e)}
                      className="form-control w-100 mt-2" />

                  </div>
                  {isSubmitFile ? <span className='text-white text-start '>{`${uploadedPrecentage}%`}</span> : ""}
                  <div style={{ height: "4%" }} className="text-start my-3">
                    <div
                      className="progress-bar progress-bar-striped bg-success "
                      style={{
                        width: `${uploadedPrecentage}%`,
                        backgroundColor: "#198754",
                        height: "100%",
                      }}
                      aria-valuenow={uploadedPrecentage}
                    ></div>
                  </div>
                  {isSubmitFile ? <div className='col-auto my-3 text-center'>
                    <span className="btn btn-danger text-primary cursor-pointer text-white"
                      onClick={() => cancelUpload()}
                      style={{
                        fontSize: "14px",

                      }}>
                      Cancel
                    </span>

                  </div> :
                    <div className='col-auto my-3 text-center'>
                      <span className="btn  text-primary cursor-pointer text-white"
                        style={{
                          borderRadius: 5,
                          backgroundColor: "green",
                          fontSize: "14px",
                          border: "transparent",
                          color: "#fff"
                        }}
                        onClick={e => { uploadfiles1() }}>
                        Submit
                      </span>
                    </div>
                  }

                </div>
              </div> : null
          }


          {
            popupcell ?

              (
                <div className="row py-5">
                  <div className="col-md-12 col-sm-12 col-12">
                    <p
                      className="mb-2"
                    >
                      <span className="text-white h4 text-capitalize mrglft-8">ALLOCATE VENDOR</span>

                      <p className="mb-0 mrglft-5 h5" onClick={(e) => setpopupcell(false)} >❌</p>
                    </p>
                  </div>

                  <div className="col-md-4 col-sm-4 col-4"></div>
                  <div className="col-md-4 col-sm-4 col-4 py-3 formClass" >
                    <div className="my-5">
                      <label className="form-label">Event Name</label> <br />
                      <select style={{ marginTop: "12px", color: "green", fontWeight: "800" }} className="w-100">
                        <option value={eventname?.project_ID} >{eventname?.projectName} </option>

                      </select>
                    </div>
                    <div className="my-5">
                      <label className="form-label">Select Vendor</label>
                      {/* {vList} */}
                      <select className="w-100 form-control" onChange={e => { onHandleVendor(e.target.value) }}>

                        <option value="" >--Select Vendor --</option>
                        {
                          eventVendor.map((c, i) =>

                            <option value={c.mcode} key={i} >{c.vendorName} </option>
                          )

                        }

                      </select>
                    </div>
                    <div className="my-5"> <input type="number"
                      className="w-100"
                      placeholder="enter percentage "
                      value={percent}
                      onChange={(e) => setpercent(e.target.value)}
                    /></div>
                    <div className="my-5">
                      <center><Button onClick={allocateVendor} style={{ background: "green", color: "#fff", padding: "5px 20px" }} >SUBMIT</Button></center>
                    </div>
                  </div>
                </div>

              )

              : ""
          }

          <div className="row">

          </div>
          {/*Allocate employee */}
          {showallocate ?
            <div className="row py-2">
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  className="text-white h4 text-capitalize text-center mrglft-8"
                >
                  Allocate Employee <span className="mb-0 mrglft-5 h5 cursor-pointer" onClick={showALLocate}>❌</span>
                </h3>
              </div>

              <div className="col-md-4 col-sm-4 col-4"></div>
              <div className="col-md-4 col-sm-4 col-4 py-3 formClass text-center" >
                <div className="my-3">
                  <label className="form-label text-white text-capitalize">Employee Name</label> <br />
                  <select
                    className="w-100 mt-3"
                    aria-label="Default select example"
                    style={{ width: "200px" }}
                    value={empname}
                    id="empName"
                    onChange={(e) => setempname(e.target.value)}
                  >
                    <option>select employee</option>
                    {dropdown?.map((emp, i) => (
                      <option key={i} value={emp.employeeID}>{emp.firstName} {emp.lastName}</option>
                    ))}
                  </select>
                </div>

                <div className="my-3">
                  <label className="form-label text-white text-capitalize">
                    Allocate Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <input
                    type="date"
                    id="empAllocateDate"
                    disabled
                    value={allocateDate}
                    onChange={(e) => setallocateDate(e.target.value)}
                    className="form-control w-100 mt-3"
                    max="9999-12-31"
                    style={{ "display": "initial" }}
                  />
                </div>
                <div className="my-3">
                  <button
                    style={{
                      borderRadius: 5,
                      backgroundColor: "green",
                      padding: "6px 20px",
                      fontSize: "14px",
                      border: "transparent",
                      color: "#fff",
                      marginTop: "20px",
                    }}
                    onClick={handleEmpAllocate}
                  >
                    SUBMIT
                  </button>
                </div>
                {/* <div className='table animatingBottom' style={{width:"80%"}}>
             {dataTable}
             
             </div> */}
              </div>
            </div>

            :
            null}

          <div className="row mb-5">

            {/* <div className="col-md-2 col-2  col-sm-4"></div> */}
            <div className="col-md-4 col-4 col-sm-4">
              <div
                className={costhover}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handlecost}
              >
                <div className="totalbg text-center m-auto p-0 h3">Costing Sheet</div>
              </div>

            </div>
            <div className="col-md-4 col-sm-4 col-4">
              <div
                className={brandhover}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handlebrand}
              >
                <div className="totalbg text-center m-auto p-0 h3">Branding Sheet</div>

              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-4">
              <div
                className={Orderhover}
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={handleOrder}
              >
                <div className="totalbg text-center m-auto p-0 h3">Work Order</div>

              </div>
            </div>
          </div>
          {
            brandpopupcell ?
              (
                <div>
                  <div className="formbg text-center pt-0">
                    <center>
                      {" "}
                      <h3
                        className="text-white h4 text-capitalize mrglft-5"
                      >
                        Allocate Vendor
                        <span className="mb-0 mrglft-5 h5 cursor-pointer " onClick={e => { setbrandpopupcell(false) }} >❌</span>
                      </h3>
                    </center>

                    <div className="row mb-2">
                      <div className="col-md-4 col-sm-4 col-4">
                      </div>
                      <div className="col-md-4 col-sm-4 col-4 formClass px-3">
                        <div className="my-3">
                          <label className="form-label text-capitalize">Event Name</label>
                          <select className="w-100 mt-3">
                            <option value={eventname?.project_ID} >{eventname?.projectName} </option>
                          </select>
                        </div>
                        <div className="my-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label text-capitalize mb-2"
                          >
                            Select Vendor
                          </label>
                          {/* {vList} */}
                          <select className="w-100 form-control mt-3" onChange={e => { onHandleVendor(e.target.value) }}>

                            <option value="" >--Select Vendor --</option>
                            {
                              eventVendor.map((c, i) =>

                                <option value={c.mcode} key={i} >{c.vendorName} </option>
                              )

                            }

                          </select>
                        </div>
                        <div className="my-3">
                          <label className="form-label text-capitalize">Allocate Percentage</label>

                          <input type="number"
                            placeholder="enter percentage "
                            className="w-100 mt-3"
                            value={percent}
                            onChange={(e) => setpercent(e.target.value)}
                          />
                        </div>
                        <div className="my-5">
                          <center><Button onClick={allocateVendor} style={{ background: "green", color: "#fff", padding: "5px 20px" }} >SUBMIT</Button></center>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )

              : ""
          }

          {/* ----------------------------- */}
          {cost ? (
            <>
              <ExcelUpload refreshData={getCostByProId} title={"upload internal Cost Sheet"} dwnPath={Costsheetdefault} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} onUploadSuccess={(type) => handleExcelUpload(type)} />
              <DataGrids name={"Cost_Sheet"} cardsList={costData} columns={costcolumns} />
              <div className="totalcostprc">
                <p style={{ marginTop: "-5%" }}>
                  Total Amount :
                  <span style={{ fontWeight: "800" }}>
                    {" "}
                    &#x20b9; &nbsp; &nbsp;{countTotalcost}
                  </span>
                </p>
              </div>

              <div>
                <FloatingBtn
                  href={`/ItemList/form/${projectName}`}
                  usehtmlFor={`COST`}
                />
              </div>
              <div
                className="d-flexed mt-3 tracking-in-expand-fwd-top"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: "70%",
                }}
              >
              </div>

            </>
          ) :
            null}
          {brand ? (
            <>
              <ExcelUpload refreshData={getBrandSheetByProId} title={"upload Brand Sheet"} dwnPath={BrandingSheet} ProjectId={projectName} reloadFun={reloadFun} for={selectedExcelType} setLoading={setLoading} onUploadSuccess={(type) => handleExcelUpload(type)} />
              {BrandData?.length > 0 ?
                <div className='table-responsive mt-3 emgncy'>
                  <table className="table table-bordered border-secondary tblHeader" id="rspsblSheet" >
                    <thead className='tblHeader'>
                      <tr>
                        <th scope="col text-white">S.No. </th>
                        <th scope="col text-white" className={"d-none"}>Responsive</th>
                        <th scope="col text-white">Name</th>
                        <th scope="col text-white">Create Date</th>
                        <th scope="col text-white">Create Time</th>
                        <th scope="col text-white">Created By</th>

                        <th scope="col text-white">View  </th>
                      </tr>
                    </thead>
                    <tbody className='tblBdy'>
                      {
                        BrandData?.map((data, i) => {
                          return (<tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td className={"d-none"}>{data.responsive}</td>
                            <td>{data.name}</td>
                            <td>{data.edate.split("T")[0]}</td>
                            <td>{formatTime(data.edate.split("T")[1])}</td>
                            <td>{data.euser}</td>
                            <td>
                              <button
                                className='btn btn-success'
                                onClick={() => handleViewClick(data.path)}
                              >
                                View
                              </button>
                            </td>
                          </tr>);
                        }
                        )
                      }

                    </tbody>
                  </table>
                </div>
                :
                ""
              }
              <div>
                <FloatingBtn
                  href={`/ItemList/form/${projectName}`}
                  usehtmlFor={` BRAND `}
                />
              </div>
              <br />

              <br />
              <div
                className="d-flexed tracking-in-expand-fwd-top"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: "70%",
                }}
              >

              </div>
            </>
          ) : null}
          {Order ? (
            <>
              <h4 className="text-center mb-4" style={{ color: "white" }}>Upload Work Order</h4>
              <div >

                <div className="d-flexed">
                  <div>
                    <label>Particular Name:</label>
                    <input type="text" name="Particular_Name" value={formData.Particular_Name} onChange={handleChange} />
                  </div>
                  <div>
                    <label>Quantity:</label>
                    <input type="number" name="Qty" value={formData.Qty} onChange={handleChange} />
                  </div>
                  <div>
                    <label>Amount:</label>
                    <input type="number" name="Amt" value={formData.Amt} onChange={handleChange} />
                  </div>

                  <button className="btn btn-info" type="submit" onClick={(e) => { handleSubmit(e) }} >Submit</button>
                </div>


              </div>
              <br />
              {workOrderData?.length > 0 ?
                <div className='table-responsive mt-3 emgncy'>
                  <table className="table table-bordered border-secondary tblHeader" id="rspsblSheet" >
                    <thead className='tblHeader'>
                      <tr>
                        <th scope="col text-white">S.No. </th>

                        <th scope="col text-white">Particular Name</th>
                        {/* <th scope="col text-white">Create Date</th>
                        <th scope="col text-white">Create Time</th> */}
                        <th scope="col text-white">Amount</th>

                        <th scope="col text-white">Quantity  </th>
                      </tr>
                    </thead>
                    <tbody className='tblBdy'>
                      {
                        workOrderData?.map((data, i) => {
                          return (<tr key={i}>
                            <th scope="row">{i + 1}</th>

                            <td>{data.name}</td>
                            {/* <td>{data.edate.split("T")[0]}</td>
                            <td>{formatTime(data.edate.split("T")[1])}</td> */}
                            <td>{data.amt}</td>
                            <td>
                              {data.qty}

                            </td>
                          </tr>);
                        }
                        )
                      }

                    </tbody>
                  </table>
                </div>
                :
                ""
              }

              <br />

              <div
                className="d-flexed tracking-in-expand-fwd-top"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: "70%",
                }}
              >


              </div>
            </>
          ) : null}







          {
            viewTeams ?
              <div style={{ height: "100vh", width: "100%" }}>
                <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseTeams()}>
                  ❌
                </p>

                <div className="formbg">
                  <center>
                    {" "}
                    <h3
                      className="tracking-in-expand-fwd-bottom"
                      style={{
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "4px",
                        color: "rgb(9, 82, 85)",
                        marginTop: "-60px"
                      }}
                    >
                      Team Member List
                    </h3>
                  </center>
                  <br /><br />

                  <div style={{ width: "80%", margin: "auto" }}>
                    <div className="table">

                      {/* <DataGrid
        rows={taledata}
         columns={columnsForTeams}
         getRowId={(row) => row.rowpos}
         pageSize={10}
         onSelectionModelChange={(ids) => {
         }}
         />  */}
                      {/* {empDoc} */}
                      <DataGrid
                        rows={taledata}
                        columns={columnsForTeams}
                        getRowId={(row) => row.rowpos}
                        pageSize={10}
                        onSelectionModelChange={(ids) => {
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              : null
          }
          {/*show vendor List Data */}
          {
            viewVendorsTeam ? <div style={{ height: "100vh", width: "100%" }}>
              <p className="float-end cursor-pointer p-4" onClick={(e) => handleCloseVendors()}>
                ❌
              </p>

              <div className="formbg">
                <center>
                  {" "}
                  <h3
                    className="tracking-in-expand-fwd-bottom"
                    style={{
                      fontWeight: "900",
                      textTransform: "uppercase",
                      letterSpacing: "4px",
                      color: "rgb(9, 82, 85)",
                      marginTop: "-60px"
                    }}
                  >
                    Vendors List
                  </h3>
                </center>
                <br /><br />

                <div style={{ width: "80%", margin: "auto" }}>
                  <div className="table">
                    <DataGrid
                      rows={dataTableForVendor}
                      columns={column}
                      getRowId={(row) => row.rowpos}
                      pageSize={10}
                      onSelectionModelChange={(ids) => {
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
              : null
          }

          {/*show DailyUpdates By Project List */}
          {
            viewDailyUpdatesByProject ?
              <div style={{ height: "100vh", width: "100%" }}>
                <p className="float-end cursor-pointer p-4" onClick={(e) => handleClosedailyUpdates()}>
                  ❌
                </p>

                <div className="formbg">
                  <center>
                    {" "}
                    <h3
                      className="tracking-in-expand-fwd-bottom"
                      style={{
                        fontWeight: "900",
                        textTransform: "uppercase",
                        letterSpacing: "4px",
                        color: "rgb(9, 82, 85)",
                        marginTop: "-60px"
                      }}
                    >
                      Daily Updates
                    </h3>
                  </center>
                  <br /><br />

                  <div style={{ width: "80%", margin: "auto" }}>
                    <div className="table">
                      <DataGrid
                        rows={allDailyUpdates}
                        columns={columnForDailyUpdatesForProject}
                        getRowId={(row) => row.rowpos}
                        pageSize={10}
                        onSelectionModelChange={(ids) => {
                        }}
                      />
                      {/* {allDailyUpdates} */}
                    </div>
                  </div>
                </div>
              </div> : null
          }
          {/*show emp daily updates popus */}
          {showEmpDailyUpdatesPops ?
            <div className="popupbox" style={{ position: "fixed", top: "-1%" }}>
              <div className='popup' style={{ width: "50%", position: "fixed" }}>
                <p style={{ float: "right", cursor: "pointer" }} onClick={handlecrossEmpDailyUpdates}>❌</p>
                <h3 className="text-danger">Daily Updates</h3>
                <DataGrid
                  className='animatingTop'
                  rows={DailyUpdatesForEmp}
                  columns={columnForDUpdatesOFEmp}
                  pageSize={10}
                  getRowId={(row) => row.rowpos}

                />
              </div>
            </div> : null
          }

          {/*DocShow popup*/}
          {
            popupdoc ?

              <div className='popup' style={{ width: "50%", position: "fixed" }}>
                <p style={{ float: "right", cursor: "pointer" }} onClick={handlecrossDocPopo}>❌</p>
                {
                  ViewDoc
                }
              </div>
              :
              ""
          }
          {
            eventname?.iS_RECEIVED_ACCEP === "U" ?
              <div className="row mt-3 theamColor bg-gray" style={{ marginTop: "5%" }}>
                <h4 className="theamColor mt-3 text-center"> Question And Answer</h4>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4'>
                  <div className='row my-2'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.1: </span>
                    </div>
                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Received letter of acceptance.</span>
                    </div>
                  </div>
                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-4 px-5'>
                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="A" name="a" style={{ width: "13px", height: "13px" }} onClick={e => { updateRecAccept(true) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                    </div>

                    <div className='col-sm-4 px-4'>
                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="B" name="a" style={{ width: "13px", height: "13px" }} onClick={e => { updateRecAccept(false) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.2:</span>
                    </div>

                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Received work Order.</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-4 px-5'>

                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="A" name="b" style={{ width: "13px", height: "13px" }} onClick={e => { updateWorkOrder(true) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className='col-sm-4 px-3'>
                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="B" name="b" style={{ width: "13px", height: "13px" }} onClick={e => { updateWorkOrder(false) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.3:</span>
                    </div>

                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Performance security deposited.</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-4 px-5'>

                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="A" name="c" style={{ width: "13px", height: "13px" }} onClick={e => { updateSecDpst(true) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                    </div>

                    <div className='col-sm-4 px-4'>
                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="B" name="c" style={{ width: "13px", height: "13px" }} onClick={e => { updateSecDpst(false) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.4:</span>
                    </div>

                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>EMD released</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>

                    <div className='col-sm-4 px-5'>

                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="A" name="d" style={{ width: "13px", height: "13px" }} onClick={e => { updateEMDReleased(true) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                    </div>

                    <div className='col-sm-4 px-3'>
                      <div className="form-check" style={{ paddingLeft: "4%" }}>
                        <input className="form-check-input" type="radio" id="B" name="d" style={{ width: "13px", height: "13px" }} onClick={e => { updateEMDReleased(false) }} />
                        <label className="form-check-label theamColor" htmlFor="flexRadioDefault1">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="text-center"><button className="btn btn-success mb-5 cursor-pointer" onClick={submitAns}>Submit</button></div>
              </div>
              :
              <div className="row mt-3 theamColor bg-gray">

                <h4 className="theamColor mt-3 text-center"> Question And Answer</h4>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4'>
                  <div className='row my-2'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.1: </span>
                    </div>
                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Received letter of acceptance.</span>
                    </div>
                  </div>
                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-2 px-3'>
                      {eventname?.iS_RECEIVED_ACCEP === "Y" ? "YES" : "NO"}
                    </div>
                    <div className='col-sm-7 px-1'>
                      {eventname?.iS_RECEIVED_ACCEP === "Y" ?
                        new Date(eventname?.receiveD_ACCEP_DATE).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':') :
                        null}
                    </div>
                  </div>
                </div>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.2:</span>
                    </div>
                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Received work Order.</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-2 px-3'>
                      {eventname?.iS_RECEIVED_WORKORDER === "Y" ? "YES" : "NO"}
                    </div>
                    <div className='col-sm-7 px-1'>
                      {eventname?.iS_RECEIVED_WORKORDER === "Y" ?
                        new Date(eventname?.receiveD_WORKORDER_DATE).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':') :
                        null}

                    </div>
                  </div>
                </div>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.3:</span>
                    </div>

                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>Performance security deposited.</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>
                    <div className='col-sm-2 px-3'>
                      {eventname?.iS_SECURITY_DEPOSIT === "Y" ? "YES" : "NO"}
                    </div>
                    <div className='col-sm-7 px-1'>
                      {eventname?.iS_SECURITY_DEPOSIT === "Y" ?
                        new Date(eventname?.securitY_DEPOSIT_DATE).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':') :
                        null}
                    </div>
                  </div>

                </div>
                <div className='col-sm-1 pt-4'> </div>
                <div className='col-sm-5 pt-4 '>
                  <div className='row'>
                    <div className='col-sm-1 text-end p-0'>
                      <span className='fw-bold mx-2 f-16 '>Que.4:</span>
                    </div>

                    <div className='col-sm-11 p-0 Questions' ><span className='mx-4 f-15'>EMD released</span>
                    </div>

                  </div>

                  <div className='row f-15 my-2 px-5'>

                    <div className='col-sm-2 px-3'>
                      {eventname?.iS_EMD_RELEASED === "Y" ? "YES" : "NO"}
                    </div>

                    <div className='col-sm-7 px-1'>
                      {eventname?.iS_EMD_RELEASED === "Y" ?
                        new Date(eventname?.emD_RELEASED_DATE).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).split(':', 2).join(':')
                        : null}

                    </div>
                  </div>

                </div>
                {/* <div className="text-center"><button className="btn btn-success mb-5 cursor-pointer" onClick={submitAns}>Submit</button></div>  */}
              </div>
          }
        </div>
      </div>
      <Modal className="fade show" isOpen={isOpenDeletePopUp} >
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
          <div>   <Button
            className='btnSuccess mx-2'
            onClick={() => { deleteProductionRider() }}>
            Yes
          </Button>
            <Button
              className='btnDelete mx-2'
              onClick={handalCloseDelete}
            >
              No
            </Button></div>
        </ModalBody>
      </Modal>
      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <SuccessAlertBox href={window.location.href} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
    </div>
  );
}
export default RunningProject;