import React, { useEffect, useState } from "react";
import './empMaster.css';
import { useParams, useNavigate } from "react-router-dom";
import {
  CREATE_EMPLOYEE, UPDATE_EMPLOYEE, GET_ALL_STATES, GET_ALL_COL_NAME,
  CREATE_DOCUMENT, GET_ALL_CITEIES_BY_STATE, UPDATE_DOCUMETS
} from "../../../../utiles/AllApis";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { GET_Employee, DELETE_EMPLOYEE } from '../../../../utiles/AllApis';
import axios from "axios";
import SuccessAlertBox from "../../../../common/AlertBox/SuccessAlertBox";
import ErrorAlertBox from "../../../../common/AlertBox/ErrorAlertBox";
import { onlynumerical,onlyalphbt } from "../../../../GenericFunction/GenericFunction";
import { CabinSharp } from "@mui/icons-material";
import { Button, Modal, ModalBody } from "reactstrap";
import { VscError } from 'react-icons/vsc';
import { minWidth } from "@mui/system";
import Empsearch from "../../../../common/EmpSearch/EmpSearch";
import MonthalyData from "../monthalyData/MonthalyData";
import Form from "../EmpChanges/Form";
const MasterFrom = (props) => {
  const navigate = useNavigate();
  const { masterName } = useParams();
  let URL = "";
  if (window.location.href?.split("?").length === 1) {
    URL = window.location.href;
  }
  else {
    if (masterName === "client_master" || masterName === "cat_master") {
      URL = window.location.href?.split('?')[1] === "FormProject" ? '/projectList/form' : `/manageMaster/${masterName}`;
    }
    else {
      URL = `/manageMaster/${masterName}`;
    }
  }
  const [allColumns, setAllColumns] = useState([])
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isMarried, setIsMarried] = useState(true);
  const [contraEmp, setIsContaEmp] = useState(true);
  const [isUnMarried, setIsUnMarried] = useState(false);
  const [slyCreation, setSlyCreation] = useState(true);
  const [errEmail, setErrorEmail] = useState("");
  const [errMobile, setErrorMobile] = useState("");
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [dynamicClo, setDynamicClo] = useState({});
  const [successMsg, setsuccessMsg] = useState("");
  const [docs, submitDoc] = useState(null);
  const [empAadhar, setempAadhar] = useState("");
  const [empPan, setEmpPan] = useState("");
  const [stateList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [proID, setProId] = useState("");
  const [selecedUserID, setSelectedUserID] = useState("");
  const [isadd, setIsadd] = useState(false);
  const [isPictureOpen, setIsPictureOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeTab, setActiveTab] = useState('Personal');
  const [personal, setPersonal] = useState(true);
  const [employement, setEmployement] = useState(false);
  const [account, setAccount] = useState(false);
  const [mis, setMIS] = useState(false);
  const [QualificationDetails, setQualificationDetails] = useState(false);
  const [delisOpen, setdelIsOpen] = useState(false);
  const handalClose = () => { setdelIsOpen(false); }
  const [empDetails, setEmpDetails] = useState({
    "ColName": dynamicClo,
    "chgdate": "1990-01-01",
    "wef": "1990-01-01",
    "AsPerPf": true,
    "BiometricCode": "",
    "department": "",
    "edog": "1990-01-01",
    "edoi": "1990-01-01",
    "edojgrp": "1990-01-01",
    "edol": "1990-01-01",
    "edop": "1990-01-01",
    "firstName": "",
    "hiringDate": "1990-01-01",
    "lastName": "",
    "mail": "",
    "middleName": "",
    "phone": "",
    "bMarried": false,
    "bankac": "",
    "bankacname": "",
    "bankhname": "",
    "cadd1": "",
    "cadd2": "",
    "ccity": "",
    "cphone": "",
    "cpin": "",
    "cstate": "",
    "currRate": "",
    "currency": "",
    "dblot": false,
    "depend": "",
    "dependents": 0,
    "dtDOB": "1990-01-01",
    "ecategory": "",
    "edepartment": "",
    "egrade": "",
    "elevel": "",
    "elocation": "",
    "employer_percentage": "",
    "eshift": "",
    "eslPcnt": 0,
    "eslempPcnt": 0,
    "eslno": "",
    "ifsccode": "",
    "nameOfWeeksDay": "",
    "nationality": "",
    "nigthAllow": 0,
    "otrate": "",
    "otttype": "",
    "padd1": "",
    "padd2": "",
    "paymentMods": "",
    "pcity": "",
    "pfno": "",
    "pfprcnt": "",
    "pphone": "",
    "ppin": "",
    "pstate": "",
    "religon": "",
    "retirement": "",
    "salarytype": "",
    "strDesignation": "",
    //"strEmergContactNO": "",
    "strEmergContactName": "",
    "strInitial": "",
    "strLeaveReason": "",
    "strLocation": "",
    "strQualification": "",
    "timesheet": "",
    "userType": "",
    "emgcyTel": "",
    "contractEmp": true,
    "EmployeeID":selecedUserID
  });

  //  EMPLOYMENTS DATES 
  const [deleteid, setdeleteid] = useState("")
  const handledocs = (e, type) => {
    var name = e.target.files[0].name?.split("");
    var validate = name.reverse().join("")?.split(".")[0];
    if (type === "EMP_AADAHR" || type === "EMP_PAN") {
      if (e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "jpg" && e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "jpeg" && e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "png") {
        alert("Only Image files Allow");
        e.target.value = null;
      }
      if (type === "EMP_AADAHR") {
        setempAadhar(e.target.files[0]);
      }
      else if (type === "EMP_PAN") {
        setEmpPan(e.target.files[0]);
      }

    }
    else {
      if (e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "jpg" && e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "jpeg" && e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "png" && e.target.files[0].name?.split(".")[e.target.files[0].name?.split(".").length - 1] !== "pdf") {
        alert("Only Image and PDF files Allow");
        e.target.value = null;
      }
    }
    submitDoc(e.target.files[0]);
  };
  //for element typeklj
  var queryBCD = window.location.href?.split("=")[1];
  const [isOpen, setIsOpen] = useState(false);
  const handalOpen = () => {
    setIsOpen(true);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const handalOpen1 = () => {
    setIsOpen1(true);
  };
  useEffect(() => {
    setdeleteid(empDetails.rowpos);
    if (empDetails.bMarried) {
      setIsMarried(true);
      setIsUnMarried(false);
    }
    else {
      setIsMarried(false);
      setIsUnMarried(true);
    }
  }, [empDetails]);


  useEffect(() => {
    getAllStates();

  }, []);
  //-----------function to get all states-----------
  const getAllStates = () => {
    axios.get(GET_ALL_STATES).then((response) => {
      if (response.data.isSuccess === true) {
        setStatesList(response.data.data);
        console.log("getting all states", response.data.data)
      }
      else {
        setStatesList([]);
      }
    });
  }
  //-----------function to get all Cites of state-----------

  const getAllCities = (stateId) => {

    axios.post(GET_ALL_CITEIES_BY_STATE, { "code": stateId }).then((response) => {
      if (response.data.isSuccess === true) {
        setCityList(response.data.data);
        console.log("city", response.data.data)
      }
      else {
        setCityList([]);
      }
    });
  }
  //---------------get relatives details--------------
  const onHandleChangeNew = (e) => {
    setDynamicClo({ ...dynamicClo, [e.target.name]: e.target.value });
  }
  //--------------get all columns -----------
  const GetAllColName = () => {
    setLoading(true);
    axios.get(GET_ALL_COL_NAME).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setAllColumns(response.data.data);
      }
      else {
        setAllColumns([]);
      }

    })
  }

  const handleOpen = () => {
    setIsPictureOpen(true);
  };

  const handleClose = () => {
    setIsPictureOpen(false);
    setSelectedFile(null);
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handlePictureChange = () => {
    handleOpen();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (selectedFile) {
      // upload logic
      handleClose();
    }
  };
  const handleCancel = () => {
    handleClose();
  };

  const goToNxt = () => {
    if (errEmail !== "" ||
      empDetails.strInitial === "" ||
      empDetails.firstName === "" ||
      empDetails.lastName === "" ||
      empDetails.mail === "" ||
      empDetails.phone === "" ||
      empDetails.dtDOB === "" || empDetails.strQualification == "" 
    ) {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      // personal ? handleEmployement() : handlePersonal()
      return;
    }
    else {
      if (errEmail !== "") {
        setHeading("Submission Failed!");
        setErrMsg("Enter valid Email ID");
        handalOpen();
        return;
      }
       if (empDetails?.phone?.length < 10) {
        setHeading("Submission Failed!");
        setErrMsg("Enter valid phone Number");
        handalOpen();
        return;
      }
      else {
        GetAllColName();
        setSlyCreation(false);
      }
    }

  };

  const submitDetails = () => {
    if (new Date(empDetails.chgdate) > new Date(empDetails.wef)) {
      setHeading("Failed");
      setErrMsg("Effective date cannot greater then upto valid date.");
      handalOpen();
      return;
    }
    if(empDetails.bas)
    setLoading(false);
    axios.post(CREATE_EMPLOYEE,
      {
        "ColName": dynamicClo,
        "chgdate": empDetails.chgdate,
        "wef": empDetails.wef,
        "AsPerPf": empDetails.AsPerPf,
        "BiometricCode": empDetails.BiometricCode,
        "department": empDetails.department,
        "edog": empDetails.edog,
        "edoi": empDetails.edoi,
        "edojgrp": empDetails.edojgrp,
        "edol": empDetails.edol,
        "edop": empDetails.edop,
        "firstName": empDetails.firstName,
        "hiringDate": empDetails.hiringDate,
        "lastName": empDetails.lastName,
        "mail": empDetails.mail,
        "middleName": empDetails.middleName,
        "phone": empDetails.phone,
        "bMarried": empDetails.bMarried,
        "bankac": empDetails.bankac,
        "bankacname": empDetails.bankacname,
        "bankhname": empDetails.bankhname,
        "cadd1": empDetails.cadd1,
        "cadd2": empDetails.cadd2,
        "ccity": empDetails.ccity,
        "cphone": empDetails.cphone,
        "cpin": empDetails.cpin,
        "cstate": empDetails.cstate,
        "currRate": empDetails.currRate,
        "currency": empDetails.currency,
        "dblot": empDetails.dblot,
        "depend": empDetails.depend,
        "dependents": empDetails.dependents,
        "dtDOB": empDetails.dtDOB,
        "ecategory": empDetails.ecategory,
        "edepartment": empDetails.edepartment,
        "egrade": empDetails.egrade,
        "elevel": empDetails.elevel,
        "elocation": empDetails.elocation,
        "employer_percentage": empDetails.employer_percentage,
        "eshift": empDetails.eshift,
        "eslPcnt": empDetails.eslPcnt,
        "eslempPcnt": empDetails.eslempPcnt,
        "eslno": empDetails.eslno,
        "ifsccode": empDetails.ifsccode,
        "nameOfWeeksDay": empDetails.nameOfWeeksDay,
        "nationality": empDetails.nationality,
        "nigthAllow": empDetails.nigthAllow,
        "otrate": empDetails.otrate,
        "otttype": empDetails.otttype,
        "padd1": empDetails.padd1,
        "padd2": empDetails.padd2,
        "paymentMods": empDetails.paymentMods,
        "pcity": empDetails.pcity,
        "pfno": empDetails.pfno,
        "pfprcnt": empDetails.pfprcnt,
        "pphone": empDetails.pphone,
        "ppin": empDetails.ppin,
        "pstate": empDetails.pstate,
        "religon": empDetails.religon,
        "retirement": empDetails.retirement,
        "salarytype": empDetails.salarytype,
        "strDesignation": empDetails.strDesignation,
        //"strEmergContactNO": "",
        "strEmergContactName": empDetails.strEmergContactName,
        "strInitial": empDetails.strInitial,
        "strLeaveReason": empDetails.strLeaveReason,
        "strLocation": empDetails.strLocation,
        "strQualification": empDetails.strQualification,
        "timesheet": empDetails.timesheet,
        "userType": empDetails.userType,
        "emgcyTel": empDetails.emgcyTel,
        "contractEmp": empDetails.contractEmp
      }).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (empAadhar !== "") {
            udloadEmpDoc(response.data.data.rowpos, empAadhar, "EMP_AADHAR");
          }
          if (empPan !== "") {
            udloadEmpDoc(response.data.data.rowpos, empPan, "EMP_PAN");
          }
          setsuccessMsg("Employee Add Successfully.");
          setHeading1("Success");
          handalOpen1();
        } else {
          setHeading("Creation Failed!");
          setErrMsg(response.data.message);
          handalOpen();
          console.log('Error creating employee:', response.data.message);
        }
      });
  }
  const udloadEmpDoc = (rowpos, Document, typ) => {
    var FormData = require("form-data");
    var data = new FormData();
    let SubType = `${rowpos}_${typ}`
    data.append("File", Document);
    data.append("ID", rowpos);
    data.append("SubType", SubType);
    data.append("name", SubType);
    data.append("Type", typ);
    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const UpdateUdloadEmpDoc = (rowpos, Document, typ) => {
    var FormData = require("form-data");
    var data = new FormData();
    let SubType = `${rowpos}_${typ}`
    data.append("File", Document);
    data.append("ID", rowpos);
    data.append("SubType", SubType);
    data.append("name", SubType);
    data.append("Type", typ);
    var config = {
      method: "post",
      url: UPDATE_DOCUMETS,

      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        if (response.data.isSuccess === true) {

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const onupdateHandeling = () => {
    URL = `/manageMaster/${window.location.href?.split("=")[1]}`;
    var thisrowpos = window.location.href?.split("=")[1]
    if (document.getElementById("firstName").value === ""
    ) {
      setHeading("Submission Failed!");
      setErrMsg(`Fields Cannot empty!!`);
      handalOpen();
    }
    else {
      setLoading(true);
      let maritalStatus;
      if (document.getElementById("bMarried").value === "Married") {
        maritalStatus = true;
      }
      else {
        maritalStatus = false;
      }
      axios.post(UPDATE_EMPLOYEE,
        empDetails
      ).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          if (empAadhar !== "") {
            UpdateUdloadEmpDoc(response.data.data.rowpos, empAadhar, "EMP_AADHAR");
          }
          if (empPan !== "") {
            UpdateUdloadEmpDoc(response.data.data.rowpos, empPan, "EMP_PAN");
          }
          setsuccessMsg("Employee Details Updated Successfully.");
          setHeading1("Success");
          handalOpen1();
        }
        else {
          setHeading("Updation Failed!");
          setErrMsg(`Opps.. SomeThing went Wrong`);
          handalOpen();
        }
      })
    }
  }
  const ondeleteid = () => {
    setdeleteid();
    axios.post(DELETE_EMPLOYEE, { "ROWPOS": deleteid }).then((response) => {
      if (response.data.isSuccess == true) {
        setHeading1("Success");
        setsuccessMsg("client deleted Successfully");
        handalOpen1();
      }
      else {
        setHeading1("error");
        setsuccessMsg("error");
        handalOpen1();
      }
    })
  }
  const empChange = (e) => {
    if (e.target.id === "mail") {
      let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(emailValidate)) {
        setErrorEmail("");
      } else {
        setErrorEmail("* Please enter valid Email ID");
      }
    }
    if(e.target.id==="contractEmp")
    {
      e.target.value = e.target.checked;
    }
    if (e.target.name === "bMarried") {
      setIsMarried(true);
      setIsUnMarried(false);
      if (e.target.value === "Married") {
        e.target.value = true;
      }
      else if (e.target.value === "Unmarried") {
        setIsMarried(false);
        setIsUnMarried(true);
        e.target.value = false;
      }
    }
    if (e.target.name === "state") {
      getAllCities(e.target.value);
    }
    setEmpDetails({ ...empDetails, [e.target.name]: e.target.value });
  };
  // form page 

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case 'Personal':
        handlePersonal();
        break;
      case 'Employeement':
        handleEmployement();
        break;
      case 'Account':
        handleAccount();
        break;
      case 'MIS':
        handleMIS();
        break;
      case 'QualificationDetails':
        handleQualificationDetails();
        break;
      default:
        break;
    }
  };
  const handlePersonal = () => {
    setPersonal(true);
    setEmployement(false);
    setAccount(false);
    setMIS(false);
  };

  const handleEmployement = () => {

    setPersonal(false);
    setEmployement(true);
    setAccount(false);
    setMIS(false);
  };

  const handleAccount = () => {
    setPersonal(false);
    setEmployement(false);
    setAccount(true);
    setMIS(false);
  };

  const handleMIS = () => {
    setPersonal(false);
    setEmployement(false);
    setAccount(false);
    setMIS(true);
  };

  const handleQualificationDetails = () => {
    setPersonal(false);
    setEmployement(false);
    setAccount(false);
    setMIS(false);
    setQualificationDetails(true);
  };
  return (
    <div className="ligthBg formbg">
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <Empsearch setIsadd={setIsadd} isadd={isadd} setUserID={setSelectedUserID} setSelectEmpDetails={setEmpDetails} isView={false} />
      <div className="">
        <div className="row ">
          <div className="col-sm-3 col-md-3 col-3 my-2">
            <button
              className={`newShdBtn ${activeTab === 'Personal' ? 'active' : ''} w-100`}
              onClick={() => handleTabClick('Personal')}
            >
              Personal
            </button>
          </div>
          <div className="col-sm-3 col-md-3 col-3 my-2">
            <button
              className={`newShdBtn ${activeTab === 'Employeement' ? 'active' : ''} w-100`}
              onClick={() => handleTabClick('Employeement')}
            >
              Employeement
            </button>
          </div>

          {
            selecedUserID !== "" ?
              <>
                <div className="col-sm-3 col-md-3 col-3 my-2">
                  <button
                    className={`newShdBtn ${activeTab === 'Account' ? 'active' : ''} w-100`}
                    onClick={() => handleTabClick('Account')}
                  >
                    Account
                  </button>
                </div>
                <div className="col-sm-3 col-md-3 col-3 my-2">
                  <button
                    className={`newShdBtn ${activeTab === 'MIS' ? 'active' : ''} w-100`}
                    onClick={() => handleTabClick('MIS')}
                  >
                    MIS
                  </button>
                </div>
              </> : ""
          }


          <div>
            {
              slyCreation ?
                <>

                  {
                    personal ?
                      <>
                        <div className="empMster row px-2">
                          <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                            <p className="SecoHead">Employee Information</p>
                            <hr className='myhr text-white' />
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="strInitial"
                                className="form-label p"
                              >
                                salutation
                              </label>
                              <select
                                className="form-select mt-2"
                                aria-label="Default select example"
                                id="strInitial"
                                value={empDetails.strInitial}
                                name="strInitial"
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              >
                                <option value=""> --Select Salutation--</option>
                                <option value="MR">MR.</option>
                                <option value="MS">MS</option>
                                <option value="MRS">MRS.</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="firstName"
                                className="form-label p"
                              >
                                First Name<span className='required'>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                id="firstName"
                                name="firstName"
                                value={empDetails?.firstName}
                                placeholder="Enter Employee First Name Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="middleName"
                                className="form-label p"
                              >
                                Middle Name
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                id="middleName"
                                name="middleName"
                                value={empDetails.middleName}
                                placeholder="Enter Employee Middle Name Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="lastName"
                                className="form-label p"
                              >
                                Last Name<span className='required'>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                id="lastName"
                                name="lastName"
                                value={empDetails?.lastName}
                                placeholder="Enter Employee Last Name Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="mail"
                                className="form-label p"
                              >
                                Employee Email<span className='required' style="margin-right:30px">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control mt-2"
                                id="mail"
                                name="mail"
                                value={empDetails.mail}
                                placeholder="Enter Employee Email Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              />
                        
                              <span className="text-danger py-1 fw-bold" style="margin-left:8px">{errEmail}</span>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="religon"
                                className="form-label p"
                              >
                                Religion<span className='required'>*</span>
                              </label>
                              <select
                                className="form-select  mt-2"
                                id="religon"
                                value={empDetails.religon}
                                name="religon"
                                onChange={(e) => empChange(e)}
                              >
                                <option value=""> --Select Religion--</option>
                                <option value="BUDDHISM">BUDDHISM</option>
                                <option value="CHRISTIAN">CHRISTIAN</option>
                                <option value="HINDU">HINDU</option>
                                <option value="JUDAISM">JUDAISM</option>
                                <option value="MUSLIM">MUSLIM</option>
                                <option value="SIKH">SIKH</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="nationality"
                                className="form-label p"
                              >
                                Nationality<span className='required'>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control  mt-2"
                                id="nationality"
                                name="nationality"
                                value={empDetails.nationality}
                                placeholder="Enter Employee Nationality Here..."
                                onChange={(e) => empChange(e)}
                                onKeyPress={onlyalphbt}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="phone"
                                className="form-label p"
                              >
                                Mobile No.<span className='required'>*</span>
                              </label>
                              <input
                                type="tel"
                                className="form-control mt-2"
                                maxLength="10"
                                id="phone"
                                value={empDetails.phone}
                                name="phone"
                                placeholder="Enter Employee Mobile No. Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                                onKeyPress={onlynumerical}
                              />
                              <span className="text-danger py-1 fw-bold">{errMobile}</span>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label p"
                              >
                                Aadhaar Card
                              </label>
                              <input
                                className="form-control mt-2"
                                onChange={e => { handledocs(e, "EMP_AADAHR") }}
                                type="file" name="aadharCard" id="aadharCard" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="aadharCard"
                                className="form-label p"
                              >
                                Pan Card
                              </label>
                              <input placeholder="Upload Pan Card..."
                                className="form-control mt-2"
                                onChange={e => { handledocs(e, "EMP_PAN") }}
                                type="file" name="aadharCard" id="aadharCard" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="dtDOB"
                                className="form-label p"
                              >
                                Date Of Birth<span className='required'>*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control mt-2"
                                value={empDetails.dtDOB?.split("T")[0]}
                                id="dtDOB"
                                name="dtDOB"
                                max="9999-12-31"
                                // defaultValue={"01-01-0001"}
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="row">
                              <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="bMarried"
                                    className="form-label p" style={{ width: "38%" }}
                                  >
                                    Marital status
                                  </label>
                                  <div className="form-check mt-2" >
                                    <input className="form-check-input " type="radio" name="bMarried" id="bMarried" value="Married" onChange={(e) => {
                                      empChange(e);
                                    }} checked={isMarried} />
                                    <label className="form-check-label mx-2 text-white" htmlFor="bMarried2">
                                      Married
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input mt-2" type="radio" name="bMarried" id="bMarried2" value="Unmarried" onChange={(e) => {
                                      empChange(e);
                                    }} checked={isUnMarried} />
                                    <label className="form-check-label text-white" htmlFor="exampleRadios2">
                                      Unmarried
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="onChntract"
                                    className="form-label p"
                                  >
                                    On Contract
                                  </label>
                                  <div className="form-check mt-3 text-center">
                                    <input className="form-check-input" type="checkbox" name="contractEmp" id="contractEmp"
                                      onChange={(e) => empChange(e)} value={empDetails.contractEmp}checked={empDetails.contractEmp} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="strDesignation"
                                className="form-label p"
                              >
                                Designation<span className='required'>*</span>
                              </label>
                              <select
                                className="form-select mt-2"
                                aria-label="Default select example"
                                id="strDesignation"
                                name="strDesignation"
                                value={empDetails.strDesignation}
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              >
                                <option value=""> --Select Designation--</option>
                                <option value="Owner/Director">Owner/Director</option>
                                <option value="Additional_Director">Additional Director</option>
                                <option value="Manager">Manager</option>
                                <option value="Team">Team</option>
                                <option value="Intern">Intern</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="strQualification"
                                className="form-label p"
                              >
                                Higher Qualification<span className='required'>*</span>
                              </label>
                              <select
                                className="form-select mt-2"
                                aria-label="Default select example"
                                id="strQualification"
                                name="strQualification"
                                value={empDetails.strQualification}
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              >
                                <option value=""> --Select Qualification--</option>
                                <option value="PHD">PHD</option>
                                <option value="Masters">Masters</option>
                                <option value="Becholers">Becholers</option>
                                <option value="intermediate">intermediate</option>
                                <option value="High_school">High School</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="userType"
                                className="form-label p"
                              >
                                USER TYPE<span className='required'>*</span>
                              </label>
                              <select
                                className="form-select mt-2"
                                aria-label="Default select example"
                                id="userType"
                                value={empDetails.userType}
                                name="userType"
                                onChange={(e) => {
                                  empChange(e);
                                }}
                              >
                                <option value=""> --Select UserType--</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="OPERATIONS">OPERATION</option>
                                <option value="PRODUCTION">PRODUCTION</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                            <p className="SecoHead">Emergency Contact</p>
                            <hr className='myhr text-white' />
                          </div>

                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="strEmergContactName"
                                className="form-label p"
                              >
                                Relative / Spouse Name
                              </label>
                              <input placeholder="Enter Relative Name Here......"
                                className="form-control mt-2"
                                onChange={e => { empChange(e) }}
                                value={empDetails?.strEmergContactName}
                                type="text" name="strEmergContactName" id="strEmergContactName" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="emgcyTel"
                                className="form-label p"
                              >
                                Contact Number
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                value={empDetails?.emgcyTel}
                                maxLength="10"
                                id="emgcyTel"
                                name="emgcyTel"
                                placeholder="Enter Relative Mobile No. Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                                onKeyPress={onlynumerical}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                            <hr className='myhr text-white' />
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                              <p className="SecoHead">Parmanent Address </p>
                              <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="padd1"
                                      className="form-label p"
                                    >
                                      Line 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.padd1}
                                      id="padd1"
                                      name="padd1"
                                      placeholder="Enter Permanent Address here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="padd2"
                                      className="form-label p"
                                    >
                                      Line 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.padd2}
                                      id="padd2"
                                      name="padd2"
                                      placeholder="Enter permanent Address "
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="pstate"
                                      className="form-label p"
                                    >
                                      state
                                    </label>
                                    <select
                                      type="text"
                                      className="form-select mt-2"
                                      id="pstate"
                                      name="pstate"
                                      value={empDetails.pstate}
                                      onChange={(e) => {
                                        empChange(e);
                                      }}>

                                      <option value="dummy state">--Select State--</option>
                                      {
                                        stateList?.map((data, i) => {
                                          return (<option value={data.desc}>{data.desc}</option>)
                                        })
                                      }

                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="pcity"
                                      className="form-label p"
                                    >
                                      city
                                    </label>
                                    <select
                                      type="text"
                                      className="form-select mt-2"
                                      id="pcity"
                                      name="pcity"
                                      value={empDetails.pcity}
                                      onChange={(e) => {
                                        empChange(e);
                                      }}>
                                      <option value="dummy city">--Select City--</option>
                                      {
                                        cityList?.map((data, i) => {
                                          return (<option value={data.abvr}>{data.desc}</option>)
                                        })
                                      }

                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="ppin"
                                      className="form-label p"
                                    >
                                      Pin
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.ppin}
                                      id="ppin"
                                      name="ppin"
                                      maxLength="6"
                                      placeholder="Enter Pin No. "
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                      onKeyPress={onlynumerical}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="pphone"
                                      className="form-label p"
                                    >
                                      phone
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.pphone}
                                      id="pphone"
                                      name="pphone"
                                      maxLength="10"
                                      placeholder="Enter Mobile No. Here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                      onKeyPress={onlynumerical}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                              <p className="SecoHead">Correspondence Address</p>
                              <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cadd1"
                                      className="form-label p"
                                    >
                                      Line 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.cadd1}
                                      id="cadd1"
                                      name="cadd1"
                                      placeholder="Enter Line 1 here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cadd2"
                                      className="form-label p"
                                    >
                                      Line 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.cadd2}
                                      id="cadd2"
                                      name="cadd2"
                                      placeholder="Enter Line 2 here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cstate"
                                      className="form-label p"
                                    >
                                      state
                                    </label>
                                    <select
                                      type="text"
                                      className="form-select mt-2"
                                      id="cstate"
                                      name="cstate"
                                      value={empDetails.cstate}
                                      onChange={(e) => {
                                        empChange(e);
                                      }}>

                                      <option value="dummy2 state">--Select State--</option>
                                      {
                                        stateList?.map((data, i) => {
                                          return (<option value={data.desc}>{data.desc}</option>)
                                        })
                                      }

                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="ccity"
                                      className="form-label p"
                                    >
                                      city
                                    </label>
                                    <select
                                      type="text"
                                      className="form-select mt-2"
                                      id="ccity"
                                      name="ccity"
                                      value={empDetails.ccity}
                                      onChange={(e) => {
                                        empChange(e);
                                      }}>
                                      <option value="dummy 2 city">--Select City--</option>
                                      {
                                        cityList?.map((data, i) => {
                                          return (<option value={data.abvr}>{data.desc}</option>)
                                        })
                                      }

                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cpin"
                                      className="form-label p"
                                    >
                                      Pin
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.cpin}
                                      id="cpin"
                                      name="cpin"
                                      maxLength="6"
                                      placeholder="Enter Pin No. Here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                      onKeyPress={onlynumerical}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="cphone"
                                      className="form-label p"
                                    >
                                      phone
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control mt-2"
                                      value={empDetails.cphone}
                                      id="cphone"
                                      name="cphone"
                                      maxLength="10"
                                      placeholder="Enter Mobile No. Here..."
                                      onChange={(e) => {
                                        empChange(e);
                                      }}
                                      onKeyPress={onlynumerical}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </> : ""
                  }``
                  {
                    employement ? (
                      <div className="empMster row px-2">
                        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                          <p className="SecoHead">Dates</p>
                          <hr className='myhr text-white' />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="hiringDate"
                              className="form-label p"
                            >
                              Joined Date
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails?.hiringDate?.split("T")[0]}
                              id="hiringDate"
                              name="hiringDate"
                              max="9999-12-31"
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="edojgrp"
                              className="form-label p"
                            >
                              Contract/joining
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails.edojgrp?.split("T")[0]}
                              id="edojgrp"
                              name="edojgrp"
                              max="9999-12-31"
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="edop"
                              className="form-label p"
                            >edojgrp
                              Probation
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails.edop?.split("T")[0]}
                              id="edop"
                              name="edop"
                              max="9999-12-31"
                              // defaultValue={"01-01-0001"}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="edog"
                              className="form-label p"
                            >
                              confirmation
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails.edog?.split("T")[0]}
                              id="edog"
                              name="edog"
                              max="9999-12-31"
                              // defaultValue={"01-01-0001"}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="edol"
                              className="form-label p"
                            >
                              Contract/Leaving
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails.edol?.split("T")[0]}
                              id="edol"
                              name="edol"
                              max="9999-12-31"
                              // defaultValue={"01-01-0001"}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="strLeaveReason"
                              className="form-label p"
                            >
                              Reasion for Leaving
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="strLeaveReason"
                              name="strLeaveReason"
                              value={empDetails.strLeaveReason}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Reason--</option>
                              <option value="Owner/Director">Owner/Director</option>
                              <option value="additional_director">additional director</option>
                              <option value="Manager">Manager</option>
                              <option value="Team">Team</option>
                              <option value="Intern">Intern</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="edoi"
                              className="form-label p"
                            >
                              Next Increment
                            </label>
                            <input
                              type="date"
                              className="form-control mt-2"
                              value={empDetails.edoi?.split("T")[0]}
                              id="edoi"
                              name="edoi"
                              max="9999-12-31"
                              // defaultValue={"01-01-0001"}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                          <p className="SecoHead">Status</p>
                          <hr className='myhr text-white' />
                        </div>

                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="egrade"
                              className="form-label p"
                            >
                              Grade
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="egrade"
                              name="egrade"
                              value={empDetails.egrade}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value="">--Select Grade--</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="elevel"
                              className="form-label p"
                            >
                              Level
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="elevel"
                              name="elevel"
                              value={empDetails.elevel}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Level--</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="department"
                              className="form-label p"
                            >
                              Department
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="department"
                              name="department"
                              value={empDetails.department}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select department--</option>
                              <option value="IT">Information & Technology</option>
                              <option value="HR">HR</option>
                              <option value="Finance">Finance</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="elocation"
                              className="form-label p"
                            >
                              Location
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="elocation"
                              name="elocation"
                              value={empDetails.elocation}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Location--</option>
                              <option value="Noida">Noida</option>
                              {/* <option value="Accountant">Accountant</option>
                                <option value="TeamLead">Team Lead</option>
                                <option value="Manager">Manager</option>
                                <option value="Team">Team</option>
                                <option value="Intern">Intern</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="ecategory"
                              className="form-label p"
                            >
                              Category
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="ecategory"
                              name="ecategory"
                              value={empDetails.ecategory}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Category--</option>
                              <option value="Expat">Expat</option>
                              <option value="Staff">Staff</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="eshift"
                              className="form-label p"
                            >
                              Shift
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="eshift"
                              name="eshift"
                              value={empDetails.eshift}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Shift--</option>
                              <option value="Owner/Director">1st</option>
                              <option value="additional_director">2nd</option>
                              <option value="Day_Fix">Day Fixed</option>
                              <option value="Day_Rotate">Day Rotate</option>
                              <option value="Night_Fix">Night Fixed</option>
                              <option value="Night_Rotate">Night Rotate</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="timesheet"
                              className="form-label p"
                            >
                              Timesheet
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="timesheet"
                              name="timesheet"
                              value={empDetails.timesheet}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Timesheet--</option>
                              <option value="8_to_5">8 to 5</option>
                              <option value="9_to_6">9 to 6</option>
                              <option value="10_to_7">10 to 7</option>
                              <option value="Flexiable">Flexiable</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                          <p className="SecoHead">Statutory</p>
                          <hr className='myhr text-white' />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="pfno"
                              className="form-label p"
                            >
                              P.F. Number
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="pfno"
                              name="pfno"
                              maxLength="22"
                              value={empDetails.pfno}
                              placeholder="Enter P.F. Number Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="pfprcnt"
                              className="form-label p"
                            >
                              PF %
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="pfprcnt"
                              maxLength="3"
                              name="pfprcnt"
                              value={empDetails.pfprcnt}
                              placeholder="Enter PF % Here..."

                              onKeyPress={onlynumerical}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="AsPerPf"
                              className="form-label p"
                            >
                              As per PF
                            </label>
                            <div className="form-check mt-3 text-center">
                              <input className="form-check-input"
                                type="checkbox"
                                name="AsPerPf"
                                id="AsPerPf"
                                value={empDetails.AsPerPf}
                                onChange={(e) => empChange(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="eslempPcnt"
                              className="form-label p"
                            >
                              Employer PF%
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="eslempPcnt"
                              name="eslempPcnt"
                              value={empDetails.eslempPcnt}
                              placeholder="Enter Employer PF% Here..."
                              onKeyPress={onlynumerical}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="eslno"
                              className="form-label p"
                            >
                              ESI No.
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="eslno"
                              name="eslno"
                              maxLength="17"
                              value={empDetails.eslno}
                              placeholder="Enter  ESI No. Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}

                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="eslPcnt"
                              className="form-label p"
                            >
                              ESI %
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="eslPcnt"
                              name="eslPcnt"
                              value={empDetails.eslPcnt}
                              placeholder="Enter ESI % Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="employer_percentage"
                              className="form-label p"
                            >
                              Employer %
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="employer_percentage"
                              name="employer_percentage"
                              value={empDetails.employer_percentage}
                              placeholder="Enter Employer % Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="dependents"
                              className="form-label p"
                            >
                              Dependents
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="dependents"
                              name="dependents"
                              value={empDetails.dependents}
                              placeholder="Enter Dependents Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="depend"
                              className="form-label p"
                            >
                              Depensary
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="depend"
                              name="depend"
                              value={empDetails.depend}
                              placeholder="Enter Depensary Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="retirement"
                              className="form-label p"
                            >
                              Retirement
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="retirement"
                              name="retirement"
                              value={empDetails.retirement}
                              placeholder="Enter Retirement Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                          <p className="SecoHead">Payments</p>
                          <hr className='myhr text-white' />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="paymentMods"
                              className="form-label p"
                            >
                              Mode of Payment
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="paymentMods"
                              name="paymentMods"
                              value={empDetails.paymentMods}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Mode of payments--</option>
                              <option value="Cash">Cash</option>
                              <option value="Cheque">Online</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="bankac"
                              className="form-label p"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="bankac"
                              name="bankac"
                              value={empDetails?.bankac}
                              placeholder="Enter Account Number Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="bankacname"
                              className="form-label p"
                            >
                              Employee name as in bank
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="bankacname"
                              name="bankacname"
                              value={empDetails.bankacname}
                              placeholder="Enter Employee Name Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="ifsccode"
                              className="form-label p"
                            >
                              IFSC Code
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="ifsccode"
                              name="ifsccode"
                              value={empDetails.ifsccode}
                              placeholder="Enter IFSC Code Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="bankhname"
                              className="form-label p"
                            >
                              Bank Name Add Branch
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="bankhname"
                              name="bankhname"
                              value={empDetails.bankhname}
                              placeholder="Enter Bank Name Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                          <p className="SecoHead">Others</p>
                          <hr className='myhr text-white' />
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="otttype"
                              className="form-label p"
                            >
                              Over Time(Basic)
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="otttype"
                              name="otttype"
                              value={empDetails.otttype}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Basic--</option>
                              <option value="N/A">N/A</option>
                              <option value="Actual">Actual</option>
                              <option value="Fixed">Fixed Rate</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="RelativeName"
                              className="form-label p"
                            >
                              Week of Day
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="weekOfDay"
                              name="weekOfDay"
                              value={empDetails.weekOfDay}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Week of Day--</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="nameOfWeeksDay"
                              className="form-label p"
                            >
                              Name Of Week days
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="nameOfWeeksDay"
                              name="nameOfWeeksDay"
                              value={empDetails.nameOfWeeksDay}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Week of Day--</option>
                              <option value="Sunday">Sunday</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="otrate"
                              className="form-label p"
                            >
                              O.T. Multiplication Factor
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="otrate"
                              name="otrate"
                              value={empDetails.otrate}
                              placeholder="Enter O.T Rate Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="dblot"
                              className="form-label p"
                            >
                              Double OT Application
                            </label>
                            <div className="form-check mt-3 text-center">
                              <input className="form-check-input"
                                type="checkbox"
                                name="dblot"
                                id="dblot"
                                value={empDetails.dblot}
                                onChange={(e) => empChange(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="salarytype"
                              className="form-label p"
                            >
                              Salary Type
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="salarytype"
                              name="salarytype"
                              value={empDetails.salarytype}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Salary Type--</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Daily">Daily</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="currency"
                              className="form-label p"
                            >
                              Parmanent Currency
                            </label>
                            <select
                              className="form-select mt-2"
                              aria-label="Default select example"
                              id="currency"
                              name="currency"
                              value={empDetails.currency}
                              onChange={(e) => {
                                empChange(e);
                              }}
                            >
                              <option value=""> --Select Parmanent Currency--</option>
                              <option value="DHR">DHR</option>
                              <option value="INR">INR</option>
                              <option value="EURO">EURO</option>
                              <option value="MTN">MTN</option>
                              <option value="US$">US$</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="currRate"
                              className="form-label p"
                            >
                              Fixed Exchange Rate
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="currRate"
                              name="currRate"
                              value={empDetails.currRate}
                              placeholder="Enter Fixed Exchange Rate Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="nigthAllow"
                              className="form-label p"
                            >
                              Night Allowance
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="nigthAllow"
                              name="nigthAllow"
                              value={empDetails.nigthAllow}
                              placeholder="Enter  Night Allowance Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                              onKeyPress={onlynumerical}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-3">
                          <div className="mb-3">
                            <label
                              htmlFor="BiometricCode"
                              className="form-label p"
                            >
                              Biomatric code
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="BiometricCode"
                              name="BiometricCode"
                              value={empDetails?.BiometricCode}
                              placeholder="Enter Biomatric code Here..."
                              onChange={(e) => {
                                empChange(e);
                              }}
                            />
                          </div>
                        </div>

                      </div>

                    ) : ''
                  }
                  {
                    selecedUserID !== "" ?
                      account ? (
                        <div className='row'>
                          <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                            <p className="SecoHead">Accounts</p>
                            <hr className='myhr text-white' />
                          </div>
                          <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="RelativeName"
                                className="form-label p"
                              >
                                Salary
                              </label>
                              <input placeholder="Enter Relative Name Here......"
                                className="form-control mt-2"
                                value={empDetails?.salaryac}
                                onChange={e => { handledocs(e, "EMP_PAN") }}
                                disabled={true}
                                type="text" name="relativeName" id="RelativeName" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="ContactName"
                                className="form-label p"
                              >
                                Imprest
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                value={empDetails?.imprestac}
                                id="ContactName"
                                name="dtContactNameDOB"
                                disabled={true}
                                placeholder="Enter Relative Mobile No. Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                                onKeyPress={onlynumerical}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                            <div className="mb-3">
                              <label
                                htmlFor="ContactName"
                                className="form-label p"
                              >
                                Advance
                              </label>
                              <input
                                type="text"
                                className="form-control mt-2"
                                value={empDetails?.employeeID}
                                disabled={true}
                                id="ContactName"
                                name="dtContactNameDOB"
                                placeholder="Enter Relative Mobile No. Here..."
                                onChange={(e) => {
                                  empChange(e);
                                }}
                                onKeyPress={onlynumerical}
                              />
                            </div>
                          </div>
                        </div>
                      )
                        : mis ? (
                          <div className='d-flex justify-content-center'>
                            <div className='card mt-4 mis-card'>
                              <div className='card-body d-flex flex-row align-items-center'>
                                <div className='me-3'>
                                  <label htmlFor='fromDate' className='form-label text-white'>Date Period:</label>
                                  <input type='date' id='fromDate' className='form-control mt-2' />
                                </div>

                                <div className='me-3'>
                                  <label htmlFor='toDate' className='form-label text-white'>To:</label>
                                  <input type='date' id='toDate' className='form-control mt-2' />
                                </div>

                                <div className='form-check me-3'>
                                  <input type='radio' className='form-check-input' id='option1' name='options' value='option1' />
                                  <label htmlFor='option1' className='form-check-label text-white'>Consolidated</label>
                                </div>

                                <div className='form-check me-3'>
                                  <input type='radio' className='form-check-input' id='option2' name='options' value='option2' />
                                  <label htmlFor='option2' className='form-check-label text-white'>Consolidated Salary Type</label>
                                </div>

                                <div className='form-check me-3'>
                                  <input type='radio' className='form-check-input' id='option3' name='options' value='option3' />
                                  <label htmlFor='option3' className='form-check-label text-white'>Monthly</label>
                                </div>

                                <div className='form-check me-3'>
                                  <input type='radio' className='form-check-input' id='option4' name='options' value='option4' />
                                  <label htmlFor='option4' className='form-check-label text-white'>Monthly Salary Type</label>
                                </div>

                                <button className='newShdBtn'>Refresh</button>
                              </div>
                              <hr className='myhr' />
                            </div>
                          </div>
                        ) : '' : ""
                  }
                  <div className='d-flex justify-content-center'>
                    <div className='row mt-4 mis-card'>
                      <div className='col-12'>
                        {
                          selecedUserID !== "" ?
                            <center>
                              <button
                                className="btn btnSuccess mx-1"
                                onClick={onupdateHandeling}
                              >
                                update
                              </button>
                            </center>
                            :
                            <center>
                              <button
                                className="btn btnSuccess mx-1"
                                // onClick={ slyCreation?openSalaryCreation:onSubmitHandeling}
                                onClick={goToNxt}
                              >
                                Next
                              </button>
                            </center>
                        }
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className='emp_profile_form'>
                  <div className='row'>
                    <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                      <hr className='myhr text-white' />
                      <ArrowBackSharpIcon className="text-white" onClick={e=>{setSlyCreation(true)}}>
                        Go Back
                      </ArrowBackSharpIcon>
                    </div>
                    <p className="SecoHead">Salary Details</p>
                    <div className='col-md-3 col-sm-3 col-3 mt-3'>
                      <label
                        htmlFor="chgdate"
                        className="form-label fw-normal h6 text-capitalize"
                      >
                        Effective Form<span className='required'>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control w-75 mt-2"
                        value={empDetails?.chgdate?.split("T")[0]}
                        id="chgdate"
                        name="chgdate"
                        max="9999-12-31"
                        onChange={(e) => empChange(e)}
                      />
                    </div>
                    <div className='col-md-3 col-sm-3 col-3 mt-3'>
                      <label
                        htmlFor="wef"
                        className="form-label fw-normal h6 text-capitalize"
                      >
                        Valid Upto<span className='required'>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control w-75 mt-2"
                        value={empDetails?.wef?.split("T")[0]}
                        id="wef"
                        name="wef"
                        max="9999-12-31"
                        onChange={(e) => empChange(e)}
                      />
                    </div>
                    <div className='col-md-4 col-sm-4 col-4 mt-3'>
                      <label
                        htmlFor="remarks"
                        className="form-label fw-normal h6 text-capitalize"
                      >
                        Remarks
                      </label>
                      <textarea className="form-control w-75" placeholder="Add Remarks..." id="remarks" name="remarks" style={{ maxheight: "20%" }} value={empDetails?.remarks}
                        onChange={e => { empChange(e) }}></textarea>
                    </div>
                    <div className='row mt-3'>
                      {
                        allColumns?.filter((c) => c.type !== "D").map((data, i) => {
                          return (<div className='col-md-3 col-sm-3 col-4 my-2'>
                            <label
                              htmlFor={data?.printName}
                              className="form-label fw-normal h6 text-capitalize"
                            >
                              {data?.salaryHead}
                            </label>
                            <input
                              type="text"
                              className="form-control w-75 mt-2"
                              id={data?.printName}
                              name={data?.printName}
                              disabled={!data?.formulaBased && data?.fix ? false : data?.type === "R" || data?.type === "P" ? true : false}
                              value={Object.keys(dynamicClo).filter((key) => key === data?.printName).map((c, i) =>
                                dynamicClo[c]
                              )}
                              placeholder={`Enter ${data?.printName} of employee Here...`}
                              onChange={(e) => onHandleChangeNew(e)}
                              onKeyPress={onlynumerical}
                            />
                          </div>);
                        })
                      }
                    </div>
                  </div>
                  <div className='col-md-12 col-sm-12 col-12 mt-1 text-center'>
                    <button className='btn btnSuccess' onClick={submitDetails} ><span>Submit</span></button>
                  </div>
                </div>
            }

            <div className='card-body d-flex flex-row align-items-center'>
              <Modal className="fade show" isOpen={isPictureOpen}>
                <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
                  {/* Picture upload form */}
                  <form>
                    <div className="mb-3">
                      <label htmlFor="pictureUpload" className="form-label">Upload Picture</label>
                      <input type="file" className="form-control mt-2" id="pictureUpload" onChange={handleFileChange} />
                    </div>
                    <div>
                      <Button className='btnSuccess mx-2' onClick={handleUpload} disabled={!selectedFile}>
                        Upload
                      </Button>
                      <Button className='btnDelete mx-2' onClick={handleCancel}>
                        Cancel
                      </Button>
                    </div>
                  </form>
                </ModalBody>
              </Modal>
            </div>
            <Modal className="fade show" isOpen={delisOpen} >
              <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
                <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
                <div>   <Button
                  className='btnSuccess mx-2'
                  onClick={ondeleteid}
                >
                  Yes
                </Button>
                  <Button
                    className='btnDelete mx-2'
                    onClick={handalClose}
                  >
                    No
                  </Button></div>
              </ModalBody>
            </Modal>

          </div>
        </div>
      </div>

      <ErrorAlertBox
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        heading={heading}
        errMsg={errMsg}
      />
      <SuccessAlertBox proId={proID} isOpen1={isOpen1} href={URL} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
    </div>
  );
};
export default MasterFrom;