import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, } from '@mui/x-data-grid'
import "./datagrid.css"

import XLSX from 'sheetjs-style';
const DataGrids = (props) => {
  const handleExport = () => {
    exportToExcel(props.cardsList, props.columns);
  };

  const exportToExcel = (rows, columns) => {
    const filteredColumns = columns.filter(column => column.field !== 'vIEW');

    const headers = filteredColumns.map(column => {
      if (column.field === 'createdDate') {
        return { v: 'CREATED DATE', s: { font: { bold: true } } };
      } else if (column.field === 'createdTime') {
        return { v: 'CREATED TIME', s: { font: { bold: true } } };
      } else {
        return { v: column.headerName.toUpperCase(), s: { font: { bold: true } } };
      }
    });

    const data = [
      headers.map(header => header.v),
      ...rows.map(row =>
        filteredColumns.map(column => {
          if (column.field === 'edate' || column.field === 'dtDOB' || column.field === 'date' || column.field == 'eDate') {
            var date = "";
            if (row.edate !== undefined) {
              date = new Date(row.edate);

            }
            else if (row.eDate !== undefined) {
              date = new Date(row.eDate);

            }

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          }
          if (column.field === 'createdTime') {
            var dated = '';
            if (row.edate !== undefined) {
              dated = new Date(row.edate);

            }
            else if (row.eDate !== undefined) {
              dated = new Date(row.eDate);

            }

            let hours = dated.getHours();
            const minutes = String(dated.getMinutes()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            return `${hours}:${minutes} ${ampm}`;
          }

          if (column.field === 'creationTime') {
            var dated = '';
            if (row.edate !== undefined) {
              dated = new Date(row.edate);

            }
            else if (row.eDate !== undefined) {
              dated = new Date(row.eDate);

            }

            let hours = dated.getHours();
            const minutes = String(dated.getMinutes()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            return `${hours}:${minutes} ${ampm}`;
          }
          if (column.field === 'dtDOB') {
            const date = new Date(row[column.field]);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          }
          return row[column.field];
        })
      )
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');


    headers.forEach((header, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      worksheet[cellRef].s = header.s;
    });


    worksheet['!cols'] = headers.map(() => ({ wch: 20 }));

    XLSX.writeFile(workbook, `${props.name}_Data.xlsx`);
  };





  return (
    <>
      <div className='tablebox mt-3 text-center'>
        <button
          className='newSuccBtn px-2 my-2'
          onClick={handleExport}
        >
          Export Excel
        </button>
        <DataGrid
          className='text-center'
          rows={props?.cardsList}
          columns={props?.columns}
          pageSize={10}
          getRowId={(row) => row?.rowpos}
        />
      </div>
    </>
  )
}
export default DataGrids;