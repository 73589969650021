import React, { useEffect, useState, useRef } from 'react';
import './manageMaster.css';
import ManageMasterTable from './EmpCard';
import Dpt from './DptCard';
import ElementTypeCard from './ElementTypeCard';
import DocumentCard from './DocumentCard';
import ButtonGroup from '@mui/material/ButtonGroup';
import HeadCard from './HeadCard';
import CatCard from './CatCard';

import XLSX from 'sheetjs-style';
import ElementCard from './ElementCard';
import {
    Input
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { VscError } from 'react-icons/vsc';
import {
    Button,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { GrSearch } from "react-icons/gr";
import Pagination from './common/cardPagination';
import FloatingBtn from '../../common/Buttons/FloatingBtn';
import FloatingBtnN from '../../common/Buttons/FloatingBtnN';
import ElementFloatingBtn from '../../common/Buttons/ElementFloatingBtn';
import axios from 'axios';
import {
    GET_Employee, DELETE_EMPLOYEE,
    DELETE_DOCUMENT, GET_EMPLOYEEBYID,
    GET_HEADS, DELETE_HEADS, GET_ALL_ELEMENTS,
    GET_CLIENT, DELETE_CLIENT, GET_ELEMENT_TYPE,
    GET_VENDOR, DELETE_VENDOR, GET_ALL_CMPNY_WORK_DOC,
    GET_ALL_UMO, CREATE_PRO_CAT, GET_PRO_CAT, DELETE_PRO_CAT,
} from '../../utiles/AllApis';
import { useParams } from "react-router-dom";
import SideBar from '../../common/SideBar/SideBar';
import ErrorAlertBox from '../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../common/AlertBox/SuccessAlertBox';
import { MdFormatLineSpacing, MdOutlineStorage } from 'react-icons/md';
import DataGrids from '../../common/table/DataGrid';
const ManageMaster = () => {
    const [isTblView, setIsTblView] = useState(false);
    const { masterName } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const handalOpen1 = () => {
        setIsOpen1(true);
    };
    const [empAadharVal, setEmpAadharVal] = useState("");
    const [empPanVals, setEmpPanVal] = useState("");
    const [successMsg, setsuccessMsg] = useState("");
    const [errorMSGSET, setErrorMSGSET] = useState("");
    const [typeText, setTypeText] = useState("Please Select element first to add type");
    const navigate = useNavigate();
    const [viewEmpDoc, setViewEmpDoc] = useState(false);
    const [isDocTypeSelected, setIsDocTypeSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [isElementSelected, setIsElementSelected] = useState(false);
    const [isAllCreationBtn, setIsAllCreationBtn] = useState(false);
    const handalOpen = () => { setIsOpen(true); }
    const handalClose = () => { setIsOpen(false); }
    const [heading, setHeading] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const handleClosePopUp = () => { setIsPopUp(false); }
    const [empDoc, setEmpDoc] = useState([]);
    const [strInitial, setStrInitial] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [empEmail, setEmpEmail] = useState("");
    const [empMobile, setEmpMobile] = useState("");
    const [HiringDate, setHiringDate] = useState("");
    const [strLocation, setStrLocation] = useState("");
    const [DprtName, setDprtName] = useState("");
    const [dtDOB, setDtDOB] = useState("");
    const [bMarried, setBMarried] = useState("");
    const [strDesignation, setStrDesignation] = useState("");
    const [strQualification, setStrQualification] = useState("");
    const [isOpenError, setIsOpenError] = useState(false);
    const [heading1, setHeading1] = useState("");

    const [personal, setpersonal] = useState(false)
    const [employement, setemployement] = useState(false)
    const [account, setaccount] = useState(false)
    const [isElementSeleted, setIsElementSeleted] = useState(false);

    //const mstname=`${window.location.href.split('/')[4].split('_')[0]} ${window.location.href.split('/')[4].split('_')[1]}`
    const [searctVal, setSearctVal] = useState();
    const [deleteId, setDeleteID] = useState("");
    // const[heading, setHeading]=useState("");

    const [listName, setListName] = useState(window.location.pathname.split("/")[2]);
    const [colorCls, setColorCls] = useState("masterbtnCls");
    const [showParPage, setShowParPage] = useState(8);
    const [cardLength, setCardLength] = useState(0);
    const [cardLength1, setCardLength1] = useState(0);

    const [employeeList, setEmployeeList] = useState();
    const [Department, setDepartment] = useState([])
    const [DocumentList, setDocumentList] = useState([])
    const [headList, setHeadList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [elementList, setElementList] = useState([]);
    const [elementTypeList, setElementTypeList] = useState([]);
    const [vendorlist, setvendorlist] = useState([]);
    const [formRedirection, setFormRedirection] = useState(`/manageMaster/employee_master/form`);
    //column for grid view for emp master
    const columnsForEmp = [
        {
            field: "edate",
            headerName: "Created At",
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <p className='text-white mt-2 f-14'>{formatDate(params?.row?.edate)}</p>
                );
            }
        },
        { field: 'rowpos', headerName: 'Employee Id' },
        { field: 'firstName', headerName: 'First Name' },
        { field: 'lastName', headerName: 'Last Name' },
        { field: 'mail', headerName: 'Email ID' },
        { field: 'phone', headerName: 'Phone No.' },
        { field: 'strLocation', headerName: 'Location' },
        { field: 'strQualification', headerName: 'Qualification' },
        { field: 'strDesignation', headerName: 'Designation' },
        { field: 'department', headerName: 'Department' },
        {
            field: "dtDOB",
            headerName: "Date of Birth",
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <p className='text-white mt-2 f-14'>{formatDate(params?.row?.dtDOB)}</p>
                );
            }
        },
        {
            field: "vIEW",
            headerName: "View Document",
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.employee_docs.length > 0) {
                    return (
                        <Button variant="contained" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }} onClick={() => { ViewEmpDocs(params.row.employee_docs[0].path, params.row.employee_docs[1].path) }}>view</Button>
                    );
                }
            }
        }
    ];


    //column for grid view for Doc master
    const columnsForDoc = [
        // { field: 'edate', headerName: 'Created At' },
        { field: 'rowpos', headerName: 'Document Id', width: 180 },
        { field: 'subType', headerName: 'Document Name', width: 350 },
        { field: 'type', headerName: 'Document Type', width: 350 },
        {
            field: "VIEW",
            headerName: "View Document",
            sortable: false,
            width: 300,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                return (
                    <Button variant="contained" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }} ><a className='text-white' href={params.row.path} target="_blank">View</a></Button>
                );

            }
        }
    ]

    //column for grid view for Client master
    const columnsForClient = [
        { field: 'rowpos', headerName: 'Client Id', width: 90 },
        { field: 'clientName', headerName: 'Client Name', width: 200 },
        { field: 'address', headerName: 'Address', width: 370 },
        { field: 'contactMobile', headerName: 'Contact Number', width: 110 },
        { field: 'contactName', headerName: 'Contact Name', width: 200 },
        { field: 'gstNumber', headerName: 'GST Number', width: 150 },
        { field: 'mobile', headerName: 'Phone No.', width: 110 },
    ]
    //column for grid view for vendor master
    const columnsForVendor = [
        { field: 'rowpos', headerName: 'Vendor Id', width: 90 },
        { field: 'vendorName', headerName: 'Vendor Name', width: 200 },
        { field: 'address', headerName: 'Address', width: 370 },
        { field: 'contactMobile', headerName: 'Contact Number', width: 110 },
        { field: 'contactName', headerName: 'Contact Name', width: 200 },
        { field: 'gstNumber', headerName: 'GST Number', width: 150 },
        { field: 'mobile', headerName: 'Phone No.', width: 110 },
    ]
    //column for grid view for head master
    const columnsForHead = [
        { field: 'Id', headerName: 'Expence Head Id', width: 400 },
        { field: 'name', headerName: 'Expence Head Name', width: 400 },
        { field: 'typeID', headerName: 'Expence Head Type', width: 400 },
    ]
    //column for grid view for Category master
    const columnsForCategory = [

        { field: 'name', headerName: 'Category Name', width: 400 },
        { field: 'edate', headerName: 'Date', width: 400 },
    ]

    // Function to generate unique IDs for each item
    const generateIds = (list) => {
        return list.map(item => ({
            ...item,
            id: item.name // Generate a unique ID for each item
        }));
    };
    // Update the categoryList with unique IDs
    const updatedCategoryList = generateIds(categoryList);

    //column for grid view for Element master
    const columnsForElm = [
        // { field: 'name', headerName: 'Create At',width:370 },
        { field: 'rowpos', headerName: 'Element Id', width: 100 },
        { field: 'description', headerName: 'Element Name', width: 250 },
        {
            field: "VIEW",
            headerName: "View Element Type",
            sortable: false,
            width: 170,
            disableClickEventBubbling: true,
            renderCell: (params) => {

                return (
                    <Button variant="contained" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }} onClick={e => { getElementType(params.row.bcd) }} >View</Button>
                );

            }
        }

    ]
    const columnsForElmType = [
        // { field: 'name', headerName: 'Create At',width:370 },
        { field: 'icode', headerName: 'Id', width: 50 },
        { field: 'name', headerName: 'Element Name', width: 120 },
        { field: 'ruom', headerName: 'Unit', width: 80 },
        { field: 'description', headerName: 'Description', width: 160 },
        { field: 'itemType', headerName: 'Item Type', width: 120 },
    ]
    //View User Document
    const ViewEmpDocs = (Aadhar, pan) => {
        setViewEmpDoc(true);
        setEmpAadharVal(Aadhar);
        setEmpPanVal(pan);
    }
    const deleteMaste = () => {
        handalClose();
        if (listName === "employee_master") {
            setLoading(true);

            axios.post(DELETE_EMPLOYEE, { "ROWPOS": deleteId }).then((response) => {
                setLoading(false);

                if (response.data.isSuccess === true) {
                    loadAllEmp();
                    //    setsuccessMsg("Employee Deleted Successfully.");
                    //    setHeading1("Success");
                    //    handalOpen1()
                    window.location.reload();
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        }
        //  else if(listName === "document_master/WorkDoc"||listName==="document_master/CompanyDoc"){
        else if (listName === "document_master" || listName === "document_master/WorkDoc" || listName === "document_master/CompanyDoc" || listName === "document_master/complitionDoc") {

            setLoading(true);
            axios.post(DELETE_DOCUMENT, { "ROWPOS": deleteId }).then((response) => {

                setLoading(false);
                if (response.data.isSuccess === true) {
                    setDeleteID("");
                    // setsuccessMsg("Employee Deleted Successfully.");
                    // setHeading1("Success");
                    // handalOpen1()
                    window.location.reload();
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        }
        else if (listName === "client_master") {
            setLoading(true);

            axios.post(DELETE_CLIENT, { "ROWPOS": deleteId }).then((response) => {

                setLoading(false);

                if (response.data.isSuccess === true) {
                    setDeleteID("");
                    window.location.reload();
                    //     setsuccessMsg("Employee Deleted Successfully.");
                    //    setHeading1("Success");
                    //    handalOpen1()
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        }
        else if (listName === "vendor_master") {

            setLoading(true);
            axios.post(DELETE_VENDOR, { "ROWPOS": deleteId }).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setDeleteID("");
                    window.location.reload();
                    // setsuccessMsg("Employee Deleted Successfully.");
                    // setHeading1("Success");
                    // handalOpen1()
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        }
        else if (listName === "head_master") {
            setLoading(true);
            axios.post(DELETE_HEADS, { "ROWPOS": deleteId }).then((response) => {

                setLoading(false);
                if (response.data.isSuccess === true) {
                    setDeleteID("");
                    window.location.reload();
                    // setsuccessMsg("Employee Deleted Successfully.");
                    // setHeading1("Success");
                    // handalOpen1()
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        } else if (listName === "category_master") {
            setLoading(true);
            console.log(deleteId)
            axios.post(DELETE_PRO_CAT, { "Name": deleteId }).then((response) => {
                console.log(response)
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setDeleteID("");
                    window.location.reload();
                    // setsuccessMsg("Employee Deleted Successfully.");
                    // setHeading1("Success");
                    // handalOpen1()
                }
                else {
                    setHeading("Error")
                    setErrMsg(`Opps..Fail to delete`);
                    setIsOpenError(true);
                }
            });
        }
    }





    const onDelete = (id) => {
        setDeleteID(id);
        handalOpen();
    }
    const setShowDetails = (id) => {
        setLoading(true);
        axios.post(GET_EMPLOYEEBYID, { "ROWPOS": id }).then((response) => {
            setLoading(false);
            if (response.data.isSuccess === true) {
                setEmpDoc(response.data.data[0].employee_docs);
                setStrInitial(response.data.data[0].strInitial);
                setFirstName(response.data.data[0].firstName);
                setLastName(response.data.data[0].lastName);
                setEmpEmail(response.data.data[0].mail);
                setEmpMobile(response.data.data[0].phone);
                setDprtName(response.data.data[0].department);
                setHiringDate(response.data.data[0].hiringDate.slice(0, 10));
                setStrLocation(response.data.data[0].strLocation);
                setDtDOB(response.data.data[0].dtDOB.slice(0, 10));
                setBMarried(response.data.data[0].bMarried);
                setStrDesignation(response.data.data[0].strDesignation);
                setStrQualification(response.data.data[0].strQualification);
            }
            else {
                setHeading("Error!");
                setErrMsg(`Opps.. SomeThing went Wrong`);
                handalOpen();
            }
        })
    }

    const loadAllEmp = () => {
        setLoading(true);
        axios.get(GET_Employee).then((response) => {
            setLoading(false);
            if (response.data.isSuccess === true) {

                setCardLength(response.data.data.length);
                setEmployeeList(response.data.data);
                const res = response.data.data.filter((data => {
                    if (!searctVal) {
                        return data;
                    }
                    else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                        return data;
                    }
                })).slice(pagination.start, pagination.end).map((cardsList, i) => {
                    return (

                        <div className='col-md-3 col-12 col-sm-6 my-2' key={i}>
                            <ManageMasterTable setShowDetails={setShowDetails} onDelete={onDelete} setIsPopUp={setIsPopUp} id={cardsList.employeeID} name={cardsList.employeeName} dept={cardsList.department} phone={cardsList.phone} deptId={cardsList.departmentID} hiringDate={cardsList.hiringDate} mail={cardsList.mail}
                                managerID={cardsList.managerID} rowpos={cardsList.rowpos} formRedirection={formRedirection} firstName={cardsList.firstName} lastName={cardsList.lastName} />
                        </div>
                    )
                })
                setEmployeeList(res);
            }
            else {
                setHeading("Error!");
                setErrMsg(`Opps.. SomeThing went Wrong`);
                handalOpen();
            }
        });
    }
    const loadAllHeads = () => {
        setLoading(true);
        axios.get(GET_HEADS).then((response) => {
            setLoading(false);
            if (response.data.isSuccess === true) {
                setCardLength(response.data.data.length);
                setHeadList(response.data.data);
                const res = response.data.data.filter((data => {
                    if (!searctVal) {
                        return data;
                    }
                    else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                        return data;
                    }
                })).slice(pagination.start, pagination.end).map((cardsList, i) => {
                    return (

                        <div className='col-md-3 col-12 col-sm-6 my-2' key={i}>
                            <ManageMasterTable firstName={cardsList.firstName} lastName={cardsList.lastName} setShowDetails={setShowDetails} onDelete={onDelete} setIsPopUp={setIsPopUp} id={cardsList.employeeID} name={cardsList.employeeName} dept={cardsList.department} deptId={cardsList.departmentID} hiringDate={cardsList.hiringDate} mail={cardsList.mail} managerID={cardsList.managerID} rowpos={cardsList.rowpos} formRedirection={formRedirection} phone={cardsList.phone} />
                        </div>

                    )
                })
                setHeadList(res);
            }
            else {
                setHeading("Error!");
                setErrMsg(`Opps.. SomeThing went Wrong`);
                handalOpen();
            }
        });
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleExport = () => {
        const data = employeeList.map(card => ({
            'EMPLOYEE ID': card.rowpos,
            'FIRST NAME': card.firstName,
            'LAST NAME': card.lastName,
            'DEPARTMENT': card.department,
            'MOBILE NUMBER': card.phone,
            'EMAIL': card.mail,
            'HIRING DATE': formatDate(card.hiringDate),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);

        // Make headers bold
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = range.s.c; col <= range.e.c; col++) {
            const address = XLSX.utils.encode_col(col) + 1; // Row 1 is the header row
            const cell_ref = XLSX.utils.encode_cell({ c: col, r: 0 });
            if (!worksheet[cell_ref]) continue;

            worksheet[cell_ref].s = {
                font: {
                    bold: true
                }
            };
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employee Data');
        XLSX.writeFile(workbook, 'Employee_Data.xlsx');
    };

    useEffect(() => {
        setFormRedirection(`/manageMaster/${listName}/form`);
        if (listName === "employee_master") {
            setIsAllCreationBtn(true);
            setLoading(true);
            axios.get(GET_Employee).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setCardLength(response.data.data.length);
                    setEmployeeList(response.data.data);
                }
                else {
                    setHeading("Error!");
                    setErrMsg(`Opps.. SomeThing went Wrong`);
                    handalOpen();
                }
            })
        }
        else if (listName === "client_master") {
            setLoading(true);
            setIsAllCreationBtn(true);
            axios.get(GET_CLIENT).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setCardLength(response.data.data.length);
                    setDepartment(response.data.data);
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (listName === "document_master") {

            if (window.location.href.split("document_master").length == 1) {
                navigate("/manageMaster/document_master");
            }
            // setIsDocTypeSelected(false);
            else {
                isDocTypeSelected ? setIsDocTypeSelected(true) : setIsDocTypeSelected(false);

                if (window.location.href.split("document_master/")[1] === "WorkDoc" || window.location.href.split("document_master/")[1] === "CompanyDoc") {
                    setIsDocTypeSelected(true);
                    setIsAllCreationBtn(true);
                    navigate(`/manageMaster/${listName}/${window.location.href.split("document_master/")[1]}`);
                    setLoading(true);
                    var type = window.location.href.split("document_master/")[1];
                    axios.post(GET_ALL_CMPNY_WORK_DOC, { "Type": type }).then((response) => {
                        setLoading(false);
                        if (response.data.isSuccess === true) {
                            var comDoc = [], workDoc = [];

                            response.data.data.map((data, i) => {

                                if (data.type === "COMPANY_DOCUMENT") {
                                    comDoc.push(data);
                                }
                                else {
                                    workDoc.push(data);
                                }
                            })
                            if (window.location.href.split("document_master/")[1] === "WorkDoc") {
                                setCardLength(workDoc.length);
                                setDocumentList(workDoc);
                            }
                            else if (window.location.href.split("document_master/")[1] === "CompanyDoc") {
                                setCardLength(comDoc.length);
                                setDocumentList(comDoc);
                            }
                        }
                        else {
                            // setHeading("Error!");
                            // setErrMsg(`Opps.. SomeThing went Wrong`);
                            // handalOpen();
                            setCardLength(0);
                            setErrorMSGSET(response.data.message);
                        }
                    })
                }
            }


        }
        else if (listName === "vendor_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/vendor_master");
            setLoading(true);
            axios.get(GET_VENDOR).then((response) => {

                setLoading(false);
                if (response.data.isSuccess === true) {
                    setCardLength(response.data.data.length);
                    setvendorlist(response.data.data)
                }
                else {
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (listName === "head_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/head_master");
            setLoading(true);
            axios.post(GET_HEADS).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setCardLength(response.data.data.length);
                    setHeadList(response.data.data);
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (listName === "category_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/head_master");
            setLoading(true);
            axios.get(GET_PRO_CAT).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    setCardLength(response.data.data.length);
                    // setCategoryList(response.data.data);
                    setCategoryList(generateIds(response.data.data));
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })


        }
        else if (listName === "element_master") {
            setIsElementSeleted(false);
            var lis = "type_master";
            setFormRedirection(`/manageMaster/${lis}/form`);
            setIsAllCreationBtn(false);
            navigate("/manageMaster/element_master");
            setLoading(true);
            axios.get(GET_ALL_ELEMENTS).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setElementList(response.data.data);
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }

    }, [])
    const [pagination, setPagination] = useState(
        {
            start: 0,
            end: showParPage
        }
    );
    const [pagination1, setPagination1] = useState(
        {
            start: 0,
            end: showParPage
        }
    );
    const paginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    }
    const paginationChange1 = (start, end) => {
        setPagination1({ start: start, end: end });
    }
    const masterList = [
        {
            "MenuName": "Employee Master",
            // "activeCls": "afterclicktopbuttons",
            "href": `employee_master`
        },
        {
            "MenuName": "Document Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "document_master"
        },
        {
            "MenuName": "Client Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "client_master"
        },
        {
            "MenuName": "Vendor Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "vendor_master"
        },
        {
            "MenuName": "Heads Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "head_master"
        },
        {
            "MenuName": "Project Category Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "category_master"
        },
        {
            "MenuName": "Element Master",
            // "img":  <MdOutlineDashboardCustomize/>,
            "href": "element_master"
        },

    ]
    const getMasters = (e) => {
        //for Form Redirection Page
        setFormRedirection(`/manageMaster/${e}/form`);
        setListName(e);

        if (e === "employee_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/employee_master");
            setLoading(true);
            axios.get(GET_Employee).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setEmployeeList(response.data.data)
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }

            })
        }
        else if (e === "document_master") {
            navigate("/manageMaster/document_master");
            setIsDocTypeSelected(false);

        }
        else if (e === "document_master/WorkDoc" || e === "document_master/CompanyDoc" || e === "document_master/complitionDoc") {
            setIsDocTypeSelected(true);
            setIsAllCreationBtn(true);
            navigate(`/manageMaster/${e}`);
            setLoading(true);
            var type = e.split("/")[1];
            axios.post(GET_ALL_CMPNY_WORK_DOC, { "Type": type }).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    var comDoc = [], workDoc = [], compCerfct = [];

                    response.data.data.map((data, i) => {

                        if (data.type === "COMPANY_DOCUMENT") {
                            comDoc.push(data);
                        }
                        else if (data.type === "WORKORDER_DOCUMENT") {
                            workDoc.push(data);
                        }
                        else {
                            compCerfct.push(data);
                        }
                    })
                    if (e.split("/")[1] === "WorkDoc") {
                        setCardLength(workDoc.length);
                        setDocumentList(workDoc);
                    }
                    else if (e.split("/")[1] === "CompanyDoc") {
                        setCardLength(comDoc.length);
                        setDocumentList(comDoc);
                    }
                    else {
                        setCardLength(compCerfct.length);
                        setDocumentList(compCerfct);
                    }
                }
                else {
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (e === "document_master/WorkDoc" || e === "document_master/CompanyDoc" || e === "document_master/complitionDoc") {
            setIsDocTypeSelected(true);
            setIsAllCreationBtn(true);
            navigate(`/manageMaster/${e}`);
            setLoading(true);
            var type = e.split("/")[1];

            axios.post(GET_ALL_CMPNY_WORK_DOC, { "Type": type }).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {
                    var comDoc = [], workDoc = [];

                    response.data.data.map((data, i) => {

                        if (data.type === "COMPANY_DOCUMENT") {
                            comDoc.push(data);
                        }
                        else {
                            workDoc.push(data);
                        }
                    })
                    if (e.split("/")[1] === "WorkDoc") {
                        setCardLength(workDoc.length);
                        setDocumentList(workDoc);
                    }
                    else if (e.split("/")[1] === "CompanyDoc") {
                        setCardLength(comDoc.length);
                        setDocumentList(comDoc);
                    }
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (e === "client_master") {
            navigate("/manageMaster/client_master");
            setLoading(true);
            axios.get(GET_CLIENT).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setDepartment(response.data.data)
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (e === "vendor_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/vendor_master");
            setLoading(true);
            axios.get(GET_VENDOR).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setvendorlist(response.data.data)
                }
                else {
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                    //   setHeading("Error!");
                    //     setErrMsg(`Opps.. SomeThing went Wrong`);  
                    //     handalOpen();
                }
            })
        }
        else if (e === "head_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/head_master");
            setLoading(true);
            axios.post(GET_HEADS).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setHeadList(response.data.data);
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (e === "category_master") {
            setIsAllCreationBtn(true);
            navigate("/manageMaster/category_master");
            setLoading(true);
            axios.get(GET_PRO_CAT).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setCategoryList(response.data.data);
                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
        else if (e === "element_master") {
            setIsAllCreationBtn(false);
            navigate("/manageMaster/element_master");
            setLoading(true);
            axios.get(GET_ALL_ELEMENTS).then((response) => {
                setLoading(false);
                if (response.data.isSuccess === true) {

                    setCardLength(response.data.data.length);
                    setElementList(response.data.data);

                }
                else {
                    // setHeading("Error!");
                    // setErrMsg(`Opps.. SomeThing went Wrong`);
                    // handalOpen();
                    setCardLength(0);
                    setErrorMSGSET(response.data.message);
                }
            })
        }
    }
    const ListName = (listVal) => {
    }

    const searchFilterData = (e) => {
        setSearctVal(e.target.value);
    }



    // personal button 
    const handlepersonal = () => {
        setpersonal(true)
        setemployement(false)
        setaccount(false)
    }
    const handleemployement = () => {
        setpersonal(false)
        setemployement(true)
        setaccount(false)
    }
    const handleaccount = () => {
        setpersonal(false)
        setemployement(false)
        setaccount(true)
    }
    //get element type
    const [bcd, setBCD] = useState();

    const getElementType = (bcd) => {
        setBCD(bcd);
        setIsElementSelected(true);
        setIsAllCreationBtn(false);
        document.getElementById("element_master").focus();
        setLoading(true);

        setIsElementSeleted(true);

        axios.post(GET_ELEMENT_TYPE, {
            "BCD": bcd
        }).then((response) => {
            setLoading(false);
            if (response.data.isSuccess === true) {
                setCardLength1(response.data.data.length);
                setElementTypeList(response.data.data);
            }
            else {
                setIsElementSelected(false);
                setTypeText(response.data.message);
            }
        })

    }
    return (
        <div className='container-fluid'>
            {
                isLoading ? <div className='loaderDiv'>

                </div> : null
            }
            <div className='row '>
                <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
                    <SideBar />
                </div>
                <div className='col-md-10 col-12 col-sm-12 pt-3 pb-1 myview'>
                    <div className='row'>
                        {masterList.map((data, i) => {
                            return (<div key={i} className={`col-md-2 col-sm-2 col-6 text-center newShdBtn ${listName === data.href || listName === `${data.href}/WorkDoc` || listName === `${data.href}/CompanyDoc` ? 'activeCls' : ''}`}><button className="btn text-white" onClick={e => { getMasters(data.href) }} id={data.href}>{data.MenuName} </button></div>)

                        })}
                        <div className='col-md-9 col-9 col-sm-9 text-white'>
                            {

                                listName === "document_master" || listName === "document_master/WorkDoc" || listName === "document_master/CompanyDoc" || listName === "document_master/complitionDoc" ?
                                    <div className='row mt-3'>
                                        <div className={`col-md-3 col-sm-3 col-6 text-center mx-auto newShdBtn ${listName === "document_master/CompanyDoc" || window.location.href.split("document_master/")[1] === "CompanyDoc" ? 'activeCls' : ''}`}  ><button className="btn text-white" onClick={e => { getMasters("document_master/CompanyDoc") }} id="Com_Doc">Company Doc</button></div>
                                        <div className={`col-md-3 col-sm-3 col-6 text-center mx-auto newShdBtn ${listName === "document_master/WorkDoc" || window.location.href.split("document_master/")[1] === "WorkDoc" ? 'activeCls' : ''}`}  ><button className="btn text-white" onClick={e => { getMasters("document_master/WorkDoc") }} id="WO_Doc">Work Order Doc</button></div>
                                        <div className={`col-md-3 col-sm-3 col-6 text-center mx-auto newShdBtn ${listName === "document_master/complitionDoc" || window.location.href.split("document_master/")[1] === "complitionDoc" ? 'activeCls' : ''}`}  ><button className="btn text-white" onClick={e => { getMasters("document_master/complitionDoc") }} id="COMP_Doc">Completions Certificate</button></div>
                                    </div> : null}

                            {
                                isTblView ?
                                    <MdFormatLineSpacing onClick={e => { setIsTblView(false) }} className="h4 mt-2" />
                                    :
                                    <MdOutlineStorage onClick={e => { setIsTblView(true) }} className="h4 mt-2" />
                            }
                        </div>
                        {/* <div className='col-md-1 col-1 col-sm-1 text-white'>
            </div> */}
                        <div className='col-md-3 col-4 col-sm-4 mt-2 inBacSide searchMaster'>
                            {
                                isTblView ? null :
                                    <><GrSearch className='searchIcon' />
                                        <Input
                                            id="seaarch"
                                            name="search"
                                            placeholder="search here...."
                                            type="text"
                                            className='tracking-in-expand-fwd-bottom'
                                            onChange={searchFilterData}
                                        /></>


                            }
                        </div>
                        {
                            listName === "employee_master" ?
                                isTblView ?
                                    <>

                                        <DataGrids name={"Employee_Master"} cardsList={employeeList} id="employee_Master" columns={columnsForEmp} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                        </div></> :
                                    <div className="row">

                                        <div> <button className='newSuccBtn px-2 my-2' style={{ width: "150px", height: "30px", padding: "2px 5px" }} onClick={handleExport}>
                                            Export Excel
                                        </button>
                                        </div>
                                        {
                                            employeeList?.length > 0 ?
                                                employeeList?.filter((data => {
                                                    if (!searctVal) {
                                                        return data;
                                                    }
                                                    else if (data?.firstName?.toLowerCase().includes(searctVal.toLowerCase()) || data?.lastName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                        return data;
                                                    }
                                                }))

                                                    ?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                        return (

                                                            <div className='col-md-3 col-12 col-sm-6 my-2' key={i}>
                                                                <ManageMasterTable setShowDetails={setShowDetails} onDelete={onDelete} setIsPopUp={setIsPopUp} id={cardsList.employeeID} name={cardsList.employeeName} dept={cardsList.department} deptId={cardsList.departmentID} hiringDate={cardsList.hiringDate} mail={cardsList.mail} managerID={cardsList.managerID} rowpos={cardsList.rowpos} formRedirection={formRedirection}
                                                                    firstName={cardsList.firstName} lastName={cardsList.lastName} phone={cardsList.phone}
                                                                />
                                                            </div>
                                                        )
                                                    }) : <p className='my-5'>Opps..No Record Found!!</p>
                                        }
                                        <><div className='mt-2 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                        </div>
                                            <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                            </div> </>
                                    </div> : null
                        }
                        {

                            listName === "client_master" ?
                                isTblView ?
                                    <>  <DataGrids name={"Client_Master"} cardsList={Department} columns={columnsForClient} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                    </div></> :
                                    <div className="row">{
                                        Department?.length > 0 ?
                                            Department?.filter((data => {
                                                if (!searctVal) {
                                                    return data;
                                                }
                                                else if (data?.clientName?.toLowerCase().includes(searctVal.toLowerCase())) {

                                                    return data;
                                                }
                                            }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                return (
                                                    <div className='col-md-3 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                        <Dpt className="tracking-in-expand-fwd-bottom" id={cardsList.id} name={cardsList.clientName} gst={cardsList.gstNumber} mobile={cardsList.mobile} control={cardsList.control} contactName={cardsList.contactName} contactMobile={cardsList.contactMobile} address={cardsList.address} formRedirection={formRedirection} onDelete={onDelete} rowpos={cardsList.rowpos} email={cardsList.email} />
                                                    </div>
                                                )
                                            }) : <p className='my-5'>Opps..No Record Found!!</p>
                                    }    <><div className='my-3 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                    </div>
                                            <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                            </div> </>
                                    </div> : null
                        }
                        {

                            listName === "vendor_master" ?
                                isTblView ?
                                    <>  <DataGrids name={"Vendor_Master"} cardsList={vendorlist} columns={columnsForVendor} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                    </div></> :
                                    <div className="row">{
                                        vendorlist?.length > 0 ?
                                            vendorlist?.filter((data => {

                                                if (!searctVal) {

                                                    return data;

                                                }
                                                else if (data?.vendorName?.toLowerCase().includes(searctVal.toLowerCase())) {

                                                    return data;
                                                }
                                            }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                return (
                                                    <div className='col-md-3 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                        <Dpt className="tracking-in-expand-fwd-bottom" id={cardsList.id} name={cardsList.vendorName} gst={cardsList.gstNumber} mobile={cardsList.mobile} control={cardsList.control} contactName={cardsList.contactName} contactMobile={cardsList.contactMobile} address={cardsList.address} formRedirection={formRedirection} onDelete={onDelete} rowpos={cardsList.rowpos} email={cardsList.email} />
                                                    </div>
                                                )
                                            }) : <p className='my-5'>Opps..No Record Found!!</p>
                                    }    <><div className='my-3 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                    </div>
                                            <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                            </div> </>
                                    </div> : null
                        }

                        {
                            listName === "document_master" || listName === "document_master/WorkDoc" || listName === "document_master/CompanyDoc" || listName === "document_master/complitionDoc" ?
                                <>
                                    {
                                        isDocTypeSelected ?
                                            isTblView ?
                                                <>  <DataGrids name={"Document_Master"} cardsList={DocumentList} columns={columnsForDoc} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                                </div></> :
                                                <div className="row DocDiv" >
                                                    {DocumentList?.length > 0 ?
                                                        DocumentList?.filter((data => {
                                                            if (!searctVal) {
                                                                return data;
                                                            }
                                                            else if (data?.documentName?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                return data;
                                                            }
                                                        }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                            return (
                                                                <div className='col-md-3 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                                    <DocumentCard className="tracking-in-expand-fwd-bottom" val={cardsList.val} id={cardsList.id} name={cardsList.documentName} dept={cardsList.dept} deptId={cardsList.typeID} hiringDate={cardsList.hiringDate} mail={cardsList.mail} managerID={cardsList.managerID} location={cardsList.location} formRedirection={formRedirection} onDelete={onDelete} path={cardsList.path} rowpos={cardsList.rowpos} />
                                                                </div>
                                                            )
                                                        }) : <p className='my-5'>Opps..No Record Found!!</p>
                                                    }
                                                    <><div className='mt-1 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                                    </div>
                                                        <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={'/manageMaster/document_master/form'} QuerPara={bcd} usehtmlFor={listName} />
                                                        </div> </>
                                                </div> : <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={'/manageMaster/document_master/form'} QuerPara={bcd} usehtmlFor={listName} />
                                            </div>
                                    }
                                </>

                                : null
                        }
                        {
                            listName === "head_master" ?
                                isTblView ?
                                    <>  <DataGrids name={"Head_Master"} cardsList={headList} columns={columnsForHead} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                    </div></> :
                                    <div className="row">
                                        {
                                            headList?.length > 0 ?
                                                headList?.filter((data => {
                                                    if (!searctVal) {
                                                        return data;
                                                    }
                                                    else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                        return data;
                                                    }
                                                }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                    return (
                                                        <div className='col-md-3 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                            <HeadCard className="tracking-in-expand-fwd-bottom" id={cardsList.id} name={cardsList.name} dept={cardsList.dept} deptId={cardsList.deptId} hiringDate={cardsList.hiringDate} mail={cardsList.mail} managerID={cardsList.managerID} location={cardsList.location} formRedirection={formRedirection} onDelete={onDelete} rowpos={cardsList.rowpos} />
                                                        </div>
                                                    )
                                                }) : <p className='my-5'>Opps..No Record Found!!</p>
                                        }
                                        <><div className='my-3 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                        </div>
                                            <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                            </div> </>
                                    </div> : null
                        }
                        {
                            listName === "category_master" ?
                                isTblView ?
                                    <>  <DataGrids name={"Category_Master"} cardsList={categoryList} columns={columnsForCategory} /><div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                    </div></> :
                                    <div className="row">
                                        {
                                            categoryList?.length > 0 ?
                                                categoryList?.filter((data => {
                                                    if (!searctVal) {
                                                        return data;
                                                    }
                                                    else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                        return data;
                                                    }
                                                }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                    console.log(cardsList)
                                                    return (
                                                        <div className='col-md-3 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                            <CatCard className="tracking-in-expand-fwd-bottom" key={i} name={cardsList.name} onDelete={() => onDelete(cardsList.name)} edate={cardsList.edate} />
                                                        </div>
                                                    )
                                                }) : <p className='my-5'>Opps..No Record Found!!</p>
                                        }
                                        <><div className='my-3 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                        </div>
                                            <div className='inBacSide'><FloatingBtn className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor={listName} />
                                            </div> </>
                                    </div> : null
                        }

                        {
                            listName === "element_master" ?

                                <div className="row">
                                    {
                                        isTblView ?
                                            <div className='col-md-6 col-6 col-sm-6 my-2'>
                                                <h4 className='text-center fw-bold text-white'>ELEMENTS</h4>  <DataGrids name={"Elements"} cardsList={elementList} columns={columnsForElm} /><div className='inBacSide'>
                                                </div><div className='inBacSide'><ElementFloatingBtn className="elementFloatingBtn" href={formRedirection} usehtmlFor={listName} />
                                                </div>
                                            </div> :
                                            <div className='col-md-6 col-6 col-sm-6 my-2'>
                                                <h4 style={{ color: "#af262a" }} className='text-center fw-bold'>ELEMENTS</h4>
                                                <div className="row">
                                                    {elementList?.length > 0 ?
                                                        elementList?.filter((data => {
                                                            if (!searctVal) {
                                                                return data;
                                                            }
                                                            else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                return data;
                                                            }
                                                        }))?.slice(pagination.start, pagination.end)?.map((cardsList, i) => {
                                                            return (
                                                                <div className='col-md-4 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                                    <ElementCard className="tracking-in-expand-fwd-bottom" id={cardsList.bcd} name={cardsList.name} desc={cardsList.Description} getElementType={getElementType} />
                                                                </div>
                                                            )
                                                        }) : <p className='my-5'>Opps..No Record Found!!</p>
                                                    }
                                                </div>
                                                <>
                                                    <div className='my-3 inBacSide'> <Pagination cardLength={cardLength} showParPage={showParPage} paginationChange={paginationChange} />
                                                    </div>
                                                    <div className='inBacSide'><ElementFloatingBtn className="elementFloatingBtn" href={formRedirection} usehtmlFor={listName} />
                                                    </div>
                                                </>
                                            </div>
                                    }
                                    {isTblView ?
                                        <div className='col-md-6 col-6 col-sm-6 my-2'>
                                            <h4 className='text-center fw-bold text-white'>TYPES</h4>
                                            {isElementSelected ? <>
                                                <DataGrids name={"Types"} cardsList={elementTypeList} columns={columnsForElmType} />
                                                <div className='inBacSide'><FloatingBtnN className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor="Element_Type" />
                                                </div>
                                            </> : <p style={{ color: "#af262a" }}>{typeText}</p>}
                                        </div> :
                                        <div className='col-md-6 col-6 col-sm-6 my-2'>
                                            <h4 style={{ color: "#af262a" }} className='text-center fw-bold'>TYPES</h4>
                                            {
                                                isElementSelected ?
                                                    <>
                                                        <div className="row">
                                                            {
                                                                elementTypeList?.filter((data => {
                                                                    if (!searctVal) {
                                                                        return data;
                                                                    }
                                                                    else if (data?.name?.toLowerCase().includes(searctVal.toLowerCase())) {
                                                                        return data;
                                                                    }
                                                                }))?.slice(pagination1.start, pagination1.end)?.map((cardsList, i) => {
                                                                    return (
                                                                        <div className='col-md-4 col-12 col-sm-6 my-2 inBacSide' key={i}>
                                                                            <ElementTypeCard className="tracking-in-expand-fwd-bottom" id={cardsList.bcd} name={cardsList.name} desc={cardsList.Description} getElementType={getElementType} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            {
                                                                isElementSeleted ?
                                                                    <>
                                                                        <div className='my-3 inBacSide'> <Pagination cardLength={cardLength1} showParPage={showParPage} paginationChange={paginationChange1} />
                                                                        </div>

                                                                    </> : null

                                                            }
                                                        </div></> :
                                                    <p>{typeText}</p>
                                            }
                                            {
                                                isElementSeleted ?
                                                    <div className='inBacSide'><FloatingBtnN className="tracking-in-expand-fwd-top" href={formRedirection} QuerPara={bcd} usehtmlFor="Element_Type" />
                                                    </div> : null}
                                        </div>}
                                </div>
                                :
                                null
                        }
                    </div>

                </div>
            </div>
            <Modal className="fade show" isOpen={isOpen} >
                <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
                    <p className=' text-center text-danger fw-bold'><VscError className='fw-bolder mx-2 my-2 h2' />Are you sure you want to Delete</p>
                    <div>   <Button
                        className='btnSuccess mx-2'
                        onClick={() => { deleteMaste() }}
                    >
                        Yes
                    </Button>
                        <Button
                            className='btnDelete mx-2'
                            onClick={handalClose}
                        >
                            No
                        </Button></div>
                </ModalBody>

            </Modal>
            {/*employees details */}
            <Modal className=" fade show tracking-in-expand-fwd-top" isOpen={isPopUp} >
                <div className='row'>
                    <div className='col-sm-11 col-md-11 col-11'>

                        <ButtonGroup variant="contained" aria-label="outlined button group">
                            <Button style={{ width: "150px" }} onClick={handlepersonal}>Personal</Button>
                            <Button style={{ width: "140px" }} onClick={handleemployement}>Employeement</Button>
                            <Button style={{ width: "140px" }} onClick={handleaccount}>Account</Button>
                        </ButtonGroup>


                        <p className='h5 text-secondary text-center my-2 fw-700'> Employee Personal Details</p>
                    </div>
                    <div className='col-sm-1 col-md-1 col-1'>
                        <p className="float-end cursor-pointer py-2 px-2" onClick={(e) => handleClosePopUp()}>
                            ❌
                        </p>

                    </div>
                    {
                        personal ?

                            <>
                                <div className='col-md-12 col-sm-12 col-12 px-4'>
                                    <div className="float-start py-2"><span className="">Name: </span><span>{strInitial} {firstName} {lastName}</span></div>
                                    <div className="float-start py-2"><span className="">Email: </span><span>{empEmail}</span></div>
                                    <div className="float-start py-2"><span className="">Mobile No.: </span><span>{empMobile}</span></div>
                                    <div className="float-start py-2"><span className="">Hiring Date: </span><span>{HiringDate}</span></div>
                                    <div className="float-start py-2"><span className="">Location: </span><span>{strLocation === "" ? "------" : strLocation}</span></div>
                                    <div className="float-start py-2"><span className="">DOB: </span><span>{dtDOB}</span></div>
                                    <div className="float-start py-2"><span className="">Department: </span><span>{DprtName}</span></div>
                                    <div className="float-start py-2"><span className="">Married: </span><span>{bMarried ? `Married` : `Unmarried`}</span></div>
                                    <div className="float-start py-2"><span className="">Designation: </span><span>{strDesignation}</span></div>
                                    <div className="float-start py-2"><span className="">Qualification: </span><span>{strQualification === "" ? "-----" : strQualification}</span></div>
                                    {empDoc.length !== 0 ?
                                        empDoc.map((data, i) =>
                                            <div className="float-start py-2" key={i}><span className="fw-bold py-2">{data.media_Type}: </span><img className="w-100" src={data.path} alt="aadharCard" /></div>
                                        )
                                        : null}
                                </div>
                            </> : ""
                    }

                </div>
                <ModalBody className='text-center tracking-in-expand-fwd-bottom'>

                </ModalBody>

            </Modal>
            <SuccessAlertBox isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />

            {/*Error Box*/}
            <ErrorAlertBox
                isOpen={isOpenError}
                setIsOpen={setIsOpenError}
                heading={heading}
                errMsg={errMsg}
            />
            {/*Employee documents show popup */}
            <Modal className=" fade show tracking-in-expand-fwd-top" isOpen={viewEmpDoc} >
                <div className='px-2'>
                    <div className='row'>
                        <div className='col-sm-11 col-md-11 col-11'>
                            <p className='h5 text-secondary text-center my-2 fw-700'> Employee Personal Documents</p>
                        </div>
                        <div className='col-sm-1 col-md-1 col-1'>
                            <p className="float-end cursor-pointer py-2 px-2" onClick={(e) => setViewEmpDoc(false)}>
                                ❌
                            </p>
                        </div>
                        <div className='px-2'>
                            <div className='row'>
                                <div className='col-sm-6 col-md-6 col-6'>
                                    <h6 className='text-center my-1'>Aadhar card </h6>
                                    <img src={empAadharVal} alt="Aadhar Image" className="w-100 h-100"></img>
                                </div>
                                <div className='col-sm-6 col-md-6 col-6'>
                                    <h6 className='text-center my-1'>Pan card </h6>
                                    <img src={empPanVals} alt="Pan Image" className="w-100 h-100"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalBody className='text-center tracking-in-expand-fwd-bottom'>

                </ModalBody>

            </Modal>

        </div>
    )
}
export default ManageMaster;