import React, { useRef, useState } from 'react';
import { Button } from "@mui/material";
import * as pdfjsLib from 'pdfjs-dist/webpack';



import { VERIFY_EXCEL, CREATE_DOCUMENT, UPLOAD_IMPREST_SHEET, UPLOAD_TRAVEL_EXPENSE_EXCEL, UPLOAD_COST_JOB_EXCEL, UPLOAD_VENDOR_EXCEL, UPLOAD_STCK_ASST_EXCEL, VELIDATE_vENDOR_EXCEL, UPLOAD_COMPLIANCE_EXCEL } from '../../utiles/AllApis';
import axios from 'axios';
import * as XLSX from 'xlsx';
//import {Modal,ModalBody} from 'reactstrap';
import ErrorAlertBox from '../AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../AlertBox/SuccessAlertProduct';
import { onlynumericalForExcel } from '../../GenericFunction/GenericFunction';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { useEffect } from 'react';
const Main = (props, { refreshData }) => {
  // pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);
  const [excelData, setExcelData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [title, setTitlle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCancel, setIsCancel] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [heading, setHeading] = useState("");
  const [file, setFile] = useState("");

  const [jsonDate, setJsonData] = useState([]);
  const [textData, setTextData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [pdfData, setPdfData] = useState([]);

  const urlVal = "";
  const selectedcost = async (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      // Process Excel file
      if (props.for === 'JOB_SHEET' || props.for === 'PROD_RDR' || props.for === 'PROD_RDRR' || props.for === 'PUCH_ORD') {
        setExcelData(file);
        setFile(null);
        setImageData(null);
        setPdfData(null);
      }
      else {
        const data = await file.arrayBuffer();
        const excelFile = XLSX.read(data);
        const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
        const excelJson = XLSX.utils.sheet_to_json(excelSheet);
        setExcelData(excelJson);
        setFile(null);
        setImageData(null);
        setPdfData(null);
      }
    } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // Allow .doc and .docx files
      setExcelData(null);
      setFile(file);
      setImageData(null);
      setPdfData(null);
    } else if (fileType.startsWith("image/")) {
      // Process image file
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        setExcelData(null);
        setPdfData(null);
        setFile(null);
        const imageFile = dataURLToFile(imageDataUrl, file.name);

        setImageData(imageFile);
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      if (fileExtension !== "pdf") {
        e.target.value = null;
        return;
      }
      setPdfData(file);
      setExcelData(null);
      setImageData(null);
      setFile(null);
    } else {
      alert("Unsupported file type");
    }
  };


  const dataURLToFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  function isValidDate(dateString) {

    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
      return false;
    }


    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    if (year < 1000 || year > 9999 || month == 0 || month > 12) {
      return false;
    }

    const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLengths[1] = 29;
    }


    return day > 0 && day <= monthLengths[month - 1];
  }


  function formatDate(date) {
    // Pad single-digit month and day with leading zeros
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    var day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  const validateExcel = () => {
    if (props.for !== "PROD_RDR" && props.for !== 'PROD_RDRR') {

      if (props.for !== "PUCH_ORD" && props.for !== "JOB_SHEET") {
        let errList = [];
        if (excelData.length > 0) {

          excelData?.map((data, i) => {

            if (props.for === "IMPRESST_SHEET") {
              if (data.DATE === undefined) {
                data.DATE = "1990-01-01";
              }
              else {
                var jsDate = ExcelDateToJSDate(data.DATE);
                data.DATE = formatDate(jsDate);
              }

              if (data.RECEIVED === undefined) {
                data.Length = "";
              }
              else {
                if (!onlynumericalForExcel(data.RECEIVED)) {
                  errList.push(`Row ${i + 1} : Length ${data.RECEIVED} must be numerical value.`);
                }
              }
              if (data.EXPENSE === undefined) {
                data.EXPENSE = "";
              }
              else {
                if (!onlynumericalForExcel(data.EXPENSE)) {
                  errList.push(`Row ${i + 1} : Length ${data.EXPENSE} must be numerical value.`);
                }
              }
            }
            if (props.for === "TRAVEL_EXPENSE") {

              if (data.Date === undefined) {
                data.Date = "1990-01-01";
              }
              else {
                var jsDate = ExcelDateToJSDate(data.Date);
                data.Date = formatDate(jsDate);
              }
            }
            if (props.for == "ORDER_SHEET") {
              if (data.Particular_Name === undefined) {
                errList.push(`Row ${i + 1} : Particular Name cannot be Black.`);
              }
              if (data.Qty === undefined) {
                data.Qty = "";
              }
              else {
                if (!onlynumericalForExcel(data.Qty)) {
                  errList.push(`Row ${i + 1} : Length ${data.Qty} must be numerical value.`);
                }
              }
              if (data.Amt === undefined) {
                data.Amt = "";
              }
              else {
                if (!onlynumericalForExcel(data.Amt)) {
                  errList.push(`Row ${i + 1} : Length ${data.Amt} must be numerical value.`);
                }
              }
            }
            if (props.for === "COST" || props.for === "PROD_RDR" || props.for === "RESPONSIBILITY_SHEET") {
              if (data.Particular_Name === undefined) {
                errList.push(`Row ${i + 1} : Particular Name cannot be Black.`);
              }
              if (data.Particular_Type === undefined) {
                errList.push(`Row ${i + 1} : Particular Type cannot be Black.`);
              }
              if (data.Length === undefined) {
                data.Length = "";
              }
              else {
                if (!onlynumericalForExcel(data.Length)) {
                  errList.push(`Row ${i + 1} : Length ${data.Length} must be numerical value.`);
                }
              }
              if (data.Width === undefined) {
                data.Width = "";
              }
              else {
                if (!onlynumericalForExcel(data.Width)) {
                  errList.push(`Row ${i + 1} : Width ${data.Width} must be numerical value.`);
                }
              }
              if (data.Height === undefined) {
                data.Height = "";
              }
              else {
                if (!onlynumericalForExcel(data.Height)) {
                  errList.push(`Row ${i + 1} : Height ${data.Height} must be numerical value.`);
                }
              }
              if (data.Area === undefined) {
                data.Area = "";
              }
              else {
                if (!onlynumericalForExcel(data.Area)) {
                  errList.push(`Row ${i + 1} : Area ${data.Area} must be numerical value.`);
                }
              }
              if (data.Qty === undefined) {
                data.Qty = "";
              }
              else {
                if (!onlynumericalForExcel(data.Qty)) {
                  errList.push(`Row ${i + 1} : Qty ${data.Qty} must be numerical value.`);
                }
              }
              if (data.Unit === undefined) {
                data.Unit = "";
              }
              else {
                if (typeof (data.Unit) !== "string") {
                  errList.push(`Row ${i + 1} : Unit ${data.Unit} must be String value.`);
                }
              }
              if (data.Days === undefined) {
                data.Days = "";
              }
              else {
                if (!onlynumericalForExcel(data.Days)) {
                  errList.push(`Row ${i + 1} : Days ${data.Days} must be numerical value.`);
                }
              }
              if (data.Rate === undefined) {
                data.Rate = "";
              }
              else { }
              if (!onlynumericalForExcel(data.Rate)) {
                errList.push(`Row ${i + 1} : Rate ${data.Rate} must be numerical value.`);
              }
              if (data.Amt === undefined) {
                data.Amt = "";
              }
              else {
                if (!onlynumericalForExcel(data.Amt)) {
                  errList.push(`Row ${i + 1} : Amt ${data.Amt} must be numerical value.`);
                }
              }
            }
            else if (props.for === "VENDOR") {
              if (data.Contact_Number === undefined) {
                data.Contact_Number = "";
              }
              else {
                if (typeof (data.Contact_Number) !== "number") {
                  errList.push(`Row ${i + 1} : Contact Number ${data.Contact_Number} must be numerical value.`);
                }
                else {
                  if (data.Contact_Number.toString().length > 10 || data.Contact_Number.toString().length < 10) {
                    errList.push(`Row ${i + 1} : Contact Number ${data.Contact_Number} length must be 10 digit.`);
                  }

                }
              }
              if (data.Scope_Of_Work === undefined) {
                data.Scope_Of_Work = "";
              }
              if (data.Company_Name === undefined) {
                data.Company_Name = "";
              }
              if (data.Contact_Person === undefined) {
                data.Contact_Person = "";
              }

            }
            else if (props.for === "ORDER_SHEET") {

            }
            else if (props.for === "JOB_SHEET") {
              if (data.Particular_Name === undefined) {
                errList.push(`Row ${i + 1} : Particular Name cannot be Black.`);
              }
              if (data.Particular_Type === undefined) {
                errList.push(`Row ${i + 1} : Particular Type cannot be Black.`);
              }
              if (data.Qty === undefined) {
                data.Qty = "";
              }
              else {
                if (!onlynumericalForExcel(data.Qty)) {
                  errList.push(`Row ${i + 1} : Qty ${data.Qty} must be numerical value.`);
                }
              }
              if (data.Size === undefined) {
                data.Size = "";
              }
              // else {
              //   if (!onlynumericalForExcel(data.Size)) {
              //     errList.push(`Row ${i + 1} : Size ${data.Size} must be numerical value.`);
              //   }
              // }
              if (data.Rate === undefined) {
                data.Rate = "";
              }
              else {
                if (!onlynumericalForExcel(data.Rate)) {
                  errList.push(`Row ${i + 1} : Rate ${data.Rate} must be numerical value.`);
                }
              }
              if (data.Amt === undefined) {
                data.Amt = "";
              }
              else {
                if (!onlynumericalForExcel(data.Amt)) {
                  errList.push(`Row ${i + 1} : Amt ${data.Amt} must be numerical value.`);
                }
              }
            }
            else if (props.for === "STCK_ASST") {
              if (data.Particular_Name === undefined) {
                errList.push(`Row ${i + 1} : Particular Name cannot be Black.`);
              }
              if (data.Particular_Type === undefined) {
                errList.push(`Row ${i + 1} : Particular Type cannot be Black.`);
              }
              if (data.Descriptions === undefined) {
                data.Descriptions = "";
              }
              if (data.New === undefined) {
                data.New = "";
              }
              if (data.Existing === undefined) {
                data.Existing = "";
              }
              if (data.Total === undefined) {
                data.Total = "";
              }
              else {
                if (!onlynumericalForExcel(data.Total)) {
                  errList.push(`Row ${i + 1} : Total ${data.Total} must be numerical value.`);
                }
              }
              if (data.Rate === undefined) {
                data.Rate = "";
              }
              else { }
              if (!onlynumericalForExcel(data.Rate)) {
                errList.push(`Row ${i + 1} : Rate ${data.Rate} must be numerical value.`);
              }
              if (data.Amount === undefined) {
                data.Amount = "";
              }
              else {
                if (!onlynumericalForExcel(data.Amount)) {
                  errList.push(`Row ${i + 1} : Amount ${data.Amount} must be numerical value.`);
                }
              }
              if (data.Event_Referance === undefined) {
                data.Event_Referance = "";
              }
              if (data.Purchase_Date === undefined) {
                data.Purchase_Date = "";
              }
              data.Purchase_Date = ExcelDateToJSDate(data.Purchase_Date);
            }
            else if (props.for === "ON_SITE_COMPLIANCE") {
              if (data.Contract_Term === undefined) {
                errList.push(`Row ${i + 1} : Contract Term cannot be Black.`);
              }
              if (data.Date === undefined) {
                data.Date = "1990-01-01";
              }
              else {
                data.Date = ExcelDateToJSDate(data.Date);
              }
              if (data.Status === undefined) {
                data.Status = "";
              }
              if (data.Remarks === undefined) {
                data.Remarks = "";
              }
            }
          });
          if (errList.length > 0) {
            inputRef.current.value = null;
            setIsOpen(true);
            setHeading("Failed: Please find the below list:-");
            setErrMsg(errList);
            return;
          }
        }
        else {
          setIsOpen(true);
          setHeading("opps...");
          setErrMsg("Excel has " + excelData.length + " rows. Please fill the Excel.");
          return;
        }
      }
    }
    //Date convertion serial formet to data formet
    handelAPICal(props.for);
  }
  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }
  const handelAPICal = (val) => {
    inputRef.current.value = null;
    if (val === "COST" || val === "RESPONSIBILITY_SHEET" || val === "ORDER_SHEET") {
      uploadCostJob();
    }
    else if (val === "PROD_RDR" || val === 'PROD_RDRR' || val === 'PUCH_ORD' || val === "JOB_SHEET") {
      uploadElementDocument(val)
    }
    else if (val === "TRAVEL_EXPENSE") {
      uploadTravelExpense();
    }
    else if (val === "IMPRESST_SHEET") {
      uploadImprestExpense();
    }
    else if (val === "VENDOR") {
      //ValidateVendor();
      uploadVendor();
    }
    else if (val === "STCK_ASST") {
      uploadStckAsst();
    }
    else if (val === "ON_SITE_COMPLIANCE") {
      uploadOnSiteCompliance();
    }

  }

  const uploadElementDocument = async (val) => {
    //alert("Hello")

    var FormData = require("form-data");
    var data = new FormData();
    if (file) {
      data.append("File", file);

    } else if (pdfData) {
      data.append("File", pdfData);
    } else if (excelData) {
      data.append("File", excelData);

    } else if (imageData) {
      data.append("File", imageData);

    }
    setLoading(true);
    // data.append("File", eligibilityfile);
    if (val === 'PROD_RDR') {
      data.append("SubType", `${props.ProjectId}_ELEMENT_SHEET`);
      data.append("name", `${props.ProjectId}_ELEMENT_SHEET`);
      data.append("Type", `${props.ProjectId}_ELEMENT_SHEET`);

    }
    else if (val === 'JOB_SHEET') {
      data.append("SubType", `${props.ProjectId}_BRAND_SHEET`);
      data.append("name", `${props.ProjectId}_BRAND_SHEET`);
      data.append("Type", `${props.ProjectId}_BRAND_SHEET`);


    }
    else if (val === 'PROD_RDRR') {
      data.append("SubType", `${props.ProjectId}_PRODUCTION_RIDER`);
      data.append("name", `${props.ProjectId}_PRODUCTION_RIDER`);
      data.append("Type", `${props.ProjectId}_PRODUCTION_RIDER`);


    } else {
      data.append("SubType", `${props.ProjectId}_PURCHASE_ORDER`);
      data.append("name", `${props.ProjectId}_PURCHASE_ORDER`);
      data.append("Type", `${props.ProjectId}_PURCHASE_ORDER`);

    }
    data.append("ID", props.ProjectId);

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };

    await axios(config)
      .then(function (response) {
        if (response.data.isSuccess) {
          alert("File uploaded successfully.");
          if (typeof props.refreshData === 'function') {
            props.refreshData();
          } else {
            console.error("refreshdata is not a function");
          }
          // if (props.reloadFun) {
          //   props.reloadFun();
          // }
          // if (props.onUploadSuccess) {
          //   props.onUploadSuccess(props.for);
          // }
        } else {
          alert("File upload failed.");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const uploadImprestExpense = () => {
    props.setLoading(true);
    console.log(props.for);
    console.log(excelData);

    var config = {
      method: "post",
      url: UPLOAD_IMPREST_SHEET,
      data: {
        "imprest": excelData,
        "projectId": "0",
        "type": props.for
      }
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);

        if (!response.data.isSuccess) {
          setIsOpen(true);
          setHeading("Oops...");
          setErrMsg("Something went wrong.");
        } else {
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your Excel file was uploaded successfully.");

          if (typeof props.refreshData === 'function') {
            props.refreshData();
          } else {
            console.error("refreshData is not a function");
          }
          props.onDataUpdated();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadTravelExpense = () => {

    props.setLoading(true);
    var config = {
      method: "post",
      url: UPLOAD_TRAVEL_EXPENSE_EXCEL,
      data:
      {
        "expense": excelData,
        "projectId": "0",
        "type": props.for
      }
    };
    axios(config)
      .then(function (response) {
        console.log(response)
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);

        if (!response.data.isSuccess) {
          setIsOpen(true);
          setHeading("opps...");
          setErrMsg("Somthing went wrong.");
        }
        else {
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your excel uploded successfully..");
          if (typeof props.refreshData === 'function') {
            props.refreshData();
          } else {
            console.error("refreshData is not a function");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //---------for uploading costing----------------
  const uploadCostJob = () => {

    props.setLoading(true);
    var config = {
      method: "post",
      url: UPLOAD_COST_JOB_EXCEL,
      data:
      {
        "cost_List": excelData,
        "projectId": props.ProjectId,
        "type": props.for
      }
    };
    axios(config)
      .then(function (response) {
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);
        props.reloadFun(props.for);
        if (!response.data.isSuccess) {
          setIsOpen(true);
          setHeading("opps...");
          setErrMsg("Somthing went wrong.");
        }
        else {
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your excel uploded successfully..");
          if (typeof props.refreshData === 'function') {
            props.refreshData();
          } else {
            console.error("refreshData is not a function");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  //----------for validate vendor----------------
  const ValidateVendor = () => {
    props.setLoading(true);
    var config = {
      method: "post",
      url: VELIDATE_vENDOR_EXCEL,
      data:
      {
        "vendor_List": excelData,
      }

    };
    axios(config)
      .then(function (response) {
        props.setLoading(false);
        if (!response.data.isSuccess) {
          let errList = [];
          setIsOpen(true);
          setHeading("Failed: Please find the below list:-");
          response.data.data?.map((data, i) => {
            errList.push(data.address);
          })
          setErrMsg(errList);

        }
        else {
          uploadVendor();
        }


      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //---------for uploading vendor-----------------
  const uploadVendor = () => {
    console.log(excelData)
    props.setLoading(true);
    var config = {
      method: "post",
      url: UPLOAD_VENDOR_EXCEL,
      data:
      {
        "vendor_List": excelData,
        "projectId": props.ProjectId,
      }

    };
    axios(config)
      .then(function (response) {
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);
        props.reloadFun(props.for);
        if (!response.data.isSuccess) {
          setIsOpen(true);
          //setTitlle(response.data.message);
          setHeading("opps...");
          setErrMsg(response.data.message);
        }
        else {

          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your excel uploded successfully..");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //---------for STOCK ASSESTS-----------------

  const uploadStckAsst = () => {
    props.setLoading(true);
    var config = {
      method: "post",
      url: UPLOAD_STCK_ASST_EXCEL,
      data:
      {
        "Stck_Asst": excelData,
        "projectId": props.ProjectId,
      }

    };
    axios(config)
      .then(function (response) {
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);
        props.reloadFun(props.for);
        if (!response.data.isSuccess) {
          setIsOpen(true);
          //setTitlle(response.data.message);
          setHeading("opps...");
          setErrMsg(response.data.message);
        }
        else {
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your excel uploded successfully..");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //---------for ON SITE COMPLIANCE -----------------
  const uploadOnSiteCompliance = () => {
    props.setLoading(true);
    var config = {
      method: "post",
      url: UPLOAD_COMPLIANCE_EXCEL,
      data:
      {
        "compliance": excelData,
        "projectId": props.ProjectId,
      }

    };
    axios(config)
      .then(function (response) {
        props.setLoading(false);
        setIsSubmit(false);
        setIsRefresh(true);
        setIsCancel(false);
        props.reloadFun(props.for);
        if (!response.data.isSuccess) {
          setIsOpen(true);
          setHeading("opps...");
          setErrMsg(response.data.message);
        }
        else {
          setIsOpen1(true);
          setHeading1("Success");
          setsuccessMsg("Your excel uploded successfully..");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="row py-2">
        <div className="col-md-12 col-sm-12 col-12">
          <h3
            style={{
              color: "#0f533d",
              textTransform: "uppercase",
              fontWeight: "900",
              textAlign: "center",
            }}
            className="mb-2"
          >
            {" "}
            <span className="text-white h4 text-capitalize mrglft-5"> {props.title}
            </span>
          </h3>
        </div>
        <div
          className="d-flexed mt-3 tracking-in-expand-fwd-top"
          style={
            props.for !== "RESPONSIBILITY_SHEET" ?
              {
                display: "flex",
                justifyContent: "space-between",
                margin: "auto",
                width: "70%"
              } :
              {
                display: "flex",
                justifyContent: "space-between",
                margin: "auto",
                width: "40%"
              }
          }
        >
          {
            props.for !== "RESPONSIBILITY_SHEET" ?
              <a href={props.dwnPath} className="downloadbtn" download>
                {" "}
                <ion-icon name="download-outline"></ion-icon>&nbsp;&nbsp;Download Default Template
              </a> : null
          }

          <input
            type="file"
            id="fileCost"
            ref={inputRef}
            onChange={(e) => selectedcost(e)}
            style={{ padding: "10px" }}
            className="text-white"
          />
          <Button
            style={{ background: "green", color: "#fff", height: "35px", textTransform: "capitalize" }}
            //  onClick={verifyExcel}
            onClick={validateExcel}
          >
            Upload Excel
          </Button>
          <div>
          </div>
        </div>

      </div>












      <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
      <Modal className=" fade show" isOpen={isOpen1} >
        <ModalHeader className='brdBtm0 text-center pb-0'
        >
          <h4 className=' text-center  fw-bold  successColor'>
            {heading1}</h4>
        </ModalHeader>
        <ModalBody className='text-center pt-1'>
          <p className='h6 text-dark'>{successMsg}</p>
          <div>   <Button
            className='btnSuccess'
            onClick={() => { setIsOpen1(false); }}
          >
            Ok
          </Button></div>
        </ModalBody>

      </Modal>
    </>
  )
}
export default Main;