import React, { useEffect, useState } from 'react';
import {
  GET_Employee, ADD_PROJECT, CHOOSE_DOCUMENT, CREATE_DOCUMENT,
  GET_CLIENT, Is_PRO_EXIST, CREATE_PRO_CAT, TENDER_STATE,
  GET_DOCUMENT, GET_DOCUMENTBYID, GET_PRO_CAT, ALLOWED_USERTYPE
}
  from '../../../utiles/AllApis';
import axios from 'axios';
import ErrorAlertBox from '../../../common/AlertBox/ErrorAlertBox';
import SuccessAlertBox from '../../../common/AlertBox/SuccessAlertBox';
import SideBar from '../../../common/SideBar/SideBar';
import Cookies from 'js-cookie';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Icon from '@mui/material/Icon';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Category } from '@material-ui/icons';
const MasterFrom = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { ProjectID } = useParams()
  const navigate = useNavigate()

  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'DOCUMENT NAME', width: 300 },
    { field: 'toDate', headerName: 'TO DATE', width: 300 },
    { field: 'path', headerName: 'IMAGE', width: 400 },

  ]
  const [error, setError] = useState("");
  const [proCatList, setProCatList] = useState([]);
  const [projectDetails, setProjetcDetails] = useState({
    "ProjectName": "",

    "ProjectType": "QUICK_CREATION",

    "Category": "",

    "OwnerName": "",

    "CLIENT_MCODE": "",  // An mcode will be passed here

    "Remarks": "",

    "Won": false,

    "Interested": false,

    "EstimatedProjectCost": 0,

    "TenderDocumentFee": 0,

    "EMD": 0,

    "ProcessOfSubmissionBid": "",

    "PresentationDate": "1990-01-01",

    "OpenFinancialBids": "1990-01-01",

    "DateOfOpeningTecBid": "1990-01-01",

    "Evaluation": "",

    "EvaluationIssue": "",

    "ScoringIssue": "",

    "LdateOfPreBidQueries": "1990-01-01",

    "LdateOfSubmissionBid": "1990-01-01",

    "DateOfOpeningTecBid": "1990-01-01",

    "PreBidMeetingDate": "1990-01-01",

    "eventDate": "1990-01-01",

    "eventToDate": "1990-01-01",

    "EventLocation": "",

    "CLIENTNAME": "",

    "RECEIVED_ACCEP_DATE": "1990-01-01",

    "RECEIVED_WORKORDER_DATE": "1990-01-01",

    "SECURITY_DEPOSIT_DATE": "1990-01-01",

    "EMD_RELEASED_DATE": "1990-01-01",

    "STATE": TENDER_STATE.NEW,

    "proRemarks": "",
    "ProjectDescription": ""

  });
  const catChange = (e) => {
    setCatMaster({ ...cat, [e.target.name]: e.target.value });
  };
  const [cat, setCatMaster] = useState({
    Name: "",
  });
  const SubmissionBIDList = ['ONLINE', 'OFFLINE'];
  const ProjectCategoryList =
    ['Cultural', 'Sports',
      'Virtual(Digital)', 'Rallies',
      'Conclave & Awards', 'PM (VVIP Events)',
      'International', 'Opening & Closing',
      'Media', 'Stall Design',
      'Projection Mapping', 'Drone Show',
      'Concert', 'Exhibition',
      'Religious', 'Firework', 'Empanelment', 'Other'
    ]
  const setProjectCategoryName = (catVal) => {
    const str = catVal.join(',');
    setProjetcDetails({ ...projectDetails, "Category": str });
  }
  const setSubmissionBIDListName = (BIDVal, val) => {

    const str = BIDVal.join(',');
    setProjetcDetails({ ...projectDetails, [val]: str });
  }
  const [heading, setHeading] = useState("");
  const [corrigendumfile, setCorrigendumfile] = useState("");
  const [corrigendumfile2, setCorrigendumfile2] = useState("");
  const [corrigendumfile3, setCorrigendumfile3] = useState("");
  const [corrigendumfile4, setCorrigendumfile4] = useState("");
  const [corrigendumfile5, setCorrigendumfile5] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [heading1, setHeading1] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [catFromOpen, setCatFromOpen] = useState(false);
  //-----------for client name draft val----------
  const [cName, setClientName] = useState("");
  const [cltName, setCltName] = useState("");
  const [proLeadName, setProLeadName] = useState("");
  const [selectClient, setSelectClient] = useState("");
  //-----------for projrct lead draft val----------
  const [ownerName, setOwnerName] = useState("");
  const [SelectOwnerName, setSelectOwnerName] = useState("");
  //-----------for projrct Type draft val----------
  const [proType, setProType] = useState("");
  const [SelectproType, setSelectproType] = useState("");
  //-----------for projrct eVALUATION draft val----------
  const [proEval, setProEval] = useState("");
  const [SelectproEval, setSelectproEval] = useState("");
  const [dropdown, setdropdown] = useState([])
  const [dropdownclient, setdropdownclient] = useState([])
  const [allvendordropdown, setallvendordropdown] = useState([])
  var optClient = [];
  var optLead = [];
  const [clientSeach, setClientSeach] = useState("");
  const [leadSeach, setLeadSeach] = useState("");
  const [categories, setcategories] = useState([]);
  const [erm, setERM] = useState("")
  const [multidocs, setmultidocs] = useState("")
  const [multiprojecttype, setmultiprojecttype] = useState("")
  const [multiProcessOfSubmissionOfBid, setMultiProcessOfSubmissionOfBid] = useState("");
  const [showMultiProcessOfSubmissionOfBid, setshowMultiProcessOfSubmissionOfBid] = useState(false);
  const [showdocs, setshowdocs] = useState(false)
  const [carouseldata, setcarouseldata] = useState([])
  const [doculist, setdoculist] = useState([]);
  const [Emplist, setemplist] = useState([]);
  const [todaysdate, settodaysdate] = useState("1990-01-01T00:00:00")
  const [addmorebtn, setaddmorebtn] = useState("addmore")
  const [projectID, setprojectId] = useState("")
  const [additionals, setadditionals] = useState(false)
  const [eligibilityfile, seteligibilityfile] = useState("")
  const [projectmyltidocs, setprojectmyltidocs] = useState("")
  const [BOQdocs, setBOQdocs] = useState("");
  const [sideBtn, setSideBtn] = useState("ADD MORE");
  const [iconSet, setIconSet] = useState();
  const handalOpen = () => { setIsOpen(true); }
  const [isOpen1, setIsOpen1] = useState(false);
  const handalOpen1 = () => { setIsOpen1(true); }
  
  useEffect(() => {
    setIconSet(<ion-icon name='caret-down-outline'></ion-icon>);
    setprojectId(window.location.href.split("=")[1])
    axios.get(GET_Employee).then((response) => {
      if (response.data.isSuccess === true) {
        response.data.data.map((data, i) => {
          var fullName = `${data.firstName} ${data.lastName}`
          optLead.push({ value: data.employeeID, label: fullName })
          //data.lastName
        })
        setLeadSeach(<Select options={optLead} onChange={(e) => onHandleChangeSearch(e, "OwnerName")} />)
      }

    })
    //-----------For get pro categories-------------
    getProCat();
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);
    settodaysdate("1990-01-01 00:00:00");
    var config = {
      method: 'post',
      url: CHOOSE_DOCUMENT,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        setdropdown(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    axios.post(GET_DOCUMENT).then((response) => {
      setdoculist(response.data.data);
    })
    var thisrowpos = window.location.href.split("=")[1]
    axios.post(GET_DOCUMENTBYID, { "ROWPOS": thisrowpos }).then((response) => {

    })
    axios.get(GET_CLIENT).then((response) => {
      if (response.data.isSuccess === true) {
        //setdropdownclient(response.data.data);
        response.data.data.map((data, i) => {
          optClient.push({ value: data.mcode, label: data.clientName })
        })
        setClientSeach(<Select options={optClient} onChange={(e) => onHandleChangeSearch(e, "CLIENT_MCODE")} />)
      }
    })
    var thisrowpos = window.location.href.split("=")[1];
    if (Cookies.get("ProInfo") !== undefined) {
      setProjetcDetails(JSON.parse(Cookies.get("ProInfo")));
    }
    if (Cookies.get("ProCLIENTNAME") !== undefined) {
      setClientName(Cookies.get("ProCLIENTNAME"));
    }
    if (Cookies.get("ProOwnerName") !== undefined) {
      setOwnerName(Cookies.get("ProOwnerName"));
    }
    if (Cookies.get("ProType") !== undefined) {
      setProType(Cookies.get("ProType"));
    }
    if (Cookies.get("ProInfo") !== undefined) {
      // console.log(JSON.parse(Cookies.get("ProInfo")).Category.split(","));
      if (JSON.parse(Cookies.get("ProInfo")).Category !== "") {
        setcategories(JSON.parse(Cookies.get("ProInfo")).Category.split(","));
      }
      else {
        setcategories([]);
      }
    }

  }, [])
  //------------getProject category-------------
  const getProCat = () => {
    axios.get(GET_PRO_CAT).then((response) => {
      if (response.data.isSuccess === true) {
        var arr = [];
        response?.data?.data.map((data, i) => {
          arr.push(data.name);

        })
        setProCatList(arr);

      }

    })
  }
  //----------uploade project documents-------------
  const UploadProjEligibleDOCS = async (newproject_ROWPOS) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", eligibilityfile);
    data.append("ID", newproject_ROWPOS);
    data.append("SubType", `${newproject_ROWPOS}_EligibilityDocument`);
    data.append("name", `${newproject_ROWPOS}_EligibilityDocument`);
    data.append("Type", "ELIGIBILITY_DOCUMENT");
    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };

    await axios(config)
      .then(function (response) {
        if (projectmyltidocs !== "") {
          uploadprojectMultipleDocs(newproject_ROWPOS);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //-----------upload projects documents---------------------
  const uploadprojectMultipleDocs = async (newproject_ROWPOS) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", projectmyltidocs);
    data.append("ID", newproject_ROWPOS);
    data.append("SubType", `${newproject_ROWPOS}_ProjectDocument`);
    data.append("name", `${newproject_ROWPOS}_ProjectDocument`);
    data.append("Type", "PROJECT_DOCUMENT");

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };

    await axios(config)
      .then(async function (response) {

        if (corrigendumfile !== "") {
          await UploadCorrigendumDOCS(newproject_ROWPOS, corrigendumfile, `${newproject_ROWPOS}_CORRIGENDUM_DOCUMENT1`);
        }
        if (corrigendumfile2 !== "") {
          await UploadCorrigendumDOCS(newproject_ROWPOS, corrigendumfile2, `${newproject_ROWPOS}_CORRIGENDUM_DOCUMENT2`);
        }
        if (corrigendumfile3 !== "") {
          await UploadCorrigendumDOCS(newproject_ROWPOS, corrigendumfile3, `${newproject_ROWPOS}_CORRIGENDUM_DOCUMENT3`);
        }
        if (corrigendumfile4 !== "") {
          await UploadCorrigendumDOCS(newproject_ROWPOS, corrigendumfile4, `${newproject_ROWPOS}_CORRIGENDUM_DOCUMENT4`);
        }
        if (corrigendumfile5 !== "") {
          await UploadCorrigendumDOCS(newproject_ROWPOS, corrigendumfile5, `${newproject_ROWPOS}_CORRIGENDUM_DOCUMENT5`);
        }
        if (BOQdocs !== "") {
          await UploadBOQDOCS(newproject_ROWPOS, BOQdocs, `${newproject_ROWPOS}_BOQ_DOCUMENT5`);
        }
        setLoading(false);
        setHeading1("Success");
        setsuccessMsg("Project Add Successfully");
        handalOpen1();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //-----------upload corrigendum documents----------------
  const UploadCorrigendumDOCS = async (newproject_ROWPOS, file, fName) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", file);
    data.append("ID", newproject_ROWPOS);
    data.append("SubType", fName);
    data.append("name", fName);
    data.append("Type", "CORRIGENDUM_DOCUMENT");

    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    await axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  //----------uploade BOQ documents-------------
  const UploadBOQDOCS = async (newproject_ROWPOS) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", BOQdocs);
    data.append("ID", newproject_ROWPOS);
    data.append("SubType", `${newproject_ROWPOS}_BOQ_Documents`);
    data.append("name", `${newproject_ROWPOS}_BOQ_Documents`);
    data.append("Type", "BOQ_DOCUMENT");
    var config = {
      method: "post",
      url: CREATE_DOCUMENT,

      data: data,
    };
    await axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });
  }


useEffect(() => {
  

  const savedProjectDetails = JSON.parse(localStorage.getItem("projectDetails"));
  if (savedProjectDetails) {
    setProjetcDetails(savedProjectDetails);
  }
 //document.getElementById("projectName").value=projectDetails.ProjectName

}, []);



 
  // const handleSetCookie = () => {
  //   Cookies.set('event',projectDetails.ProjectName , { expires: 7 });
  //   setEventName(Cookies.get('event'));
  //    Cookies.set('remark', projectDetails.proRemarks, { expires: 7 });
  //    setRemark(Cookies.get('remark'));
  //   Cookies.set('event',proLeadName , { expires: 7 });
  //    setLead(Cookies.get('lead'));

  //   Cookies.set('category',projectDetails.Category , { expires: 7 });
  //   setCategory(Cookies.get('category'));
  //   Cookies.set('desc',projectDetails.proDesc , { expires: 7 });
  //   setDescription(Cookies.get('desc'));
  //   Cookies.set('type',projectDetails.ProjectType  , { expires: 7 });
  //   setProjectType(Cookies.get('type'));

    
  // };

  // const handleRemoveCookie = () => {
  //   Cookies.remove('username');
  //   setUsername('');
  // };
  //-----------------Add Project------------------
  const submitAddProject = () => {
    if (props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN) {
      if (projectDetails.ProjectName === "" || cltName === "" || projectDetails.Category === ""
        || proLeadName === "" || projectDetails.ProjectType === ""
        || projectDetails.Evaluation === "" || eligibilityfile === "" || projectmyltidocs === "") {
        setHeading("Submission Failed!");
        setErrMsg("Mandatory Fields Cannot Empty");
        handalOpen();
        return;
      }

    }
    else {
      if (projectDetails.ProjectName === "") {
        setHeading("Submission Failed!");
        setErrMsg("Mandatory Fields Cannot Empty");
        handalOpen();
        return;
      }
    }
    if (projectDetails.LdateOfSubmissionBid === "") {
      setHeading("Submission Failed!");
      setErrMsg("Date and Time both mandatory in date-time field");
      handalOpen();
    }
    else {
      if (projectDetails.eventDate !== "1990-01-01") {
        if (projectDetails.eventDate > projectDetails.eventToDate) {
          setHeading("Submission Failed!");
          setErrMsg("Event starting data cannot greater then event end date");
          handalOpen();
          return;
        }
      }
      setLoading(true);
      axios.post(Is_PRO_EXIST,
        {
          "ProjectName": projectDetails.ProjectName
        }).then((response) => {

          if (response.data.isSuccess !== true) {
            axios.post(ADD_PROJECT, {
              "ProjectName": projectDetails.ProjectName,

              "ProjectType": projectDetails.ProjectType,

              "Category": projectDetails.Category,

              "OwnerName": proLeadName,

              "CLIENT_MCODE": cltName, // An mcode will be passed here

              "Remarks": projectDetails.Remarks,

              "Won": projectDetails.Won,

              "Interested": projectDetails.Interested,

              "EstimatedProjectCost": projectDetails.EstimatedProjectCost === "" ? 0 : projectDetails.EstimatedProjectCost,

              "TenderDocumentFee": projectDetails.TenderDocumentFee === "" ? 0 : projectDetails.TenderDocumentFee,

              "EMD": projectDetails.EMD === "" ? 0 : projectDetails.EMD,

              "ProcessOfSubmissionBid": projectDetails.ProcessOfSubmissionBid,

              "PresentationDate": projectDetails.PresentationDate === "" ? "1990-01-01" : projectDetails.PresentationDate,

              "OpenFinancialBids": projectDetails.OpenFinancialBids === "" ? "1990-01-01" : projectDetails.OpenFinancialBids,

              "DateOfOpeningTecBid": projectDetails.DateOfOpeningTecBid === "" ? "1990-01-01" : projectDetails.DateOfOpeningTecBid,

              "Evaluation": projectDetails.Evaluation,

              "EvaluationIssue": projectDetails.EvaluationIssue,

              "ScoringIssue": projectDetails.ScoringIssue,

              "LdateOfPreBidQueries": projectDetails.LdateOfPreBidQueries === "" ? "1990-01-01" : projectDetails.LdateOfPreBidQueries,

              "LdateOfSubmissionBid": projectDetails.LdateOfSubmissionBid === "" ? "1990-01-01" : projectDetails.LdateOfSubmissionBid,

              "DateOfOpeningTecBid": projectDetails.DateOfOpeningTecBid === "" ? "1990-01-01" : projectDetails.DateOfOpeningTecBid,

              "PreBidMeetingDate": projectDetails.PreBidMeetingDate === "" ? "1990-01-01" : projectDetails.PreBidMeetingDate,

              "eventDate": projectDetails.eventDate === "" ? "1990-01-01" : projectDetails.eventDate,

              "eventToDate": projectDetails.eventToDate === "" ? "1990-01-01" : projectDetails.eventToDate,

              "EventLocation": projectDetails.EventLocation,

              "CLIENTNAME": projectDetails.CLIENTNAME,

              "RECEIVED_ACCEP_DATE": projectDetails.RECEIVED_ACCEP_DATE === "" ? "1990-01-01" : projectDetails.RECEIVED_ACCEP_DATE,

              "RECEIVED_WORKORDER_DATE": projectDetails.RECEIVED_WORKORDER_DATE === "" ? "1990-01-01" : projectDetails.RECEIVED_WORKORDER_DATE,

              "SECURITY_DEPOSIT_DATE": projectDetails.SECURITY_DEPOSIT_DATE === "" ? "1990-01-01" : projectDetails.SECURITY_DEPOSIT_DATE,

              "EMD_RELEASED_DATE": projectDetails.EMD_RELEASED_DATE === "" ? "1990-01-01" : projectDetails.EMD_RELEASED_DATE,

              "STATE": projectDetails.STATE,

              "proRemarks": projectDetails.proRemarks,
              "ProjectDescription": projectDetails.proDesc,

            }).then((response) => {
              setLoading(false);
              if (response.data.isSuccess === true) {
                localStorage.clear();
                var newproject_ROWPOS = response.data.data.rowpos;
                if (props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN) {
                  UploadProjEligibleDOCS(newproject_ROWPOS);
                }
                else {
                  setHeading1("Success");
                  setsuccessMsg("Project Add Successfully");
                  handalOpen1();
                }
              }
              else {
                setHeading("Submission Failed!");
                setErrMsg(`Opps..${response.data.message}`);
                handalOpen();
              }
            });
          }
          else {
            setLoading(false);
            setHeading("Submission Failed!");
            setErrMsg(`${response.data.message}`);
            handalOpen();

          }
        });
    }

  }
  //------------------save in draft-------------------------
  const saveInDraft = () => {
    if (projectDetails.ProjectName === "") {
      setHeading("Submission Failed!");
      setErrMsg("All Field cannot blank please Fill project name to save draft");
      handalOpen();
    }
    else {
      if (projectDetails.eventDate !== "1990-01-01") {
        if (projectDetails.eventDate > projectDetails.eventToDate) {
          setHeading("Submission Failed!");
          setErrMsg("Event starting data cannot greater then event end date");
          handalOpen();
          return;
        }
      }
      setLoading(true);
      axios.post(Is_PRO_EXIST,
        {
          "ProjectName": projectDetails.ProjectName
        }
      ).then((response) => {

        if (response.data.isSuccess !== true) {
          axios.post(ADD_PROJECT, {

            "ProjectName": projectDetails.ProjectName,

            "ProjectType": projectDetails.ProjectType,

            "Category": projectDetails.Category,

            "OwnerName": proLeadName,

            "CLIENT_MCODE": cltName,   // An mcode will be passed here

            "Remarks": projectDetails.Remarks,

            "Won": false,

            "Interested": false,

            "EstimatedProjectCost": projectDetails.EstimatedProjectCost === "" ? 0 : projectDetails.EstimatedProjectCost,

            "TenderDocumentFee": projectDetails.TenderDocumentFee === "" ? 0 : projectDetails.TenderDocumentFee,

            "EMD": projectDetails.EMD === "" ? 0 : projectDetails.EMD,

            "ProcessOfSubmissionBid": projectDetails.ProcessOfSubmissionBid,

            "PresentationDate": projectDetails.PresentationDate === "" ? "1990-01-01" : projectDetails.PresentationDate,

            "OpenFinancialBids": projectDetails.OpenFinancialBids === "" ? "1990-01-01" : projectDetails.OpenFinancialBids,

            "DateOfOpeningTecBid": projectDetails.DateOfOpeningTecBid === "" ? "1990-01-01" : projectDetails.DateOfOpeningTecBid,

            "Evaluation": projectDetails.Evaluation,

            "EvaluationIssue": projectDetails.EvaluationIssue,

            "ScoringIssue": projectDetails.ScoringIssue,

            "LdateOfPreBidQueries": projectDetails.LdateOfPreBidQueries === "" ? "1990-01-01" : projectDetails.LdateOfPreBidQueries,

            "LdateOfSubmissionBid": projectDetails.LdateOfSubmissionBid === "" ? "1990-01-01" : projectDetails.LdateOfSubmissionBid,

            "DateOfOpeningTecBid": projectDetails.DateOfOpeningTecBid === "" ? "1990-01-01" : projectDetails.DateOfOpeningTecBid,

            "PreBidMeetingDate": projectDetails.PreBidMeetingDate === "" ? "1990-01-01" : projectDetails.PreBidMeetingDate,

            "eventDate": projectDetails.eventDate === "" ? "1990-01-01" : projectDetails.eventDate,

            "eventToDate": projectDetails.eventToDate === "" ? "1990-01-01" : projectDetails.eventToDate,

            "EventLocation": projectDetails.EventLocation,

            "CLIENTNAME": projectDetails.clientName,

            "RECEIVED_ACCEP_DATE": "1990-01-01",

            "RECEIVED_WORKORDER_DATE": "1990-01-01",

            "SECURITY_DEPOSIT_DATE": "1990-01-01",

            "EMD_RELEASED_DATE": "1990-01-01",

            "STATE": TENDER_STATE.DRAFT,

            "proRemarks": projectDetails.proRemarks,
            "ProjectDescription": projectDetails.proDesc
          }).then((response) => {
            setLoading(false);
            if (response.data.isSuccess === true) {
              var newproject_ROWPOS = response.data.data.rowpos;
              if (eligibilityfile !== "") {
                UploadProjEligibleDOCS(newproject_ROWPOS);
              }
              if (projectmyltidocs !== "") {
                uploadprojectMultipleDocs(newproject_ROWPOS);
              }

              setHeading1("Success");
              setsuccessMsg("Project Saved in Draft List");
              handalOpen1();

            }
            else {
              setHeading("Submission Failed!");
              setErrMsg(`Opps..${response.data.message}`);
              handalOpen();
            }
          });

        }
        else {
          setLoading(false);
          setHeading("Submission Failed!");
          setErrMsg(`${response.data.message}`);
          handalOpen();

        }
      });

    }
  }
  const onHandleChange = (e) => {
    if (e.target.name === "ConsortionAllowed") {
      e.target.value = true
      if (e.target.value == "false") {
        e.target.value = false
      }
    }
    if (e.target.name === "CLIENT_MCODE") {
      setSelectClient(e.target.selectedOptions[0].text);
    }
    if (e.target.name === "OwnerName") {
      setSelectOwnerName(e.target.selectedOptions[0].text);
    }
    if (e.target.name === "ProjectType") {
      setSelectproType(e.target.selectedOptions[0].text);
    }
    if (e.target.name === "Evaluation") {
      setSelectproEval(e.target.selectedOptions[0].text);
    }
     const updatedDetails = { ...projectDetails, [e.target.name]: e.target.value };
     setProjetcDetails(updatedDetails);
  localStorage.setItem("projectDetails", JSON.stringify(updatedDetails));


  }
  const onHandleChangeSearch = (e, name) => {
    if (name === "CLIENT_MCODE") {
      setCltName(e.value);
    }
    else if (name === "OwnerName") {
      setProLeadName(e.value);
    }
  }
  const onSubmitHandeling = () => {

    if (cat.Name === "") {
      setHeading("Submission Failed!");
      setErrMsg("Fields Cannot Empty");
      handalOpen();
      return;
    } else {
      setLoading(true);
      axios.post(CREATE_PRO_CAT, cat).then((response) => {
        setLoading(false);
        if (response.data.isSuccess === true) {
          setCatMaster({ ...cat, "Name": "" });
          setCatFromOpen(false);
          getProCat();
        } else {
          setHeading("Creation Failed!");
          setErrMsg(`Opps.. SomeThing went Wrong`);
          handalOpen();
        }
      });
    }

  };
  const urlVal = `/projectList?type=new`;
  const handleshow = () => {
    setadditionals(true);
    if (additionals === true) {
      setadditionals(false);
      setIconSet(<ion-icon name='caret-down-outline'></ion-icon>)
      setSideBtn("ADD MORE ");
    }
    else {
      setIconSet(<ion-icon name='caret-up-outline'></ion-icon>)
      setSideBtn("HIDE ");
    }
  }
  const handleELigibilty = (e) => {
    var file = e.target.files[0]
    seteligibilityfile(file)
    if (file.name.split(".")[file.name.split(".").length - 1] !== "pdf") {
      setHeading("Submission Failed!");
      setErrMsg("Only Pdf are allowed");
      handalOpen();
      document.getElementById("ProjectEligebilitydoc").value = "";

    }
    else if (file.name.split(".")[file.name.split(".").length - 1] !== "pdf") {
      if (file.size > 31457280) {
        setHeading("Submission Failed!");
        setErrMsg("Maximum 30 MB file is allowed");
        handalOpen();
      }
      else {
        seteligibilityfile(e.target.files[0]);
      }
    }
  }
  const initialCategoryValues = projectDetails.Category || [];
  const handleprojectmultiDocs = (e) => {
    var files = e.target.files[0];
    if (files.name.split(".")[files.name.split(".").length - 1] !== "jpg" && files.name.split(".")[files.name.split(".").length - 1] !== "pdf" && files.name.split(".")[files.name.split(".").length - 1] !== "jpeg" && files.name.split(".")[files.name.split(".").length - 1] !== "png") {
      setHeading("Submission Failed!");
      setErrMsg("Only Pdf are allowed");
      handalOpen();
      document.getElementById("projectdocs").value = "";
    }
    else if (files.name.split(".")[files.name.split(".").length - 1] === "jpg" || files.name.split(".")[files.name.split(".").length - 1] === "jpeg" || files.name.split(".")[files.name.split(".").length - 1] === "png" || files.name.split(".")[files.name.split(".").length - 1] === "pdf") {
      if (files.size > 52428800) {
        setHeading("Submission Failed!");
        setErrMsg("Maximum 50 MB file is allowed");
        handalOpen();
      }
      else {
        setprojectmyltidocs(files);
        const updatedDetails = { ...projectDetails, projectdocs: files };
        setProjetcDetails(updatedDetails);
    localStorage.setItem("projectDetails", JSON.stringify(updatedDetails));
      }
    }
  }
  const handleBOQDocs = (e) => {
    var files = e.target.files[0];
    if (files.name.split(".")[files.name.split(".").length - 1].toUpperCase() !== "CSV" && files.name.split(".")[files.name.split(".").length - 1].toUpperCase() !== "XLSX" && files.name.split(".")[files.name.split(".").length - 1].toUpperCase() !== "XLS" && files.name.split(".")[files.name.split(".").length - 1].toUpperCase() !== "ODS") {
      setHeading("Submission Failed!");
      setErrMsg("BOQ Document should only Excel file");
      handalOpen();
      document.getElementById("BOQdocs").value = "";
    }
    else {
      setBOQdocs(files);
    }
  }
  const handleCorrigendum = (e) => {
    var file = e.target.files[0]
    // setCorrigendumfile(file)
    var files = e.target.files[0]
    if (file.name.split(".")[file.name.split(".").length - 1] !== "jpg" && file.name.split(".")[file.name.split(".").length - 1] !== "pdf" && file.name.split(".")[file.name.split(".").length - 1] !== "jpeg" && file.name.split(".")[file.name.split(".").length - 1] !== "png") {
      setHeading("Submission Failed!");
      setErrMsg("Only Pdf and Images are allowed");
      handalOpen();
      document.getElementById("corrigendumdoc").value = "";
    }
    else if (file.name.split(".")[file.name.split(".").length - 1] === "jpg" || file.name.split(".")[file.name.split(".").length - 1] === "jpeg" || file.name.split(".")[file.name.split(".").length - 1] === "png" || file.name.split(".")[file.name.split(".").length - 1] === "pdf") {
      if (file.size > 31457280) {
        setHeading("Submission Failed!");
        setErrMsg("Maximum 30 MB File Allowed");
        handalOpen();
      }
      else {
        if (e.target.id == "corrigendumdoc1") {
        setCorrigendumfile(files);
        }
        else if (e.target.id == "corrigendumdoc2") {
          setCorrigendumfile2(files);
        }
        else if (e.target.id == "corrigendumdoc3") {
          setCorrigendumfile3(files);
        }
        else if (e.target.id == "corrigendumdoc4") {
          setCorrigendumfile4(files);
        }
        else if (e.target.id == "corrigendumdoc5") {
          setCorrigendumfile5(files);
        }

      }


    }


  }
  const ProTypeList = [
    {
      "name": "OPEN",
      "value": "OPEN"
    },
    {
      "name": "LIMITED",
      "value": "LIMITED"
    },
    {
      "name": "EMPANELMENT",
      "value": "EMPANELMENT"
    }
  ]
  const ProEvalution = [
    {
      "name": "QCBS (80:20)",
      "value": "QCBS (80:20)"
    },
    {
      "name": "QCBS (75:25)",
      "value": "QCBS (75:25)"
    },
    {
      "name": "QCBS (70:30)",
      "value": "QCBS (70:30)"
    },
    {
      "name": "QCBS (60:40)",
      "value": "QCBS (60:40)"
    },
    {
      "name": "QCBS (50:50)",
      "value": "QCBS (50:50)"
    },
    {
      "name": "QCBS (40:60)",
      "value": "QCBS (40:60)"
    },
    {
      "name": "QCBS (30:70)",
      "value": "QCBS (30:70)"
    },
    {
      "name": "LCBS",
      "value": "LCBS"
    },
    {
      "name": "EOI/Empanelment",
      "value": "EOI/Empanelment"
    },
    {
      "name": "L1",
      "value": "L1"
    }
  ]
  return (
    <>
      <div className='row'>
        {
          isLoading ? <div className='loaderDiv'>
          </div> : null
        }
        {
          props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ?
            <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
              <SideBar />
            </div> : null
        }
        <div className='col-md-10 col-12 col-sm-12 formBg myview formbg' style={{ width: "100%", margin: "auto" }}>
          <h4 className='py-3 text-center theamColor'>Add New Project
          </h4>
          <h6 className='text-danger text-center'>* Please fill date and time both in date-time field</h6>
          <div className='createForm'>
            <div className={addmorebtn} onClick={handleshow}>{sideBtn}{iconSet}</div>
            <div className='d-flexed' style={{ justifyContent: "space-between" }}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Event Name <span className='required'>*</span></label>
                <input type="text" className="form-control mt-3" id="ProjectName" name="ProjectName" placeholder="Enter Event Name Here.." onChange={(e) => onHandleChange(e)} value={projectDetails.ProjectName} />

              </div>
              <div className="mb-3">
                <label htmlFor="floatingTextarea2" className="form-label">Project Name</label>
                <textarea className="form-control mt-3" name="proDesc" id="proDesc" placeholder="Enter project name Here..." onChange={(e) => onHandleChange(e)}  value={projectDetails.proDesc}></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Project documents
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                <input type="file" className="form-control mt-3" id="projectdocs" name="projectdocs" onChange={(e) => handleprojectmultiDocs(e)}  multiple />
                <span className='text-danger py-1 fw-bold'>{error}</span>
              </div>


            </div>
            <div className='d-flexed' style={{ justifyContent: "space-between" }}>
              <div className="mb-3">
                <Icon style={{ position:"relative",top:"-15%",left:"85%", cursor: "pointer", color: "#00a859" }} onClick={e => { 
                
                  
                  window.location.href = `/manageMaster/client_master/form?FormProject` }}>add_circle</Icon>
                <label htmlFor="exampleFormControlInput1" className="form-label">Client Name
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}

                </label>
                {clientSeach}
              </div>
              <div className="mb-3">
                <Icon style={{ left: "85%" ,position: "relative", cursor: "pointer", color: "#00a859" }} onClick={e => { setCatFromOpen(true); }}>add_circle</Icon>
                <label htmlFor="exampleFormControlInput1" className="form-label">Project Category
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                <Multiselect
                  id="Category"
                  className='mt-3'
                  selectedValues={categories}
                  placeholder="--Select Project Category--"
                  options={proCatList}
                
                  isObject={false}
                  showCheckbox={true}

                
                  onSelect={(event) => {
                    setProjectCategoryName(event);
                  }}
                  onRemove={(event) => {
                    setProjectCategoryName(event);
                  }}

                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Project Lead
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                {leadSeach}
              </div>


            </div>

            <div className='d-flexed' style={{ justifyContent: "space-between" }}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">PROJECT TYPE
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>

                <select className="form-select mt-3" aria-label="Default select example" id="ProjectType" name="ProjectType" onChange={(e) => onHandleChange(e)}>
                  <option value={projectDetails.ProjectType}>{projectDetails.ProjectType == "" ? "--Select Project Type--" : projectDetails.ProjectType}</option>
                  {
                    ProTypeList?.filter((data) => {
                      if (proType !== data.value) {
                        return data;
                      }
                    }).map((data, index) => (
                      <option value={data.value}>{data.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">ELIGIBILITY DOCUMENT
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                <input type="file" className="form-control mt-3" id="ProjectEligebilitydoc" name="ProjectEligebilitydoc" onChange={(e) => handleELigibilty(e)} />
                <span className='text-danger py-1 fw-bold'>{error}</span>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Last Date of Submission bids
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                <input type="datetime-local" className="form-control mt-3" value={projectDetails.LdateOfSubmissionBid} name="LdateOfSubmissionBid" id="LdateOfSubmissionBid" onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />
              </div>
              
            </div>
            {
              additionals ?
                <>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">Evaluation
                  {props.tokectDetails?.logintype === ALLOWED_USERTYPE.ADMIN ? <span className='required'>*</span> : ""}
                </label>
                <br />
                <select className="form-control mt-3" id="Evaluation" name="Evaluation" onChange={(e) => onHandleChange(e)}>
                  <option value={projectDetails.Evaluation}>{projectDetails.Evaluation == "" ? "--Select Project Type--" : projectDetails.Evaluation}</option>
                  {
                    ProEvalution?.filter((data) => {
                      if (proEval !== data.value) {
                        return data;
                      }
                    }).map((data, index) => (
                      <option value={data.value}>{data.name}</option>
                    ))
                  }
                </select>
              </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Estimated Project Cost</label>
                      <input type="number" className="form-control mt-3" id="EstimatedProjectCost" name="EstimatedProjectCost" onChange={(e) => onHandleChange(e)} value={projectDetails.EstimatedProjectCost} />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Earnest Money Deposit</label>
                      <input type="number" value={projectDetails.EMD}  className="form-control mt-3" name="EMD" id="EMD" onChange={(e) => onHandleChange(e)} defaultValue={0} />
                    </div>
                  
                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Tender Document Fee </label>
                      <input type="number" value={projectDetails.TenderDocumentFee} className="form-control mt-3" name="TenderDocumentFee" id="TenderDocumentFee" onChange={(e) => onHandleChange(e)} defaultValue={0} />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Process Of Submission of Bid</label>
                      <Multiselect
                        id="ProcessOfSubmissionBid"
                        placeholder="--Select Process Of Submission of Bid--"
                        options={SubmissionBIDList}
                        className='mt-3'
                        isObject={false}
                        showCheckbox={true}
                        onSelect={(event) => {
                          setSubmissionBIDListName(event, "ProcessOfSubmissionBid");
                        }}
                        onRemove={(event) => {
                          setSubmissionBIDListName(event, "ProcessOfSubmissionBid");
                        }}

                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Last Date of Pre-bid Queries</label>
                      <input type="datetime-local" className="form-control mt-3" value={projectDetails.LdateOfPreBidQueries} id="LdateOfPreBidQueries" name="LdateOfPreBidQueries" onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />

                    </div>
                 
                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Date Of Opening Technical bids</label>
                      <input type="datetime-local" className="form-control mt-3" value={projectDetails.DateOfOpeningTecBid} id="DateOfOpeningTecBid" name="DateOfOpeningTecBid" onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Date of Opening of Financial BIDS </label>
                      <input type="datetime-local" className="form-control mt-3" value={projectDetails.OpenFinancialBids} name="OpenFinancialBids" id="OpenFinancialBids" onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Presentation Date</label>
                      <input type="datetime-local" className="form-control mt-3" value={projectDetails.PresentationDate} id="PresentationDate" name="PresentationDate" placeholder="Enter Technical Bid Opening Here..." onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />

                    </div>
                
                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Date of Pre-Bid Meeting</label>
                      <input type="datetime-local" className="form-control mt-3" value={projectDetails.PreBidMeetingDate} id="PreBidMeetingDate" name="PreBidMeetingDate" onChange={(e) => onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59" />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 1</label>
                      <input type="file" value={projectDetails.corrigendumdoc1} className="form-control mt-3" id="corrigendumdoc1" name="corrigendumdoc" onChange={(e) => handleCorrigendum(e)} />
                      <span className='text-danger py-1 fw-bold'>{error}</span>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 2</label>
                      <input type="file" value={projectDetails.corrigendumdoc2} className="form-control mt-3" id="corrigendumdoc2" name="corrigendumdoc" onChange={(e) => handleCorrigendum(e)} />
                      <span className='text-danger py-1 fw-bold'>{error}</span>
                    </div>
                  

                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 3</label>
                      <input type="file" value={projectDetails.corrigendumdoc3} className="form-control mt-3" id="corrigendumdoc3" name="corrigendumdoc" onChange={(e) => handleCorrigendum(e)} />
                      <span className='text-danger py-1 fw-bold'>{error}</span>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 4</label>
                      <input type="file" value={projectDetails.corrigendumdoc4} className="form-control mt-3" id="corrigendumdoc4" name="corrigendumdoc" onChange={(e) => handleCorrigendum(e)} />
                      <span className='text-danger py-1 fw-bold'>{error}</span>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CORRIGENDUM DOCUMENT 5</label>
                      <input type="file" className="form-control mt-3" value={projectDetails.corrigendumdoc5} id="corrigendumdoc5" name="corrigendumdoc" onChange={(e) => handleCorrigendum(e)} />
                      <span className='text-danger py-1 fw-bold'>{error}</span>
                    </div>
                  
                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                    {/* <div className="mb-3">
  <label htmlFor="floatingTextarea2"className="form-label">Event to Date</label>
  <input type="datetime-local" className="form-control mt-3" id="eventToDate" name="eventToDate" onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate} max="9999-12-31T11:59:59"/>
  </div> */}
                   <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label d-block">Event From AND To Date</label>
                      <input type="date" className="form-control mt-3 w-50 d-inline px-0" value={projectDetails.eventDate} id="eventDate" name="eventDate" onChange={(e) => onHandleChange(e)} max="9999-12-31" defaultValue="1990-01-01" />
                      <input type="date" className="form-control mt-3 w-50 d-inline px-0" value={projectDetails.eventToDate} id="eventToDate" name="eventToDate" onChange={(e) => onHandleChange(e)} max="9999-12-31" defaultValue="1990-01-01" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">CONSORTIOUM ALLOWED</label>
                      <div className="form-check  mt-3">
                        <input className="form-check-input mt-1" type="radio"  name="ConsortionAllowed" id="bMarried" value={true} onClick={(e) => onHandleChange(e)} />
                        <label className="form-check-label mt-3 text-white" htmlFor="exampleRadios1">
                          Yes
                        </label>
                      </div>
                      <div className="form-check  mt-3">
                        <input className="form-check-input mt-1" type="radio" name="ConsortionAllowed" id="bMarried2" value={false} onClick={(e) => onHandleChange(e)} />
                        <label className="form-check-label mt-3 text-white" htmlFor="exampleRadios2">
                          No
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Eligibility Issue(If any)</label>
                      <textarea className="form-control mt-3" value={projectDetails.EvaluationIssue} name="EvaluationIssue" id="EvaluationIssue" placeholder="Enter Eligibility Issue Here..." onChange={(e) => onHandleChange(e)}></textarea>
                      {/* <input type="datetime-local" className="form-control mt-3" name="EvalutionIssue"id="EvalutionIssue"onChange={(e)=>onHandleChange(e)} defaultValue={todaysdate}max="9999-12-31T11:59:59" /> */}
                    </div>
                 
                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Scoring Issue(If any)</label>
                      <textarea className="form-control mt-3" value={projectDetails.ScoringIssue} name="ScoringIssue" id="ScoringIssue" placeholder="Enter Scoring Issue Here..." onChange={(e) => onHandleChange(e)}></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Event Location</label>
                      <textarea  className="form-control mt-3"  value={projectDetails.EventLocation} name="EventLocation" id="EventLocation" placeholder="Enter Event Location Here..." onChange={(e) => onHandleChange(e)}></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">BOQ Documents</label>
                      <input type="file" className="form-control mt-3" id="BOQdocs" name="BOQdocs" onChange={(e) => handleBOQDocs(e)} />
                    </div>
                  

                  </div>
                  <div className='d-flexed' style={{ justifyContent: "space-between" }}>
                  <div className="mb-3">
                      <label htmlFor="floatingTextarea2" className="form-label">Remarks</label>
                      <textarea className="form-control mt-3" value={projectDetails.proRemarks} name="proRemarks" id="proRemarks" placeholder="Enter Remarks Here..." onChange={(e) => onHandleChange(e)}  ></textarea>
                    </div>
                    </div>
                </> : ""
            }
            <center><button className='btn btnPrimary mx-1' onClick={submitAddProject}>Submit </button>
              {
                props.tokectDetails?.logintype !== ALLOWED_USERTYPE.OPERATIONS ?
                  <button className='btn btnSecondary mx-1' onClick={saveInDraft}>Save in Draft </button>
                  : null
              }
            </center>
          </div>
        </div>
        <ErrorAlertBox isOpen={isOpen} setIsOpen={setIsOpen} heading={heading} errMsg={errMsg} />
        <SuccessAlertBox href={urlVal} isOpen1={isOpen1} setIsOpen1={setIsOpen1} heading1={heading1} successMsg={successMsg} />
      </div>
      <Modal className="fade show" isOpen={catFromOpen}>
        <ModalBody className='text-center tracking-in-expand-fwd-bottom'>
          <center>
            <h4 className="mt-3 mb-5 fw-bold" style={{ color: "#00a859" }}>CREATE CATEGORY<span style={{ float: "right", cursor: "pointer" }} onClick={e => { setCatFromOpen(false) }} className='h6'>❌</span></h4>

          </center>
          <div className="mb-3" style={{ width: "40%", margin: "auto", color: "#727272!important" }}>
            <label
              htmlFor="exampleFormControlInput1"
              className="h6"
              style={{ color: "#727272!important" }}
            >
              Category Name<span className='required'> *</span>
            </label>
            <input
              type="text"
              className="form-control mt-3"
              id="elementName"
              name="Name"
              placeholder="Enter Element Name Here..."
              onChange={catChange}
            />
            <br />
            <center>
              <button
                className="btn btnSuccess mx-1"
                onClick={onSubmitHandeling}
              //disabled={isdisabled}
              >
                Submit
              </button>
            </center>
          </div>
        </ModalBody>

      </Modal>
    </>
  )
}
export default MasterFrom;