
import React from 'react'

function Footer() {
  return (
    <div style={{height:"40px",width:"100%",background:"#333"}} className="fixed-bottom">
        <center><h6 style={{color:"#fff",paddingTop:"10px"}}>SAITEC INTERNATIONAL COMPUTER CONSULTANTS </h6></center>
    </div>
  )
}

export default Footer;