import React, { useEffect, useState } from 'react';
import ProjectTable from './components/tables/ProjectTable';
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid';
import './reports.css';
import clsx from 'clsx';
import SideBar from '../../common/SideBar/SideBar';
import {
  GET_EVENT, GET_EVENT_BY_DATE, GET_ALL_PROJECTS, TENDER_STATE,
  GET_PROJECT, GET_PROJECT_BY_DATE, GET_MASTER_REPORT,
  GET_Employee, GET_EMPLOYEE_BY_DATE,
  GET_ALLDAILYUPDATE, GET_ALLDAILY_UPDATE_BY_DATE,
  GET_DOCUMENT, GET_DOCUMENT_BY_DATE,
  GET_HEADS, GET_HEADS_BY_DATE,
  GET_VENDOR, GET_VENDOR_BY_DATE,
  GET_CLIENT, GET_CLIENT_BY_DATE
} from '../../utiles/AllApis';
import axios from 'axios';
const Reports = () => {
  const [isLoading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [showDates, setDates] = useState(true);
  const [showEmpTbl, setEmpTbl] = useState(false);
  const [showDocTbl, setShowDocTbl] = useState(false);
  const [showClientTbl, setShowClientTbl] = useState(false);
  const [showVendorTbl, setShowVendorTbl] = useState(false);
  const [showHeadsTbl, setShowHeadsTbl] = useState(false);
  const [showProjectTbl, setProjectTbl] = useState(false);
  const [masterTbl, setMasterTbl] = useState(true);
  const [showEventTbl, setShowEventTbl] = useState(false);
  const [showMasterBtn, setShowMasterBtn] = useState(false);
  const [MasterCol, setMasterCol] = useState([]);
  const [rptName, setRptName] = useState("Master_Projects");
  const [showDailyUpdatesTbl, setShowDailyUpdatesTbl] = useState(false);
  const [filterDate, setFilterDate] = useState(
    {
      "FromDate": "",
      "ToDate": ""
    }
  );
  const [allcolor, setallcolor] = useState("topbuttons animatingBottom");
  const [masterColor, setMasterColor] = useState("afterclicktopbuttons animatingBottom");
  const [fillingcolor, setFillingcolor] = useState("topbuttons animatingBottom");
  const [runningcolor, setRunningcolor] = useState("topbuttons animatingBottom");
  const [apprColor, setapprColor] = useState("topbuttons animatingTop");
  const [unApprColor, setunApprColor] = useState("topbuttons animatingBottom");
  const [trashcolor, settrashcolor] = useState("topbuttons trashhover animatingTop");
  const [empColor, setEmpColor] = useState("topbuttons trashhover animatingTop");
  const [docColor, setDocColor] = useState("topbuttons trashhover animatingTop");
  const [vendorColor, setVendorColor] = useState("topbuttons trashhover animatingTop");
  const [clientColor, setClientColor] = useState("topbuttons trashhover animatingTop");
  const [headsColor, setHeadsColor] = useState("topbuttons trashhover animatingTop");
  const [tagname, setTagname] = useState('Master_Projects')
  const reportList = [
    {
      "ReoprtName": "All Projects",
      "id": "Master_Projects",
      "btnColor": allcolor
    },
    {

      "ReoprtName": "Considering",
      "id": "new_Tenders",
      "btnColor": allcolor
    },
    {

      "ReoprtName": "Filing",
      "id": "Filing_Projects",
      "btnColor": allcolor
    },
    // {

    //   "ReoprtName":"Running",
    //   "id":"Running_Projects",
    //   "btnColor":allcolor
    // },
    {
      "ReoprtName": "Running(Won)",
      "id": "Won_Projects",
      "btnColor": apprColor
    },
    {

      "ReoprtName": "Completed",
      "id": "completed",
      "btnColor": allcolor
    },



    {
      "ReoprtName": "Rejected",
      "id": "Rejected_Projects",
      "btnColor": fillingcolor
    },

    {
      "ReoprtName": "Lost",
      "id": "Lost_Projects",
      "btnColor": runningcolor
    },
    // {
    //   "ReoprtName":"Compiled",
    //   "id":"Compiled_Projects",
    //   "btnColor":runningcolor
    // },

  ]
  const masterBtn = [
    {
      "name": "Employee",
      "id": "emp",
      "btnColor": empColor
    },
    {
      "name": "Document",
      "id": "doc",
      "btnColor": docColor
    },
    {
      "name": "Client",
      "id": "client",
      "btnColor": clientColor
    },
    {
      "name": "Vendor",
      "id": "vendor",
      "btnColor": vendorColor
    },
    {
      "name": "Heads",
      "id": "heads",
      "btnColor": headsColor
    },

  ]

  const getReports = (id) => {

    setMasterCol([]);
    setDates(true);
    setRptName(id);
    setLoading(true);
    var fdate = "", toDate = "";
    if (filterDate.FromDate !== "" && filterDate.ToDate !== "") {
      fdate = filterDate.FromDate;
      toDate = filterDate.ToDate;
    }
    else {
      fdate = '1990-01-01';
      toDate = '1990-01-01';
    }
    axios.post(GET_MASTER_REPORT, { "FromDate": fdate, "ToDate": toDate, STATE: id }).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {

        setReportData(response.data.data);
        const keys = Object.keys(response.data.data[0]);
        keys.map((data, i) => {
          let obj = {};

          if (id === "Compiled_Projects" && data === "state") {

            obj = {
              "field": data, "headerName": data, width: 160,
              renderCell: (params) => {
                if (params?.row?.state === TENDER_STATE.NEW) {
                  return (
                    <p className=' mt-2 f-14' style={{ color: 'green' }}>{params?.row?.state}</p>
                  );
                }
                else if (params?.row?.state == TENDER_STATE.RUNNING) {
                  return (
                    <p className=' mt-2 f-14' style={{ color: 'blue' }}>{params?.row?.state}</p>
                  );
                }
                else {
                  return (
                    <p className=' mt-2 f-14' style={{ color: 'YELLOW' }}>{params?.row?.state}</p>
                  );
                }

              }
            }
          }
          else {
            obj = { "field": data, "headerName": data, width: 160 };

          }
          setMasterCol(MasterCol => [...MasterCol, obj]);
        });
      }
      else {
        setReportData(<p className='my-4'>Opps...No recored Found!!</p>);
      }
    });
  }
  const filterDateHandler = (e) => {
    setFilterDate({ ...filterDate, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    var fdate = "", toDate = "";
    if (filterDate.FromDate !== "" && filterDate.ToDate !== "") {
      fdate = filterDate.FromDate;
      toDate = filterDate.ToDate;
    }
    else {
      fdate = '1990-01-01';
      toDate = '1990-01-01';
    }
    setLoading(true);
    setMasterCol([]);
    axios.post(GET_MASTER_REPORT, { "FromDate": fdate, "ToDate": toDate, STATE: "Master_Projects" }).then((response) => {
      setLoading(false);

      if (response.data.isSuccess === true) {
        setReportData(response.data.data);
        const keys = Object.keys(response.data.data[0]);
        keys.map((data, i) => {
          let obj = {};
          obj = { "field": data, "headerName": data, width: 160 };
          setMasterCol(MasterCol => [...MasterCol, obj]);
        });
      }
      else {
        setReportData(<p className='my-4'>Opps...No recored Found!!</p>);
      }
    });

  }, [])

  return (
    <div className='container-fluid'>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }
      <div className='row '>
        <div className='col-md-2 col-12 col-sm-12 mainSideBar px-0'>
          <SideBar />
        </div>
        <div className='col-md-10 col-12 col-sm-12 text-center myview'>
          <div className="row">
            {reportList.map((data, i) => {
              return (<div className={`col-md-2 col-2 col-sm-2 col-lg-2 ${rptName === data.id ? 'activeCls' : ''}`}>
                <button key={i} className="btn text-white " onClick={e => {
                  getReports(data.id)
                  if (data.id === "Master_Projects") {
                    setTagname("All_Projects")
                  }
                  else if (data.id === "new_Tenders") {
                    setTagname("Considering")
                  }
                  else if (data.id === "Won_Projects") {
                    setTagname("Running(Won)")
                  }
                  else {
                    setTagname(data.id)
                  }


                }}>{data.ReoprtName}</button>
              </div>
              )
            })}
          </div>
          <div className='float-end mt-3'>
            <div className='subGridbox'> {showMasterBtn ? masterBtn.map((data, i) => {
              return (<button key={i} className={data.btnColor} style={{ width: "220px", marginTop: "2px" }} onClick={e => { getReports(data.id) }} >{data.name}</button>)
            }) : null}</div>
          </div>

          <div className='row px-5 mt-4'>

            {showDates ? <>
              <div className='col-sm-4'>
                <div className='d-flexed'>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">{tagname}-From Date:</label>
                    <input type="date" className="form-control " max="9999-12-31" id="proName" name="FromDate" placeholder="Enter Project Name here.." onChange={filterDateHandler} />
                  </div>
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='col-md-3 col-sm-6 col-6 px-4'>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">{tagname}-To Date:</label>
                    <input type="date" className="form-control" max="9999-12-31" id="proName" name="ToDate" placeholder="Enter Project Name here.." onChange={filterDateHandler} />
                  </div>

                </div></div><div className='col-sm-4'>
                <button className='btn btnSuccess mt-4 mb-1 mx-1' onClick={e => { getReports(rptName) }}>Apply</button>

              </div></> : null}
          </div>
          {masterTbl && MasterCol.length > 0
            ? <> <ProjectTable tag={tagname} tblData={reportData} Col={MasterCol} /></>
            : <> {masterTbl}  {MasterCol.length} no data</>}
        </div></div></div>

  )
}
export default Reports