import React, { useEffect, useState } from 'react';
import ProjectCards from '../../../projets/ProjectCards';
import './EmpProject.css';
import {
  Button,
  Input
} from 'reactstrap';
import axios from 'axios';
import { GrSearch } from "react-icons/gr";
import FloatingBtn from '../../../../common/Buttons/FloatingBtn';
import Pagination from '../../../manageMaster/common/cardPagination';
import { GET_allprojects, GET_PROJECT_COUNT, GET_fillingprojects, GET_runningprojects, GET_completeprojects } from '../../../../utiles/AllApis';
import DataGrids from '../../../../common/table/DataGrid';
const AdminProjects = () => {
  let searctVal = "";
  const [isLoading, setLoading] = useState(false);
  const [filter, setfilter] = useState("");
  const [completeCards, setCompleteCards] = useState([]);
  const [cardLength, setCardLength] = useState(0);
  const [cardLength1, setCardLength1] = useState(0);
  const [cardLength2, setCardLength2] = useState(0);
  const [cardLength3, setCardLength3] = useState(0);
  const [showParPage, setShowParPage] = useState(10);
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState();
  const [cards2, setCards2] = useState();
  const [cards3, setCards3] = useState();
  const [cardsArray, setCardsArray] = useState([]);

  const [pagination, setPagination] = useState(
    {
      start: 0,
      end: showParPage
    }
  );

  const paginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  }
  // const searchFilterData=(e)=>
  // {

  //     searctVal=e.target.value;
  //     getAllProjectForSearch();
  // }
  const columnsForNewPro = [
    {
      field: "edate",
      headerName: "Created At",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {

        return (
          <p className='text-white mt-2 f-14'>{params?.row?.edate?.split("T")[0]}</p>
        );
      }
    },
    { field: 'rowpos', headerName: 'Project Id' },
    { field: 'projectName', headerName: 'Project Name', width: 150 },
    { field: 'clientName', headerName: 'Client Name', width: 130 },
    { field: 'category', headerName: 'Category', width: 140 },
    { field: 'ownerName', headerName: 'Owner Name', width: 130 },
    { field: 'projectType', headerName: 'Project Type', width: 130 },
    {
      field: "ldateOfSubmissionBid",
      headerName: "Last date Of Submission Bid",
      width: 100,
      disableClickEventBubbling: true,
      renderCell:
        (params) => {
          return (
            <p className='text-white mt-2 f-14'>{params?.row?.ldateOfSubmissionBid?.split("T")[0]}</p>
          );
        }
    },
    {
      field: "vIEW",
      headerName: "Eligibility Document",
      sortable: false,
      width: 105,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button variant="contained btnSuccessClr" ><a className='text-white' href={params.row.eligibilitY_DOC} target="_blank">view</a></Button>
        );
      }
    },
    {
      field: "VIEW",
      headerName: "Project Document",
      width: 105,
      renderCell: (params) => {
        if (params.row.projecT_DOC === "" || params.row.projecT_DOC === null) {
          return (
            <Button variant="contained btnSuccessClr" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }}>view</Button>
          )
        }
        else {
          return (
            <Button variant="contained btnSuccessClr" style={{ background: "rgb(15, 83, 61)", color: "#b8e3d7" }}><a className='text-white' href={params.row.projecT_DOC} target="_blank">view</a></Button>
          );
        }
      }
    }
  ]
  useEffect(() => {
    getAllNewPro();

    getAllFillingPro();
    getAllRunningPro();
    getAllCompletedPro();
  }, []);
  const getAllNewPro = () => {
    setLoading(true);
    axios.get(GET_allprojects).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setCards(response.data.data);
      }
      else {
        setCards(<p className='my-5'>No Projects Found!!</p>)
      }
    });
  }
  const getAllFillingPro = () => {
    setLoading(true);
    axios.get(GET_fillingprojects).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        console.log(response.data.data)
        setCards1(response.data.data);
      }
      else {
        setCards1(<p className='my-5'>No Projects Found!!</p>)
      }
    });
  }
  const getAllRunningPro = () => {
    setLoading(true);
    axios.get(GET_runningprojects).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setCards2(response.data.data);
      }
      else {
        setCards2(<p className='my-5'>No Projects Found!!</p>)
      }
    });
  }






  const getAllCompletedPro = () => {
    setLoading(true);
    axios.get(GET_completeprojects).then((response) => {
      setLoading(false);
      if (response.data.isSuccess === true) {
        setCards3(response.data.data);
      }
      else {
        setCards3(<p className='my-5'>No Projects Found!!</p>)
      }
    });
  }

  return (

    <div className='container-fluid'>
      {
        isLoading ? <div className='loaderDiv'>
        </div> : null
      }

      <h5 className='text-white fw-bold mt-2 text-center'>New Projects</h5>
      <hr className='text-white mb-0' />
      {cards?.length > 0 ? <> <DataGrids name={"New_Projects"} cardsList={cards} columns={columnsForNewPro} /></> : <p>Project not found!!</p>}
      <h5 className='text-white fw-bold text-center'>Filing Projects</h5>
      <hr className='text-white' />
      {cards1?.length > 0 ? <><DataGrids name={"Filing_Projects"} cardsList={cards1} columns={columnsForNewPro} /></> : <p>Project not found!!</p>}
      <div className='my-3'>
        <FloatingBtn href='/projectList/form' usehtmlFor="Add Project" />
      </div>
      <h5 className='text-white fw-bold text-center'>Running Projects</h5>
      <hr className='text-white' />
      {cards2?.length > 0 ? <><DataGrids name={"Running_Projects"} cardsList={cards2} columns={columnsForNewPro} /></> : <p>Project not found!!</p>}
      <div className='my-3'>
        <FloatingBtn href='/projectList/form' usehtmlFor="Add Project" />
      </div>
      <h5 className='text-white fw-bold text-center'>Completed Projects</h5>
      <hr className='text-white' />
      {cards3?.length > 0 ? <><DataGrids name={"Completed_Projects"} cardsList={cards3} columns={columnsForNewPro} /></> : <p>Project not found!!</p>}
      <div className='my-3'>
        <FloatingBtn href='/projectList/form' usehtmlFor="Add Project" />
      </div>

    </div>
  )
}
export default AdminProjects;